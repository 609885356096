<template>
  <div class="console_course_detail">
    <a-breadcrumb separator=">">
      <a-breadcrumb-item>
        <router-link to="/console/course/create">新建课程</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>{{ data_list.name }}</a-breadcrumb-item>
    </a-breadcrumb>
    <a-row :gutter="[20]" class="course_detail">
      <a-col :span="24" :lg="16">
        <div class="detail">
          <a-flex justify="space-between">
            <h5>课程详情</h5>
            <div class="domain_icon" v-if="data_list.is_management && data_list.can_write">
              <a-tooltip>
                <template #title>编辑更多</template>
                <img src="/hw_web/static/src/web/img/course_make1_icon.png" alt="编辑更多" @click="openCourseEdit = true">
              </a-tooltip>
              <a-tooltip>
                <template #title>课程发布</template>
                <img src="/hw_web/static/src/web/img/course_make2_icon.png" alt="课程发布" @click="showCoursePublishedConfirm(course_id)">
              </a-tooltip>
              <a-tooltip>
                <template #title>暂时归档</template>
                <img src="/hw_web/static/src/web/img/course_make3_icon.png" alt="暂时归档" @click="showCourseDoneConfirm(course_id)">
              </a-tooltip>
            </div>
          </a-flex>
          <div>
            <a-row>
              <a-col :span="12">
                <p>课程名称：<a>{{ data_list.name }}</a></p>
                <p>英文名称：<a>{{ data_list.english_name }}</a></p>
                <p>课程性质：<a>{{ data_list.course_nature }}</a></p>
                <p>关联实验环境：<a>{{ data_list.app_name }}</a></p>
                <p class="mb-0">课程标签：<span v-for="(item,index) in data_list.course_category_ids" :key="index">{{ item }}</span></p>
              </a-col>
              <a-col :span="12">
                <a-flex justify="center" align="center" class="h-100">
                <a-flex align="flex-end">
                  <div style="width: 120px;">
                    <div class="img_box">
                      <img :src="`/web/image/hw.course/${course_id}/image_512?t=${new Date().getTime()}`"/>
                    </div>
                  </div>
                  <EditOutlined v-if="data_list.is_management && data_list.can_write" @click="openCourseEdit = true" style="font-size: 20px;"/>
                </a-flex>
                </a-flex>
              </a-col>
            </a-row>
            <a-modal v-model:open="openCourseEdit" title="课程信息" @ok="courseEditOk" width="60vw" :footer='null'>
              <a-form :model="data_list" :label-col="{ span: 8 }" :wrapper-col="{ span: 10 }" @submit="courseEditOk">
                <a-row>
                  <a-col :span="12">
                    <a-form-item label="课程名称" name="name" :rules="[{ required: true, message: '请输入课程名称!' }]">
                      <a-input v-model:value="data_list.name"/>
                    </a-form-item>
                    <a-form-item label="课程性质" name="course_nature"
                                 :rules="[{ required: true, message: '请输入课程性质!' }]">
                      <a-input v-model:value="data_list.course_nature"/>
                    </a-form-item>
                    <a-form-item label="周学时" name="weekly_hours">
                      <a-input v-model:value="data_list.weekly_hours"/>
                    </a-form-item>
                    <a-form-item label="学分" name="credits">
                      <a-input v-model:value="data_list.credits"/>
                    </a-form-item>
                    <a-form-item label="模板类型" name="teaching_materials">
                      <a-select v-model:value="data_list.app_type"
                          :options="data_list.experiment_app_type_ids">
                      </a-select>
                    </a-form-item>
                    <a-form-item label="应用模板" name="teaching_materials">
                      <a-select v-if="data_list.app_type == 'office'" v-model:value="data_list.app_id" :allowClear="true"
                        :options="data_list.experiment_app_ids" show-search :filter-option="filterOption">
                      </a-select>
                      <a-select v-else v-model:value="data_list.app_id" :allowClear="true"
                        :options="data_list.experiment_user_app_ids" show-search :filter-option="filterOption">
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col :span="12">
                    <a-form-item label="课程封面：" name="file_list">
                      <a-upload
                          v-model:file-list="data_list.file_list"
                          :customRequest="uploadDummyRequest"
                          list-type="picture-card"
                          @preview="handlePreview"
                          :max-count="1"
                          accept=".png,.jpg,.jpeg"
                      >
                        <div v-if="data_list.file_list.length < 1">
                          <plus-outlined/>
                          <div class="ant-upload-text">上传图片</div>
                        </div>
                      </a-upload>
                      <a-modal :open="previewVisible" :title="previewTitle" :footer="null" @cancel="cancelPreview">
                        <img alt="example" style="width: 100%" :src="previewImage"/>
                      </a-modal>
                    </a-form-item>
                    <a-form-item label="英文名称" name="english_name">
                      <a-input v-model:value="data_list.english_name"/>
                    </a-form-item>
                    <a-form-item label="适用专业" name="applicable_majors">
                      <a-input v-model:value="data_list.applicable_majors"/>
                    </a-form-item>
                    <a-form-item label="使用教材" name="teaching_materials">
                      <a-input v-model:value="data_list.teaching_materials"/>
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-form-item label="课程标签" name="new_course_category_ids">
                  <a-select v-model:value="data_list.new_course_category_ids" :allowClear="true" mode="multiple" :max-tag-count="3"
                            show-search :options="data_list.category_ids" :filter-option="filterOption">
                  </a-select>
                </a-form-item>
                <a-form-item label="课程简介" name="course_brief">
                  <a-textarea v-model:value="data_list.course_brief"/>
                </a-form-item>
                <a-form-item label="教学基本要求" name="course_basic_requirement">
                  <a-textarea v-model:value="data_list.course_basic_requirement"/>
                </a-form-item>
                <a-form-item label="考核方式和成绩评定方法" name="course_assessment_info">
                  <a-textarea v-model:value="data_list.course_assessment_info"/>
                </a-form-item>

                <a-form-item :wrapper-col="{ offset: 11, span: 13 }">
                  <a-button type="primary" html-type="submit">保存</a-button>
                </a-form-item>
              </a-form>
            </a-modal>
          </div>
        </div>
        <div class="chapter">
          <h5>课程章节
            <div v-if="data_list.is_management">
              <button @click="openChapterEdit = true">编辑章节</button>
              <button @click="updateHistory" style="background-color: #C4C4C4;">操作历史</button>
            </div>
            <a-modal v-model:open="openChapterEdit" title="编辑章节" @ok="chapterEditOk" width="60vw" :footer='null'>
              <a-form class="formChapter" :model="data_list" :label-col="{ span: 8 }" :wrapper-col="{ span: 10 }"
                      @submit="chapterEditOk">
                <template v-if="switchChapterLesson == 'chapter'">
                  <span class="formChapter_btn active">编辑章</span>
                  <span class="formChapter_btn">编辑节</span>
                  <div class="table-responsive" style="margin-top: 10px;">
                    <table class="table table-bordered text-center">
                      <thead>
                        <tr style="background-color: #F6FCFF;">
                          <th style="border-bottom-width: 1px;width: 124px;">排序
                            <a-tooltip title="双击修改顺序,从小到大排序">
                              <InfoCircleOutlined style="margin-right: 5px; color: #1890ff;" />
                            </a-tooltip>
                          </th>
                          <th style="border-bottom-width: 1px;">章名称</th>
                          <th style="border-bottom-width: 1px;">操作</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in data_list.chapters" :key="index">
                          <td @dblclick="editSequence(index)">
                            <template v-if="editingIndex === index">
                              <a-input-number
                                v-model:value="item.sequence"
                                :min="1" :precision="0" ref="inputRefs" 
                                @blur="() => saveChapterSequence(item.id, item.sequence)"
                                @pressEnter="() => editingIndex = null"
                              />
                            </template>
                            <template v-else>{{ item.sequence }}</template>
                          </td>
                          <td>{{ item.name }}</td>
                          <td>
                            <a v-if="data_list.can_write" style="color: #68A4FF;margin: 0 9px;" @click="(e) => chapterInfoEdit(e, item.id)">编辑</a>
                            <a style="color: #68A4FF;margin: 0 9px;" @click="chapterEdit(item.id)">编辑节</a>
                            <a-popconfirm title="确定要删除?" @confirm="chapterDelete(item.id)">
                              <a v-if="data_list.can_unlink" style="color: #A7A7A7;margin: 0 9px;">删除</a>
                            </a-popconfirm>
                          </td>
                        </tr>
                        <tr v-if="data_list.can_create">
                          <td colspan="20" @click="openChapterInfoModel" title="增加章">
                            <PlusOutlined style="color: #428DFF;"/>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <a-form-item :wrapper-col="{ offset: 11, span: 13 }">
                    <a-button type="primary" html-type="submit">保存</a-button>
                  </a-form-item>
                </template>
                <template v-if="switchChapterLesson == 'lesson'">
                  <span class="formChapter_btn" style="cursor: pointer;"
                        @click="switchChapterLesson = 'chapter'">编辑章</span>
                  <span class="formChapter_btn active">编辑节</span>
                  <span>{{ data_list.chapters.find(i => i.id == chapter_id).name }}</span>
                  <div class="table-responsive" style="margin-top: 10px;">
                    <table class="table table-bordered text-center">
                      <thead>
                        <tr style="background-color: #F6FCFF;">
                          <th style="border-bottom-width: 1px;width: 124px;">排序
                            <a-tooltip title="双击修改顺序,从小到大排序">
                              <InfoCircleOutlined style="margin-right: 5px; color: #1890ff;" />
                            </a-tooltip>
                          </th>
                          <th style="border-bottom-width: 1px;">节名称</th>
                          <th style="border-bottom-width: 1px;">操作</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in hasLessons" :key="index">
                          <td @dblclick="editSequence(index)">
                            <template v-if="editingIndex === index">
                              <a-input-number
                                v-model:value="item.sequence"
                                :min="1" :precision="0" ref="inputRefs" 
                                @blur="() => saveLessonSequence(item.id, item.sequence)"
                                @pressEnter="() => editingIndex = null"
                              />
                            </template>
                            <template v-else>{{ item.sequence }}</template>
                          </td>
                          <td>{{ item.name }}</td>
                          <td>
                            <a v-if="data_list.can_write" style="color: #68A4FF;margin: 0 9px;" @click="lessonEdit(item.id)">编辑</a>
                            <a-popconfirm title="确定要删除?" @confirm="lessonDelete(item.id)">
                              <a v-if="data_list.can_unlink" style="color: #A7A7A7;margin: 0 9px;">删除</a>
                            </a-popconfirm>
                          </td>
                        </tr>
                        <tr v-if="data_list.can_create">
                          <td colspan="20" @click="openLessonInfo = true" title="增加节">
                            <PlusOutlined style="color: #428DFF;"/>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <a-form-item :wrapper-col="{ offset: 11, span: 13 }">
                    <a-button type="primary" html-type="submit">保存</a-button>
                  </a-form-item>
                </template>
              </a-form>
            </a-modal>
            <a-modal v-model:open="openChapterInfo" title="章信息" @ok="chapterInfoOk"
                     :confirm-loading="confirmChapterInfoLoading" width="60vw" :footer='null'
                     @close="closeChapterInfoModal">
              <a-form ref="chapterInfoFormRef" :model="chapterInfo" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }"
                      @submit="chapterInfoOk">
                <a-form-item label="章名称" name="name" :rules="[{ required: true, message: '请输入章名称!' }]">
                  <a-input-group compact>
                    <a-input v-model:value="chapterInfo.name" style="width: calc(100% - 46px)"/>
                    <a-tooltip>
                      <template #title>更多信息</template>
                      <a-button @click="showAdvanced = !showAdvanced"><SettingOutlined /></a-button>
                    </a-tooltip>
                  </a-input-group>
                </a-form-item>
                <template v-if="showAdvanced">
                  <a-form-item label="教学目的" name="teaching_purpose">
                    <a-textarea v-model:value="chapterInfo.teaching_purpose"/>
                  </a-form-item>
                  <a-form-item label="教学重点" name="teaching_emphasis">
                    <a-input v-model:value="chapterInfo.teaching_emphasis"/>
                  </a-form-item>
                  <a-form-item label="教学难点" name="teaching_difficulties">
                    <a-input v-model:value="chapterInfo.teaching_difficulties"/>
                  </a-form-item>
                  <a-form-item label="思政融入" name="political_education">
                    <a-textarea v-model:value="chapterInfo.political_education"/>
                  </a-form-item>
                  <a-form-item label="主要教学方法" name="teaching_methods">
                    <a-input v-model:value="chapterInfo.teaching_methods"/>
                  </a-form-item>
                </template>
                <a-form-item label="教学内容" name="description">
                  <RichEditor :height="200" :editMode="editorMode" v-model="chapterInfo.description"/>
                </a-form-item>

                <a-form-item :wrapper-col="{ offset: 11, span: 13 }">
                  <a-button type="primary" html-type="submit" v-if="ChapterInfoModelMode == 'create'">创建</a-button>
                  <a-button type="primary" v-if="ChapterInfoModelMode == 'update'"
                            @click="saveChapterInfo(chapterInfo.id)">保存
                  </a-button>
                </a-form-item>
              </a-form>
            </a-modal>
            <a-modal v-model:open="openLessonInfo" title="节信息" @ok="lessonInfoOk" width="60vw" :footer='null'>
              <a-form :model="lessonInfo" :label-col="{ span: 8 }" :wrapper-col="{ span: 10 }" @submit="lessonInfoOk">
                <a-form-item label="节名称" name="name" :rules="[{ required: true, message: '请输入节名称!' }]">
                  <a-input v-model:value="lessonInfo.name"/>
                </a-form-item>

                <a-form-item :wrapper-col="{ offset: 11, span: 13 }">
                  <a-button type="primary" html-type="submit">保存</a-button>
                </a-form-item>
              </a-form>
            </a-modal>
          </h5>
          <a-spin :spinning="skeletonLessonsActive">
            <a-collapse v-model:activeKey="activeKey" accordion expand-icon-position="end">
              <a-collapse-panel v-for="(item, index) in data_list.chapters" :key="index" :header="generateHeader(item)">
                <a-row v-for="(le,y) in item.lessons" :key="y" class="accordion">
                  <a-col :span="16" class="line-clamp-1" :title="le.name">{{ le.name }}</a-col>
                  <a-col :span="8">
                    <a-flex justify="flex-end" align="center">
                      <a :class="{'course_video': le.video_id || le.description}" title="视频">
                        <img src="/hw_web/static/src/img/course_video_icon.png" height="13" width="13"
                             @click="go_to_study(item.id,le.id)"/>
                      </a>
                      <a :class="{'course_experiment': le.experiment_id || le.lab_manual_contents}" title="实验">
                        <img src="/hw_web/static/src/img/course_experiment_icon.png" height="17" width="17"
                             @click="go_to_study(item.id,le.id)"/>
                      </a>
                      <a :class="{'course_case': le.exercise_code_num > 0}" title="案例">
                        <img src="/hw_web/static/src/img/course_case_icon.png" height="13" width="13" 
                             @click="go_to_study(item.id,le.id)"/>
                      </a>
                      <a :class="{'course_exercise': le.exercise_paper_num > 0}" title="练习">
                        <img src="/hw_web/static/src/img/course_exercise_icon.png" height="17" width="17"
                             @click="go_to_study(item.id,le.id)"/>
                      </a>
                      <a :class="{'course_courseware': le.courseware_num > 0}" title="课件">
                        <img src="/hw_web/static/src/img/course_pdf_icon.png" height="17" width="17"
                             @click="go_to_study(item.id,le.id)"/>
                      </a>
                    </a-flex>
                  </a-col>
                </a-row>
                <a-flex justify="flex-end" v-if="data_list.can_create" style="padding: 7px 0;filter: hue-rotate(300deg) saturate(1);">
                  <img src="/hw_web/static/src/img/pL_add.png" alt="增加一节" title="增加一节" height="25" @click="addLesson(item.id)" style="cursor: pointer;">
                </a-flex>
              </a-collapse-panel>
            </a-collapse>
          </a-spin>
        </div>
      </a-col>
      <a-col :span="24" :lg="8">
        <div class="count">
          <h5>课程内容统计</h5>
          <div class="row">
            <div class="col"><h4>{{ statistics_list.video_nums }}</h4>视频</div>
            <div class="col"><h4>{{ statistics_list.experiment_nums }}</h4>实验环境</div>
            <div class="col"><h4>{{ statistics_list.code_nums }}</h4>编程案例</div>
            <div class="col"><h4>{{ statistics_list.exercise_nums }}</h4>随堂练习</div>
            <!-- <div class="col"><h4>{{ statistics_list.course_courseware_id_nums }}</h4>课件</div> -->
            <div class="col"><h4>{{ statistics_list.lab_manual_nums }}</h4>实验手册</div>
          </div>
        </div>
        <div class="team">
          <h5>课程团队
            <button v-if="data_list.is_admin_user" @click="openRbac = true">权限管理</button>
            <a-modal v-model:open="openRbac" title="权限管理" @ok="rbacOk" width="60vw" :footer='null'>
              <a-form class="teamform" :model="rbacInfo" @submit="rbacOk">
                <h5 style="font-size: 16px;">设置管理员：<span style="color: #0089ff;font-size: 14px;">(仅有管理员拥有成员管理权限)</span></h5>
                <div style="display: flex;flex-wrap: wrap;">
                  <div class="user" v-if="data_list.partner_id">
                    <img v-if="data_list.user_avatar" :src="`/web/image/res.partner/${data_list.partner_id}/image_512`">
                    <img v-else src="/hw_blog/static/src/img/blog_default_avatar.png">
                    {{data_list.partner_name}}
                  </div>
                  <div class="user" v-for="item in data_list.admin_ids" :key="item.id">
                    <img v-if="item.img" :src="`/web/image/res.partner/${item.partner_id}/image_512`">
                    <img v-else src="/hw_blog/static/src/img/blog_default_avatar.png">
                    {{item.partner_name}}
                  </div>
                  <div><img src="/hw_web_console/static/src/img/project_add_members.png" @click="openMemberManagement = true" style="cursor: pointer;"/></div>
                </div>
                <h5 style="font-size: 16px;">成员权限管理：</h5>
                <table class="table table-bordered table-hover text-center">
                  <thead>
                    <tr>
                      <th>成员名称</th>
                      <th>浏览权限</th>
                      <th>新建资源权限</th>
                      <th>编辑资源权限</th>
                      <th>删除资源权限</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item,index) in data_list.user_ids" :key="item.id">
                      <td>{{item.partner_name}}</td>
                      <td><input type="radio" checked="1"/></td>
                      <td><label>
                        <input
                          type="checkbox"
                          v-model="data_list.user_ids[index].can_create"
                          :checked="data_list.user_ids[index].can_create"
                        />
                        <i></i></label>
                      </td>
                      <td><label>
                        <input
                          type="checkbox"
                          v-model="data_list.user_ids[index].can_write"
                          :checked="data_list.user_ids[index].can_write"
                        />
                        <i></i></label>
                      </td>
                      <td><label>
                        <input
                          type="checkbox"
                          v-model="data_list.user_ids[index].can_unlink"
                          :checked="data_list.user_ids[index].can_unlink"
                        />
                        <i></i></label>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <a-form-item :wrapper-col="{ offset: 11, span: 13 }">
                  <a-button type="primary" html-type="submit" :loading="rbacLoading">保存</a-button>
                </a-form-item>
              </a-form>
            </a-modal>
            <a-modal v-model:open="openMemberManagement" title="成员管理" width="60vw" :footer='null'>
              <table class="table table-bordered table-hover text-center">
                <thead>
                  <tr>
                    <th>成员名称</th>
                    <th>成员角色</th>
                    <th>操作</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{data_list.partner_name}}</td>
                    <td>超级管理员</td>
                    <td></td>
                  </tr>
                  <tr v-for="item in data_list.admin_ids" :key="item.id">
                    <td>{{item.partner_name}}</td>
                    <td>管理员</td>
                    <td>
                      <template v-if="data_list.is_super_admin">
                        <button class="btn btn-secondary btn-sm" @click="_onCancelAdmin(item.id)">取消管理员</button>
                        <button class="btn btn-danger btn-sm ml-3" @click="_onDeleteMember(item.id)">删除成员</button>
                      </template>
                    </td>
                  </tr>
                  <tr v-for="item in data_list.user_ids" :key="item.id">
                    <td>{{item.partner_name}}</td>
                    <td>一般用户</td>
                    <td>
                      <button v-if="data_list.is_super_admin" class="btn btn-success btn-sm" @click="_onSetAdmin(item.id)">设为管理员</button>
                      <button class="btn btn-danger btn-sm ml-3" @click="_onDeleteMember(item.id)">删除成员</button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <a-flex justify="center">
                <a-button type="primary" @click="openMemberManagement = false">确认</a-button>
              </a-flex>
            </a-modal>
          </h5>
          <div>
            <span>管理员：</span>
            <div>
              <div class="user" v-if="data_list.partner_id">
                <img v-if="data_list.user_avatar" :src="`/web/image/res.partner/${data_list.partner_id}/image_512`">
                <img v-else src="/hw_blog/static/src/img/blog_default_avatar.png">
                {{data_list.partner_name}}
              </div>
              <div class="user" v-for="item in data_list.admin_ids" :key="item.id">
                <img v-if="item.img" :src="`/web/image/res.partner/${item.partner_id}/image_512`">
                <img v-else src="/hw_blog/static/src/img/blog_default_avatar.png">
                {{item.partner_name}}
              </div>
            </div>
          </div>
          <div>
            <span>成员：</span>
            <div>
              <div class="user" v-for="item in data_list.user_ids" :key="item.id">
                <img v-if="item.img" :src="`/web/image/res.partner/${item.partner_id}/image_512`">
                <img v-else src="/hw_blog/static/src/img/blog_default_avatar.png">
                {{item.partner_name}}
              </div>
              <div class="add_user" v-if="data_list.is_management" title="添加成员">
                <img src="/hw_web_console/static/src/img/project_add_members1.png" @click="openAddUser = true"/>
                <a-modal v-model:open="openAddUser" title="添加成员" width="60vw" :footer='null'>
                  <a-form ref="addUserRef" :model="data_list" :label-col="{ span: 8 }" :wrapper-col="{ span: 10 }">
                    <a-form-item label="选择成员" name="management_partner_id" :rules="[{ required: true, message: '请选择要添加的成员!',trigger: 'change', }]">
                      <a-select v-model:value="data_list.management_partner_id" :allowClear="true"
                                show-search :options="data_list.course_management_partner" :filter-option="filterOption">
                      </a-select>
                    </a-form-item>
                    <a-form-item :wrapper-col="{ offset: 8, span: 16 }">
                      <a-button type="primary" @click="addUserOk" :loading="addUserLoading">保存</a-button>
                    </a-form-item>
                  </a-form>
                </a-modal>
              </div>
            </div>
          </div>
        </div>
        <div class="need">
          <h5>制作需求
            <a-button v-if="data_list.is_management" @click="getCourseDemand(course_id)">需求管理</a-button>
          </h5>
          <div v-for="item in data_list.demand_ids" :key="item.id">
            {{item.name}}
            <div>{{item.partner_name}}
              <span v-if="item.demand_state == 'processing'" class="Doing">进行中</span>
              <span v-else-if="item.demand_state == 'published'" class="publish">已发布</span>
              <span v-else>待处理</span>
            </div>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script setup>
import {onMounted, reactive, ref, computed, createVNode, nextTick} from "vue";
import {jsonRPC, getResponseData, getFailedMessage} from "@/utils/http_utils";
import {ExclamationCircleOutlined, PlusOutlined} from '@ant-design/icons-vue';
import {logDebug, logError} from "@/utils/logger";
import {useRouter} from 'vue-router';
import { EditOutlined, SettingOutlined, InfoCircleOutlined } from '@ant-design/icons-vue';

const router = useRouter();
const editorMode = ref('update')

const course_id = router.currentRoute.value.params.course_id
logDebug(course_id)
import {newCancelPreview, newHandlePreview, newPreviewState, newUploadDummyRequest,getBase64} from "@/utils/file_utils";
const {previewVisible, previewImage, previewTitle} = newPreviewState();
const cancelPreview = newCancelPreview(previewVisible, previewTitle)
const handlePreview = newHandlePreview(previewImage, previewVisible, previewTitle)
const uploadDummyRequest = newUploadDummyRequest()
const data_list = reactive({
  name: '',
  english_name: '',
  course_nature: '',
  applicable_majors: '',
  teaching_materials: '',
  course_brief: '',
  course_basic_requirement: '',
  course_assessment_info: '',
  weekly_hours: 0,
  credits: 0,
  file_list: [],
  chapters: [],
  new_course_category_ids: [],
  management_partner_id: '',
  is_management: false,
});
const statistics_list = reactive({
  code_nums: 0,
  exercise_nums: 0,
  experiment_nums: 0,
  lesson_nums: 0,
  video_nums: 0,
  lab_manual_nums: 0,
  video_time_duration: 0,
  course_courseware_id_nums: 0,
});

const openAddUser = ref(false)
const addUserLoading = ref(false)
const addUserRef = ref()
const addUserOk = ()=>{
  addUserRef.value.validate()
  .then(() => {
    addUserLoading.value = true;
    jsonRPC({
      url: "/api/make/course/base/management/add",
      params: {
        course_id: course_id,
        management_partner_id: data_list.management_partner_id,
      },
      success(res) {
        const data = getResponseData(res);
        logDebug(`获取成功`, data);
        fetchData()
      },
      fail(error) {
        logError(`查询失败, `, error);
      },
    }).then(()=>{
      openAddUser.value = false;
      addUserLoading.value = false;
      addUserRef.value.resetFields()
    });
  })
  .catch(error => {
    logError('error', error);
  });
}

const openRbac = ref(false)
const rbacLoading = ref(false)
const rbacOk = ()=>{
  rbacLoading.value = true
  jsonRPC({
    url: "/api/make/course/base/user/rbac",
    params: {
      user_ids: data_list.user_ids,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取成功`, data);
      fetchData()
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  }).then(()=>{
    openRbac.value = false;
    rbacLoading.value = false;
  });
}
const openMemberManagement = ref(false)
const _onSetAdmin = e=>{
  Modal.confirm({
    title: '你确定要设为管理员吗？',
    icon: createVNode(ExclamationCircleOutlined),
    onOk() {
      jsonRPC({
        url: "/api/make/course/base/user/setAdmin",
        params: {
          id: e,
        },
        success(res) {
          const data = getResponseData(res);
          logDebug(`获取成功`, data);
          fetchData()
        },
        fail(error) {
          logError(`查询失败, `, error);
        },
      });
    },
    onCancel() {
      logDebug('Cancel');
    },
  });
}
const _onCancelAdmin = e=>{
  Modal.confirm({
    title: '你确定要取消管理员吗？',
    icon: createVNode(ExclamationCircleOutlined),
    onOk() {
      jsonRPC({
        url: "/api/make/course/base/user/cancelAdmin",
        params: {
          id: e,
        },
        success(res) {
          const data = getResponseData(res);
          logDebug(`获取成功`, data);
          fetchData()
        },
        fail(error) {
          logError(`查询失败, `, error);
        },
      });
    },
    onCancel() {
      logDebug('Cancel');
    },
  });
}
const _onDeleteMember = e=>{
  Modal.confirm({
    title: '你确定要删除吗？',
    icon: createVNode(ExclamationCircleOutlined),
    okText: '删除',
    okType: 'danger',
    onOk() {
      jsonRPC({
        url: "/api/make/course/base/user/delete",
        params: {
          id: e,
        },
        success(res) {
          const data = getResponseData(res);
          logDebug(`获取成功`, data);
          fetchData()
        },
        fail(error) {
          logError(`查询失败, `, error);
        },
      });
    },
    onCancel() {
      logDebug('Cancel');
    },
  });
}


const activeKey = ref([]);
const go_to_study = (x,y)=>{
  if (data_list.is_management && data_list.can_write) {
    router.push(`/console/course/${course_id}/${x}/${y}/edit`);
  }
}
const getCourseDemand=(e)=>{
  router.push(`/console/course/${e}/demand`);
}

const openCourseEdit = ref(false);
const courseEditOk = async () => {
  const filePromises = await Promise.all(data_list.file_list.map(async file => {
    const base64Data = await getBase64(file.originFileObj);
      return {
          ...file,
          raw_data: base64Data,
          file_name: file.name,
      };
  }));

  jsonRPC({
    url: "/api/make/course/base/update",
    params: {
      course_id: course_id,
      name: data_list.name,
      app_type: data_list.app_type,
      app_id: data_list.app_id,
      file_list: filePromises,
      english_name: data_list.english_name,
      course_nature: data_list.course_nature,
      applicable_majors: data_list.applicable_majors,
      teaching_materials: data_list.teaching_materials,
      course_brief: data_list.course_brief,
      course_basic_requirement: data_list.course_basic_requirement,
      course_assessment_info: data_list.course_assessment_info,
      weekly_hours: data_list.weekly_hours,
      credits: data_list.credits,
      new_course_category_ids: data_list.new_course_category_ids,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取成功`, data);
      openCourseEdit.value = false;
      fetchData()
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  });
};
const filterOption = (input, option) => {
  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};
const editingIndex = ref(null);  // 用于存储当前正在编辑的index
const inputRefs = ref([]);  // 用于存储input的ref
// 编辑排序
const editSequence = (index) => {
  editingIndex.value = index;
  nextTick(() => {
    inputRefs.value[0]?.focus();
  });
};
// 保存章排序
const saveChapterSequence = (chapter_id, sequence) => {
  jsonRPC({
    url: "/api/make/course/chapter/sequence",
    params: {
      chapter_id: chapter_id,
      sequence: sequence,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取成功`, data);
      skeletonLessons()
      message.success('保存排序成功')
    },
    fail(error) {
      logError(`查询失败, `, error);
      message.error(error);
    },
  });
  editingIndex.value = null;
};
// 保存节排序
const saveLessonSequence = (lesson_id, sequence) => {
  jsonRPC({
    url: "/api/make/course/lesson/sequence",
    params: {
      lesson_id: lesson_id,
      sequence: sequence,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取成功`, data);
      skeletonLessons()
      message.success('保存排序成功')
    },
    fail(error) {
      logError(`查询失败, `, error);
      message.error(error);
    },
  });
  editingIndex.value = null;
};
const openChapterEdit = ref(false);
const chapterEditOk = () => {
  openChapterEdit.value = false;
};
import {h} from 'vue';
import chapterIcon from '@/assets/icon/chapter_icon.png';
import {message, Modal} from "ant-design-vue";
import RichEditor from "@/components/RichEditor.vue";

function generateHeader(item) {
  let div_style = 'display: inline-block;height: 25px;width: 25px;margin-left: 8px;border-radius: 50%;text-align: center;background-color: #BFBFBF;';
  if (item.lessons.length > 0) {
    div_style += 'background-color: #428DFF;'
  } 
  return h('div', [
    h('span', item.name),
    h('div', {
        style: div_style,
        onclick: (e) => chapterInfoEdit(e,item.id),
      },[
        h('img', {
          src: chapterIcon,
          style: 'height: 17px; width: 17px; filter: grayscale(100%) brightness(200%);',
        }),
      ]
    ),
  ]);
}

const showAdvanced = ref(false);
const switchChapterLesson = ref('chapter');
const openChapterInfo = ref(false);
const chapterInfoFormRef = ref();
let chapterInfo = reactive({
  "id": '',
  "name": '',
  "english_name": '',
  "remark": '',
  "teaching_purpose": '',
  "teaching_emphasis": '',
  "teaching_difficulties": '',
  "political_education": '',
  "teaching_methods": '',
  "description": '',
  "course_brief": '',
});

const confirmChapterInfoLoading = ref(false)
const openChapterInfoModel = () => {
  ChapterInfoModelMode.value = 'create';
  openChapterInfo.value = true;
  if (chapterInfoFormRef.value) {
    chapterInfoFormRef.value.resetFields();
  }
};

let ChapterInfoModelMode = ref('update')
const chapterInfoOk = () => {
  jsonRPC({
    url: `/api/make/course/${course_id}/chapter/create`,
    params: {
      'name': chapterInfo.name,
      'english_name': chapterInfo.english_name,
      'remark': chapterInfo.remark,
      'teaching_difficulties': chapterInfo.teaching_difficulties,
      'teaching_methods': chapterInfo.teaching_methods,
      'teaching_emphasis': chapterInfo.teaching_emphasis,
      'teaching_purpose': chapterInfo.teaching_purpose,
      'political_education': chapterInfo.political_education,
      'description': chapterInfo.description,
      'course_brief': chapterInfo.course_brief,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取成功`, data);
      openChapterInfo.value = false;
      confirmChapterInfoLoading.value = true;
      skeletonLessons().then(() => {
        chapterInfoFormRef.value.resetFields();
        message.success('创建章成功')
      })
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  });
}

const closeChapterInfoModal = () => {
  openChapterInfo.value = false;
  chapterInfoFormRef.value.resetFields();
}

const chapter_id = ref(0)
const hasLessons = computed(() => {
  let chapter = data_list.chapters.find(i => i.id == chapter_id.value)
  if (chapter) {
    return chapter.lessons
  } else {
    return []
  }
});
const chapterEdit = e => {
  switchChapterLesson.value = 'lesson';
  chapter_id.value = e;
}

const chapterInfoEdit = (e,id) => {
  e.preventDefault();
  e.stopPropagation(); // 阻止事件冒泡
  if (data_list.is_management && data_list.can_write) {
    ChapterInfoModelMode.value = 'update'
    openChapterEdit.value = false;
    openChapterInfo.value = true;
    fetchDataCourseChaptersInfo(course_id, id)
  }
}

const saveChapterInfo = e => {
  logDebug(`查询updateDataCourseChaptersInfo, `, e);
  updateDataCourseChaptersInfo(course_id, e)
  openChapterInfo.value = false;
  if (chapterInfoFormRef.value) {
    chapterInfoFormRef.value.resetFields();
  }
}

async function updateDataCourseChaptersInfo(course_id, chapterId) {
  await jsonRPC({
    url: `/api/make/course/${course_id}/chapter/${chapterId}/update`,
    params: {
      'name': chapterInfo.name,
      'english_name': chapterInfo.english_name,
      'remark': chapterInfo.remark,
      'teaching_difficulties': chapterInfo.teaching_difficulties,
      'teaching_methods': chapterInfo.teaching_methods,
      'teaching_emphasis': chapterInfo.teaching_emphasis,
      'teaching_purpose': chapterInfo.teaching_purpose,
      'political_education': chapterInfo.political_education,
      'description': chapterInfo.description,
      'course_brief': chapterInfo.course_brief,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`更新课程章详情信息`, data);
      Object.assign(chapterInfo, data);
    },
    fail(error) {
      logError(`获取课程目录中的节失败, `, error);
      message.error(`获取课程目录中的节失败，[${JSON.stringify(getFailedMessage(error))}]`, 3);
    },
  })
}

async function fetchDataCourseChaptersInfo(course_id, chapterId) {
  await jsonRPC({
    url: `/api/make/course/${course_id}/chapter/${chapterId}/info`,
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取课程章详情信息`, data);
      Object.assign(chapterInfo, data);
    },
    fail(error) {
      logError(`获取课程目录中的节失败, `, error);
      message.error(`获取课程目录中的节失败，[${JSON.stringify(getFailedMessage(error))}]`, 3);
    },
  })
}

const chapterDelete = e => {
  chapter_id.value = e;
  if (hasLessons.value.length > 0) {
    return message.warn('本章已有关联小节，请先删除下属小节后再操作')
  }
  jsonRPC({
    url: `/api/make/course/chapter/delete`,
    params: {
      'chapter_id': e,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取成功`, data);
      skeletonLessons().then(() => {
        message.success('删除章成功')
      })
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  });
}
const openLessonInfo = ref(false);
let lessonInfo = reactive({
  "name": '',
});
const lessonInfoOk = () => {
  jsonRPC({
    url: `/api/make/course/lesson/create`,
    params: {
      'name': lessonInfo.name,
      'chapter_id': chapter_id.value,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取成功`, data);
      openLessonInfo.value = false;
      skeletonLessons().then(() => {
        message.success('创建节成功')
      })
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  });
}
const addLesson = e => {
  chapter_id.value = e;
  openLessonInfo.value = true;
}
const updateHistory = () => {
  // 打开新页面
  router.push(`/console/course/${course_id}/history`);
}
const lessonEdit = e => {
  // 打开新页面
  router.push(`/console/course/${course_id}/${chapter_id.value}/${e}/edit`);
}
const lessonDelete = e => {
  if (hasLessons.value.length > 0) {
    let lesson = hasLessons.value.find(i => i.id == e)
    if (lesson) {
      logDebug(lesson)
      if (lesson.video_id || lesson.description || lesson.exercise_paper_id || lesson.courseware_num > 0 || lesson.exercise_code_num > 0 ) {
        return message.warn('本节已有关联内容，请先删除节下内容后再操作')
      }
    }
  }
  jsonRPC({
    url: `/api/make/course/lesson/delete`,
    params: {
      'lesson_id': e,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取成功`, data);
      skeletonLessons().then(() => {
        message.success('删除节成功')
      })
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  });
}

// 发布课程弹窗
function showCoursePublishedConfirm(course_id) {
  let lesson_num = 0;
  outerLoop:  // 定义标签
  for (let chapter of data_list.chapters) {
    for (let lesson of chapter.lessons) {
      if (lesson) {
        lesson_num += 1;
        if (lesson_num > 0) {
          break outerLoop;  // 当课时数大于0时，退出两个循环
        }
      }
    }
  }
  if (lesson_num == 0) {
    return message.error("未有节内容，暂不能发布课程！")
  }
  Modal.confirm({
    title: '请再次确认是否发布课程?',
    icon: createVNode(ExclamationCircleOutlined),
    content: '请再次检查所创建课程内容是否正确！',
    async onOk() {
      try {
        return await jsonRPC({
          url: `/api/make/course/${course_id}/published`,
          success(res) {
            const data = getResponseData(res);
            logDebug(`发布课程`, data);
            message.success(`发布课程成功`, 3);
          },
          fail(error) {
            logError(`发布课程失败, `, error);
            message.error(`发布课程失败，[${JSON.stringify(getFailedMessage(error))}]`, 3);
          },
        })
      } catch {
        return logDebug('发布课程失败');
      }
    },
    onCancel() {
    },
  });
}

// 归档课程弹窗
function showCourseDoneConfirm(course_id) {
  Modal.confirm({
    title: '请再次确认是否归档课程?',
    icon: createVNode(ExclamationCircleOutlined),
    async onOk() {
      try {
        return await jsonRPC({
          url: `/api/make/course/${course_id}/done`,
          success(res) {
            const data = getResponseData(res);
            logDebug(`归档课程`, data);
            message.success(`归档成功`, 3);
          },
          fail(error) {
            logError(`归档课程失败, `, error);
            message.error(`归档课程失败，[${JSON.stringify(getFailedMessage(error))}]`, 3);
          },
        })
      } catch {
        return logDebug('归档课程失败');
      }
    },
    onCancel() {
    },
  });
}

// 获取课程统计数据
async function fetDataCourseStatistics(course_id) {
  jsonRPC({
    url: `/api/make/course/${course_id}/statistics`,
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取statistics_list成功`, data);
      Object.assign(statistics_list, data);
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  })
}

onMounted(() => {
  fetchData()
  fetDataCourseStatistics(course_id)
  skeletonLessons()
});
function fetchData() {
  jsonRPC({
    url: `/api/make/course/${course_id}/info`,
    params: {},
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取成功`, data);
      Object.assign(data_list, data);
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  });
}
let skeletonLessonsActive = ref(false);
const skeletonLessons = () => {
  skeletonLessonsActive.value = true
  return jsonRPC({
    url: `/api/make/course/${course_id}/chapter`,
    params: {},
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取成功`, data);
      Object.assign(data_list, data);
      skeletonLessonsActive.value = false
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  });
}
</script>

<style scoped lang="scss">
.console_course_detail {
  padding: 16px;

  .course_detail {
    > div {
      max-height: 100%;
      display: flex;
      flex-direction: column;

      > div {
        padding: 1.5rem;
        background-color: #fff;
        border: 1px solid #dcddde;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.18);
        border-radius: 4px;
        margin-bottom: 20px;

        h5 {
          font-size: 18px;
          margin-bottom: 15px;

          span {
            display: inline-block;
            font-size: 14px;
            color: #FDCE4F;
            margin-left: 10px;
          }
        }

        button {
          font-size: 16px;
          color: #fff;
          line-height: 30px;
          padding: 0 8px;
          border-radius: 8px;
          user-select: none;
          outline: none;
          background-color: #7DBEFF;

          &:not(:first-child) {
            margin-left: 10px;
          }

          &:hover {
            opacity: 0.8;
          }
        }

        &.detail {
          font-size: 16px;
          a {
            font-size: 16px;
            color: #0085ff;
          }
          p{
            font-size: 16px;
            span {
              color: #000;
              display: inline-block;
              line-height: 30px;
              padding: 0 15px;
              border-radius: 15px;
              background-color: #E4F1FF;

              &:not(:last-child) {
                margin-right: 5px;
                margin-bottom: 5px;
              }
            }
          }
          .domain_icon{
            flex-shrink: 0;
            img{
              cursor: pointer;
              width: 30px;
              height: 30px;
              border-radius: 50%;
              &:not(:last-child){
                margin-right: 5px;
              }
            }
          }
        }

        &.chapter {
          flex-grow: 1;

          h5 {
            display: flex;
            justify-content: space-between;
          }

          .accordion {
            padding: 7px 0;

            &:not(:last-child) {
              border-bottom: 1px solid #e4efff;
            }

            a {
              display: inline-block;
              height: 25px;
              width: 25px;
              border-radius: 50%;
              overflow: hidden;
              text-align: center;
              user-select: none;
              &:not(:last-child){
                margin-right: 5px;
              }
              background-color: #BFBFBF;
              &.course_video {
                background-color: #94d9fe;
              }
              &.course_experiment {
                background-color: #C1AAFF;
              }
              &.course_case {
                background-color: #99d094;
              }
              &.course_exercise {
                background-color: #ff8857;
              }
              &.course_courseware {
                background-color: #ff8b8b;
              }
            }
          }
        }

        &.count {
          .row {
            .col {
              text-align: center;

              h4 {
                color: #5087EC;
                font-size: 36px;
              }
            }
          }
        }

        &.team {
          h5 {
            display: flex;
            justify-content: space-between;
          }

          > div {
            display: flex;
            >span{
              flex-shrink: 0;
            }
            > div {
              flex-grow: 1;
              display: flex;
              flex-wrap: wrap;

              .user {
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                margin-right: 10px;
                margin-bottom: 10px;

                img {
                  display: block;
                  height: 50px;
                  width: 50px;
                  border-radius: 50%;
                  margin-bottom: 5px;
                }
              }
            }
            .add_user{
              img{
                height: 50px;
                width: 50px;
                border-radius: 50%;
                cursor: pointer;
              }
            }
          }
        }

        &.need {
          flex-grow: 1;

          h5 {
            display: flex;
            justify-content: space-between;
          }

          > div {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-bottom: 10px;

            span {
              margin-left: 40px;
              color: #8E8E8E;

              &::before {
                content: '';
                display: inline-block;
                height: 15px;
                width: 15px;
                border-radius: 50%;
                transform: translate(-26%, 16%);
                background-color: #8E8E8E;
              }

              &.Doing {
                color: #96CAFF;

                &::before {
                  background-color: #96CAFF;
                }
              }

              &.publish {
                color: #ADFFB5;

                &::before {
                  background-color: #ADFFB5;
                }
              }
            }
          }
        }
      }
    }
  }
}

:global(.formChapter .formChapter_btn) {
  display: inline-block;
  font-size: 16px;
  color: #fff;
  line-height: 30px;
  padding: 0 8px;
  border-radius: 8px;
  user-select: none;
  margin-right: 10px;
  background-color: #B8B8B8;
}

:global(.formChapter .formChapter_btn.active) {
  background-color: #7DBEFF;
}
:global(.teamform img){
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
:global(.teamform .user){
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-right: 10px;
  margin-bottom: 10px;
}
:global(.teamform input[type="radio"]){
  zoom: calc(20 / 13);
  display: flex;
  width: 100%;
  box-shadow: none !important;
}
:global(.teamform label){
  margin-bottom: 0;
  display: flex;
  justify-content: center;
}
:global(.teamform input[type='checkbox']){
  display: none;
}
:global(.teamform i){
  position: relative;
  flex-shrink: 0;
  display: inline-block;
  width: 20px;
  height: 20px;
  background: #fff;
  border: 2px solid #999;
  border-radius: 50%;
}
:global(.teamform input[type='checkbox']:checked + i){
  border: 2px solid #0075ff;
}
:global(.teamform input[type='checkbox']:checked + i::before){
  content: '';
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  background: #0075ff;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
</style>