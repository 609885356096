<template>
  <div class="user-center">
    <a-form ref="formRef" :model="userInfo" :rules="rules" layout="horizontal"
      :label-col="{ style: { width: '80px' } }" :wrapper-col="{ span: 14 }">
      <a-row>
        <a-col :span="10">
          <a-form-item label="用户名" name="login" style="display:none">
            <a-input v-model:value="userInfo.login" :disabled="true" placeholder="请输入用户名"/>
          </a-form-item>
          <a-form-item label="昵称" name="name">
            <a-input v-model:value="userInfo.name" placeholder="请输入昵称"/>
            <!-- <div class="remind">
              <img src="/hw_web/static/src/img/remind.png" height="15" />
              考试认证学生请填写真实姓名
            </div> -->
          </a-form-item>
          <a-form-item label="性别" name="sex">
            <a-select v-model:value="userInfo.sex" :options="userInfo.sex_ids" :filter-option="filterOption"
              show-search :allowClear="true" placeholder="请选择性别">
            </a-select>
          </a-form-item>
          <a-form-item label="联系电话" name="phone">
            <a-input v-model:value="userInfo.phone" placeholder="请输入联系电话"/>
          </a-form-item>
          <a-form-item label="电子邮箱" name="email">
            <a-input v-model:value="userInfo.email" placeholder="请输入电子邮箱"/>
          </a-form-item>
          <a-form-item label="所属院校" name="school_id">
            <a-select v-model:value="userInfo.school_id" :options="userInfo.school_ids" :filter-option="filterOption"
              show-search :allowClear="true" placeholder="请选择所属院校">
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="头像" name="file_list">
            <a-upload v-model:file-list="userInfo.file_list" :customRequest="uploadDummyRequest"
              list-type="picture-card" @preview="handlePreview" :max-count="1" accept=".png,.jpg,.jpeg">
              <div v-if="userInfo.file_list.length < 1">
                <plus-outlined />
                <div class="ant-upload-text">上传图片</div>
              </div>
            </a-upload>
            <a-modal :open="previewVisible" :title="previewTitle" :footer="null" @cancel="cancelPreview">
              <img alt="example" style="width: 100%" :src="previewImage" />
            </a-modal>
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <div class="tab-buttons">
            <a-button type="primary" shape="round" @click="showWechatModal">微信绑定</a-button>
            <a-button type="primary" shape="round" @click="showPasswordModal">修改密码</a-button>
          </div>
        </a-col>
      </a-row>
      <div class="authentication">
        <h5>实名认证信息<small>* 完成实名认证后方可使用评论、互动论坛、考试认证等功能</small></h5>
        <a-row>
          <a-col :span="10">
            <a-form-item label="真实姓名" name="user_name">
              <a-input v-model:value="displayUserName" :disabled="userInfo.meta3phone" placeholder="请输入真实姓名"/>
            </a-form-item>
            <a-form-item label="身份证号" name="identify_num">
              <a-input v-model:value="displayIdentifyNum" :disabled="userInfo.meta3phone" placeholder="请输入有效的18位身份证号码"/>
            </a-form-item>
            <a-form-item label="手机号" name="mobile">
              <a-input v-model:value="displayMobile" :disabled="userInfo.meta3phone" placeholder="请输入手机号"/>
            </a-form-item>
            <a-form-item label="验证码" name="verify_code" v-if="!userInfo.meta3phone">
              <div style="display: flex;align-items: stretch;">
                <a-input v-model:value="userInfo.verify_code" style="margin-right: 10px;"/>
                <a-button type="primary" :disabled="isDisabled" @click="getVerificationCode" style="height: auto;width: auto;">
                  {{ isDisabled ? `重新获取(${curCount})` : '获取验证码' }}
                </a-button>
              </div>
            </a-form-item>
          </a-col>
          <a-col :span="10">
            <a-button v-if="!userInfo.meta3phone" type="primary" shape="round" @click="verify">认证</a-button>
            <a-button v-else type="primary" shape="round" @click="resetVerify">重新认证</a-button>
          </a-col>
        </a-row>
        <div v-if="userInfo.meta3phone" class="authentication-status"><CheckCircleFilled style="color: #36AB60;"/>已通过实名认证</div>
        <div v-else class="authentication-status"><ExclamationCircleFilled style="color: #FF3838;"/>未通过实名认证，请重新填写</div>
      </div>
      <a-form :model="setUserDefaultZone" layout="horizontal" :label-col="{ style: { width: '120px' } }" :wrapper-col="{ span: 16 }">
        <h5>设置可用区</h5>
        <a-row>
          <a-col :span="10">
            <a-form-item label="默认可用区" name="zone_code_selected">
              <a-select v-model:value="setUserDefaultZone.zone_code_selected" placeholder="默认可用区">
                <a-select-option @change="changeUserDefaultZone"
                  v-for="item in setUserDefaultZone.zone_code_options"
                  :key="item.zone_code" :value="item.zone_code"
                >
                  {{ item.zone_name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <a-flex justify="center" align="center">
        <a-button type="primary" shape="round" size="large" @click="confirmFormUpdate">保存</a-button>
      </a-flex>
    </a-form>
    <!-- 微信绑定弹窗 -->
    <a-modal v-model:visible="wechatModalVisible" title="微信绑定" :footer="null" width="500px">
      <template v-if="userInfo.wechat_unionid">
        <a-card title="基本信息" class="mb-4">
          <a-row :gutter="16">
            <a-col :span="24">
              <a-form-item label="微信头像">
                <img :src="`/web/image/res.users/${userInfo.id}/wechat_avatar?t=${new Date().getTime()}`" height="80" width="80"/>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="16">
            <a-col :span="24">
              <a-form-item label="微信昵称">
                <span>{{ userInfo.wechat_nickname }}</span>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="16">
            <a-col :span="20" :offset="4">
              <a-popconfirm title="确认解除绑定？" @confirm="unbind">
                <a-button type="primary" danger ghost>解除绑定</a-button>
              </a-popconfirm>
            </a-col>
          </a-row>
        </a-card>
      </template>
      <template v-else>
        <h4 class="text-center mb-4">扫描下方二维码绑定微信</h4>
        <iframe :src="wechatIframeSrc" frameborder="0"/>
      </template>
    </a-modal>
    <!-- 修改密码弹窗 -->
    <a-modal v-model:visible="passwordModalVisible" title="修改密码" @ok="changePwd" width="600px">
      <a-form ref="formPwdRef" :model="userInfo" :rules="rules" layout="horizontal"
        :label-col="{ style: { width: '120px' } }" :wrapper-col="{ span: 16 }">
        <a-form-item label="新密码" name="new_password">
          <a-input-password v-model:value="userInfo.new_password" placeholder="请输入新密码"/>
        </a-form-item>
        <a-form-item label="确认新密码" name="confirm_pwd">
          <a-input-password v-model:value="userInfo.confirm_pwd" placeholder="请输入新密码"/>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script setup>
import { CheckCircleFilled, ExclamationCircleFilled } from '@ant-design/icons-vue';
import { computed, onMounted, reactive, ref } from "vue";
import { getResponseData, jsonRPC } from "@/utils/http_utils";
import { getBase64,newCancelPreview, newHandlePreview, newPreviewState, newUploadDummyRequest } from "@/utils/file_utils";
import { logDebug, logError } from "@/utils/logger";
import { message, Modal } from "ant-design-vue";
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { createVNode } from 'vue';

const { previewVisible, previewImage, previewTitle } = newPreviewState();
const cancelPreview = newCancelPreview(previewVisible, previewTitle);
const handlePreview = newHandlePreview(previewImage,previewVisible,previewTitle);
const uploadDummyRequest = newUploadDummyRequest();

const formRef = ref();
const rules = {
  pet_name: [{ required: true, message: "请输入昵称", trigger: "blur",},],
  file_list: [{ required: true, message: "请上传头像", trigger: "change",},],
  sex: [{ required: true, message: "请选择性别", trigger: "change",},],
  name: [{ required: true, message: "请输入真实姓名", trigger: "blur",},],
  phone: [
    { required: true, message: "请输入联系电话", trigger: "blur",},
    { pattern: /^1[3-9]\d{9}$/, message: "请输入有效的手机号码（11位数字）", trigger: "blur",},
  ],
  email: [
    { required: true, message: "请输入电子邮箱", trigger: "blur",},
    { type: "email", message: "请输入有效的电子邮箱地址", trigger: ["blur", "change"],},
  ],
  identification: [
    {
      pattern:
        /^[1-9]\d{5}(18|19|20)\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])\d{3}(\d|X|x)$/,
      message: "请输入有效的18位身份证号码",
      trigger: "blur",
    },
  ],
  new_password: [
    { required: true, message: "新密码不能为空", trigger: "blur" },
    { min: 8, max: 16, message: "新密码长度需为8-16字符", trigger: "blur" },
    {
      pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#_])[A-Za-z\d@$!%*?&#_]{8,16}$/,
      message: "新密码至少包含一个大小写字母、数字和特殊字符",
      trigger: "blur",
    },
  ],
  confirm_pwd: [
    { required: true, message: "确认新密码不能为空", trigger: "blur" },
    {
      validator(_, value) {
        if (value !== userInfo.new_password) {
          return Promise.reject("确认新密码需与新密码一致");
        }
        return Promise.resolve();
      },
      trigger: "blur",
    },
  ],
  // 认证
  user_name: [
    { required: true, message: "请输入真实姓名", trigger: "blur",},
    { min: 2, max: 10, message: "姓名长度需为2-10字符", trigger: "blur",},
    { pattern: /^[a-zA-Z\u4e00-\u9fa5]+$/, message: "姓名仅支持中文、英文", trigger: "blur",},
  ],
  identify_num: [
    { required: true, message: "请输入身份证号", trigger: "blur",},
    { pattern: /^[1-9]\d{5}(18|19|20)\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])\d{3}(\d|X|x)$/, message: "请输入有效的18位身份证号码", trigger: "blur",},
  ], 
  mobile: [
    { required: true, message: "请输入手机号", trigger: "blur",},
    { pattern: /^1[3-9]\d{9}$/, message: "请输入有效的手机号码（11位数字）", trigger: "blur",},
  ],
  // 验证码
  verify_code: [
    { required: true, message: "请输入验证码", trigger: "blur",},
  ],
  // 默认可用区
  zone_code_selected: [{ required: true, message: "请选择用户默认可用区", trigger: "change",},],
};
const userInfo = reactive({
  login: "", // 用户名
  name: "", // 昵称
  sex: "", // 性别
  phone: "", // 联系电话
  email: "", // 电子邮箱
  school_id: null, // 院校ID
  file_list: [], // 头像
  wechat_unionid: false, // 微信绑定
  wechat_nickname: "", // 微信昵称
  meta3phone: false, // 实名认证是否通过
  user_name: "", // 真实姓名
  mobile: "", // 手机号
  verify_code: "", // 验证码
  identify_num: "", // 身份证号
  sex_ids: [],
  school_ids: [],
  new_password: "",
  confirm_pwd: "",
});
// 计算属性：根据 meta3phone 判断是否隐藏信息
// 真实姓名（脱敏 + 可修改）
const displayUserName = computed({
  get: () => userInfo.meta3phone 
    ? userInfo.user_name.replace(/^(.)(.*)$/, (_, first, rest) => first + '*'.repeat(rest.length)) 
    : userInfo.user_name,
  set: (newValue) => { 
    userInfo.user_name = newValue;
  }
});
// 身份证号（脱敏 + 可修改）
const displayIdentifyNum = computed({
  get: () => userInfo.meta3phone 
    ? userInfo.identify_num.replace(/^(\d{3})\d+(\d{4})$/, "$1************$2") 
    : userInfo.identify_num,
  set: (newValue) => { 
    userInfo.identify_num = newValue;
  }
});
// 手机号（脱敏 + 可修改）
const displayMobile = computed({
  get: () => userInfo.meta3phone 
    ? userInfo.mobile.replace(/^(\d{3})\d{4}(\d{4})$/, "$1****$2") 
    : userInfo.mobile,
  set: (newValue) => { 
    userInfo.mobile = newValue;
  }
});
const filterOption = (input, option) => {
  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};
const confirmFormUpdate = () => {
  formRef.value
    .validate()
    .then(async () => {
      const filePromises = await Promise.all(
        userInfo.file_list.map(async (file) => {
          const base64Data = await getBase64(file.originFileObj);
          return {
            ...file,
            raw_data: base64Data,
          };
        })
      );
      await jsonRPC({
        url: "/vue/users/update",
        params: {
          name: userInfo.name,
          file_list: filePromises,
          sex: userInfo.sex,
          phone: userInfo.phone,
          email: userInfo.email,
          school_id: userInfo.school_id,
        },
        success() {
          message.success("保存成功");
        },
        fail(error) {
          logError(`保存失败, `, error);
          message.error("保存失败, 请稍后再试");
        },
      });
      return;
    })
    .catch((error) => {
      logError("error", error);
      return;
    });
};
onMounted(() => {
  fetch1Data();
  fetch2Data();
  fetchZoneData();
});
const wechatModalVisible = ref(false);
const passwordModalVisible = ref(false);

const showWechatModal = () => {
  wechatModalVisible.value = true;
};

const showPasswordModal = () => {
  passwordModalVisible.value = true;
};

function fetch1Data() {
  jsonRPC({
    url: "/vue/users/setting",
    params: {},
    success(res) {
      const data = getResponseData(res);
      logDebug(`查询成功`, data);
      Object.assign(userInfo, data);
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  });
}

function fetch2Data() {
  jsonRPC({
    url: "/vue/users/wechat",
    params: {},
    success(res) {
      const data = getResponseData(res);
      logDebug(`查询成功`, data);
      Object.assign(userInfo, data);
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  });
}

const unbind = () => {
  jsonRPC({
    url: "/vue/users/unbind/wechat",
    params: {},
    success() {
      message.success("解除绑定成功");
      wechatModalVisible.value = false;
      fetch1Data();
    },
    fail(error) {
      logError(`解除绑定失败, `, error);
      message.error("解除绑定失败");
    },
  });
};
const formPwdRef = ref();
const changePwd = () => {
  formPwdRef.value
    .validate()
    .then(() => {
      jsonRPC({
        url: "/vue/users/changePassword",
        params: {
          new_password: userInfo.new_password,
          confirm_pwd: userInfo.confirm_pwd,
        },
        success(res) {
          const data = getResponseData(res);
          logDebug(`保存成功`, data);
          message.success("密码修改成功");
          passwordModalVisible.value = false;
          userInfo.new_password = "";
          userInfo.confirm_pwd = "";
        },
        fail(error) {
          logError(`保存失败, `, error);
          message.error("密码修改失败："+error);
        },
      });
      return;
    })
    .catch((error) => {
      logDebug("error", error);
      return;
    });
};

const setUserDefaultZone = reactive({
  zone_code_selected: "",
  zone_code_options: [],
});

function fetchZoneData() {
  jsonRPC({
    url: "/vue/console/zone",
    params: {},
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取可用区信息成功`, data);
      setUserDefaultZone.zone_code_options = data.zone_list;
      setUserDefaultZone.zone_code_selected = data.user_default_zone_code;
    },
    fail(error) {
      logError(`获取可用区信息失败, `, error);
    },
  });
}

const changeUserDefaultZone = () => {
  jsonRPC({
    url: "/vue/console/zone/update",
    params: {
      zone_code: setUserDefaultZone.zone_code_selected,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`设置用户默认可用区成功`, data);
      message.info(`设置用户默认可用区成功`, 3);
    },
    fail(error) {
      logError(`设置用户默认可用区失败, `, error);
      message.error(`设置用户默认可用区失败`, 3);
    },
  }).then(function () {
    fetchZoneData();
  });
};
const wechatIframeSrc = computed(() => {
  return `https://open.weixin.qq.com/connect/qrconnect?appid=${userInfo.wechat_login_appid}&styletype=
            &scope=snsapi_login&redirect_uri=${userInfo.wechat_login_redirect_uri}&sizetype=
            &state=${userInfo.wechat_login_state}&bgcolor=
            &login_type=jssdk
            &fast_login=0
            &self_redirect=false
            &style=${userInfo.wechat_login_style}&rst=
            &href=${userInfo.wechat_login_href}`;
  // 参考：https://developers.weixin.qq.com/doc/oplatform/Website_App/WeChat_Login/Wechat_Login.html
});
const curCount = ref(120);
const timer = ref(null);
const isDisabled = ref(false);
const getVerificationCode = ()=>{
  formRef.value.validateFields(['mobile'])
  .then(() => {
    // 验证通过后执行获取验证码的逻辑
    jsonRPC({
      url: "/register/send_user_sms/verify",
      params: {
        mobile: userInfo.mobile,
      },
      success(res) {
        logDebug(`验证码获取成功`, res)
        isDisabled.value = true;
        curCount.value = 120;
        timer.value = setInterval(SetRemainTime, 1000);
      },
      fail(error) {
        logError(`验证码获取失败, `, error)
        message.error(`验证码获取失败，[${error}]`);
      }
    })
  })
  .catch((error) => {
    // 验证失败时处理逻辑
    logDebug('手机号验证未通过:', error);
  });
}
const SetRemainTime = () => {
  if (curCount.value === 0) {
    clearInterval(timer.value);
    timer.value = null;
    isDisabled.value = false;
  } else {
    curCount.value--;
  }
};
const verify = () => {
  formRef.value.validateFields(['mobile','verify_code'])
  .then(() => {
    jsonRPC({
      url: "/api/auth/real/name",
      params: {
        auth_type: 'meta3phone',
        identify_num: userInfo.identify_num,
        user_name: userInfo.user_name,
        mobile: userInfo.mobile,
        verify_code: userInfo.verify_code,
      },
      success(res) {
        const data = getResponseData(res);
        logDebug(`实名认证信息`, data);
        if (data.verify_passed) {
          message.success("实名认证成功");
          userInfo.meta3phone = true;
        }else{
          message.error("实名认证失败,"+data.verify_description);
          userInfo.meta3phone = false;
        }
      },
      fail(error) {
        logError(`实名认证失败, `, error);
        message.error(`实名认证失败，[${error}]`);
        userInfo.meta3phone = false;
      },
    });
  })
  .catch((error) => {
    // 验证失败时处理逻辑
    logDebug('手机号验证未通过:', error);
  });
};
const resetVerify = () => {
  Modal.confirm({
    title: '你确定要重新认证吗？',
    icon: createVNode(ExclamationCircleOutlined),
    content: createVNode(
      'div',{style: 'color:red;',},
      '重置后，之前的认证信息将会被清空，请确认是否重新认证？'
    ),
    onOk() {
      jsonRPC({
        url: "/api/auth/real/name/reset",
        params: {},
        success() {
          message.success("重置成功");
          fetch1Data()
        },
        fail(error) {
          message.error(`重置失败, ${error}`);
        },
      });
    },
  });
}
</script>

<style scoped lang="scss">
.user-center {
  height: 100%;
  width: 100%;
  background-color: #fff;
  overflow-y: auto;
  padding: 24px;
  .remind {
    display: flex;
    align-items: center;
    column-gap: 5px;
    margin-top: 5px;
    font-size: 12px;
    color: #999;
  }
  .tab-buttons{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: 10px;
  }
  h5{
    font-size: 16px;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 15px;
    small{
      font-size: 14px;
      color: #FF8B8B;
    }
  }
  .authentication{
    border-top: 1px dashed #62A4FF;
    border-bottom: 1px dashed #62A4FF;
    padding: 15px 0;
    margin-bottom: 15px;
    .authentication-status{
      display: flex;
      gap: 5px;
      :where(.anticon){
        font-size: 20px;
      }
    }
  }
}

iframe {
  display: inherit;
  width: 235px;
  height: 270px;
  margin: 0 auto;
}
:where(.ant-form-item){
  margin-bottom: 7px;
}
:where(.ant-btn-primary){
  background-color: #53A6FF;
}
:deep(.ant-form-item .ant-form-item-label){
  text-align: left;
}
</style>
