<template>
  <div class="blog_detail">
    <a-breadcrumb separator=">">
      <a-breadcrumb-item>
        <router-link :to="backPath">{{ backTitle }}</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item v-if="backTitle == '技术论坛' && forum_section_name">
        <router-link :to="`/forum/section/${forum_section_id}`">{{ forum_section_name }}</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>{{ data_list.name }}</a-breadcrumb-item>
    </a-breadcrumb>
    <a-row gutter="20">
      <a-col :span="19">
        <div class="info">
          <div class="blog_list">
            <div class="blog_list_top">
              <h5>
                {{data_list.name}}
                <span v-for="i in data_list.tag_ids" :key="i.id">{{i.name}}</span>
              </h5>
              <span>{{ data_list.post_date }}</span>
              <a-pagination v-if="false"
                v-model:current="current"
                :total="total"
                :defaultPageSize="1"
                :showSizeChanger="false"
                :locale="zhCn"
                @change="handlePageChange"
              />
            </div>
            <div class="blog_list_content">
              <div>
                <div class="blog_user">
                  <router-link :to="`/blog/homepage?user_id=${data_list.create_uid?.id}`" class="user_info">
                    <img v-if="data_list.create_uid?.has_image" :src="`/web/image/res.users/${data_list.create_uid?.id}/image_512`"/>
                    <img v-else src="/hw_web/static/src/img/avatar_live.png" />
                    <h5>{{ data_list.create_uid?.name }}</h5>
                  </router-link>
                  <div class="blog_info">
                    <a>
                      <span class="count">{{data_list.create_uid?.user_blog_num}}</span>
                      <span class="title">帖子</span>
                    </a>
                    <a>
                      <span class="count">{{data_list.create_uid?.blog_attention_num}}</span>
                      <span class="title">关注</span>
                    </a>
                    <a>
                      <span class="count">{{data_list.create_uid?.blog_fan_num}}</span>
                      <span class="title">粉丝</span>
                    </a>
                  </div>
                  <a style="margin: 1rem 0;text-align: center;" v-if="!data_list.only_poster" @click="data_list.only_poster = true">只看发帖人</a>
                  <a style="margin: 1rem 0;text-align: center;" v-else @click="data_list.only_poster = false">查看所有</a>
                </div>
                <div style="flex-grow: 1;">
                  <a-typography-text>
                    <div v-html="data_list.content"></div>
                  </a-typography-text>
                  <div class="blog_list_bottom">
                    <a-tooltip placement="bottom">
                      <template #title>浏览量</template>
                      <span>
                        <img src="/hw_blog/static/src/img/visited.png" />
                        <span>{{ data_list.visits }}</span>
                      </span>
                    </a-tooltip>
                    <a-tooltip placement="bottom">
                      <template #title>评论人数</template>
                      <span>
                        <img v-if="data_list.is_comments" src="/hw_blog/static/src/img/info.png"/>
                        <img v-else src="/hw_blog/static/src/img/comment.png" />
                        <span>{{ data_list.comments }}</span>
                      </span>
                    </a-tooltip>
                    <a-tooltip placement="bottom">
                      <template #title>点赞</template>
                      <span>
                        <img v-if="data_list.is_likes" @click="onBlogLike" src="/hw_blog/static/src/img/liked.png"/>
                        <img v-else @click="onBlogLike" src="/hw_blog/static/src/img/praise.png"/>
                        <span>{{ data_list.likes }}</span>
                      </span>
                    </a-tooltip>
                    <a @click="onComment" style="font-size: 18px;color: #007bff;">回复</a>
                  </div>
                </div>
              </div>
              <img v-if="data_list.is_image" :src="`/web/image/hw.blog.post/${blog_id}/image_512`"/>
            </div>
            <a-comment v-show="showComment">
              <!-- <template #avatar>
                <a-avatar v-if="data_list.user?.has_image" :src="`/web/image/res.users/${data_list.user?.id}/image_512`" :size="40" style="margin-top: 4px"/>
                <a-avatar v-else src="/hw_web/static/src/img/avatar_live.png" :size="40" style="margin-top: 4px"/>
              </template> -->
              <template #content>
                <a-flex justify="space-between" align="center" gap="12">
                  <RichEditor :height="301" :editMode="'create'" v-model="commentValue" @keydown.enter="handleSubmit"/>
                  <a-button :loading="submitting" type="primary" @click="handleSubmit" class="reply-btn">回帖</a-button>
                </a-flex>
              </template>
            </a-comment>
          </div>
          <div class="comment_list px-4">
            <a-list :data-source="data_list.comment_ids" :pagination="pagination">
              <template #renderItem="{ item }">
                <a-list-item class="px-0">
                  <div class="item w-100">
                    <div class="comment_content">
                      <div class="blog_user">
                        <router-link :to="`/blog/homepage?user_id=${item.create_uid?.id}`" class="user_info">
                          <img v-if="item.create_uid?.has_image" :src="`/web/image/res.users/${item.create_uid?.id}/image_512`"/>
                          <img v-else src="/hw_web/static/src/img/avatar_live.png" />
                          <h5>{{ item.create_uid?.name }}</h5>
                        </router-link>
                        <div class="blog_info">
                          <a>
                            <span class="count">{{item.create_uid?.user_blog_num}}</span>
                            <span class="title">帖子</span>
                          </a>
                          <a>
                            <span class="count">{{item.create_uid?.blog_attention_num}}</span>
                            <span class="title">关注</span>
                          </a>
                          <a>
                            <span class="count">{{item.create_uid?.blog_fan_num}}</span>
                            <span class="title">粉丝</span>
                          </a>
                        </div>
                      </div>
                      <div class="content_info">
                        <div class="body" style="min-height: 93px;" v-html="item.comment"></div>
                        <div class="bottom">
                          <!-- <span class="date mr-3" :title="`最新评论第${index+1}楼`"><em style="font-style: normal;">{{index+1}}</em><sup>#</sup></span> -->
                          <span class="date mr-3">{{ item.create_date }}</span>
                          <a v-if="item.is_likes" class="praise active mr-3">
                            <LikeFilled @click="onLike(item.id)" />
                            <span class="ml-1">{{ item.likes }}</span>
                          </a>
                          <a v-else class="praise mr-3">
                            <LikeOutlined @click="onLike(item.id)" />
                            <span class="ml-1">{{ item.likes }}</span>
                          </a>
                          <a-popconfirm v-if="item.is_delete" title="确定要删除?" @confirm="commentDelete(item.id)">
                            <a>删除</a>
                          </a-popconfirm>
                          <template v-else>
                            <a @click="item.visible = !item.visible" class="mr-3">回复</a>
                            <div v-show="item.visible" class="comment_post">
                              <img :src="`/web/image/res.users/${data_list.user?.id}/image_512`" class="mr-2"/>
                              <RichEditor :height="301" :editMode="'create'" v-model="rec_commentValue" @keydown.enter="handleSubmit1(item.id)"/>
                              <a-button :loading="submitting" @click="handleSubmit1(item.id)">回帖</a-button>
                            </div>
                          </template>
                        </div>
                        <div class="reply" v-if="item.comment_ids.length">
                          <div class="comment_list">
                            <div v-for="com in item.comment_ids" :key="com.id" class="item">
                              <div class="comment_content">
                                <div class="avatar mr-2">
                                  <img v-if="com.create_uid.has_image" :src="`/web/image/res.users/${com.create_uid.id}/image_512`" class="mr-2"/>
                                  <img v-else src="/hw_web/static/src/img/avatar_live.png" class="mr-2"/>
                                </div>
                                <div class="content_info">
                                  <div class="top mb-2">
                                    {{ com.create_uid?.name }}
                                    <span class="text-dark">回复</span>
                                    {{ item.create_uid?.name }} :
                                  </div>
                                  <div class="body" v-html="com.comment"></div>
                                  <div class="bottom">
                                    <span class="date mr-3">{{ com.create_date }}</span>
                                    <a v-if="com.is_likes" class="praise active mr-3">
                                      <LikeFilled @click="onLike(com.id)" />
                                      <span class="ml-1">{{ com.likes }}</span>
                                    </a>
                                    <a v-else class="praise mr-3">
                                      <LikeOutlined @click="onLike(com.id)" />
                                      <span class="ml-1">{{ com.likes }}</span>
                                    </a>
                                    <a-popconfirm v-if="com.is_delete" title="确定要删除?" @confirm="commentDelete(com.id)">
                                      <a>删除</a>
                                    </a-popconfirm>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a-list-item>
              </template>
            </a-list>
            <a-comment>
              <!-- <template #avatar>
                <a-avatar v-if="data_list.user?.has_image" :src="`/web/image/res.users/${data_list.user?.id}/image_512`" :size="40" style="margin-top: 4px"/>
                <a-avatar v-else src="/hw_web/static/src/img/avatar_live.png" :size="40" style="margin-top: 4px"/>
              </template> -->
              <template #content>
                <a-flex justify="space-between" align="center" gap="12">
                  <RichEditor :height="301" :editMode="'create'" v-model="commentValue" @keydown.enter="handleSubmit"/>
                  <a-button :loading="submitting" type="primary" @click="handleSubmit" class="reply-btn">回帖</a-button>
                </a-flex>
              </template>
            </a-comment>
          </div>
        </div>
      </a-col>
      <a-col :span="5">
        <div class="blog_user" v-show="false">
          <div class="user_info">
            <img v-if="data_list.user?.has_image" :src="`/web/image/res.users/${data_list.user?.id}/image_512`"/>
            <img v-else src="/hw_web/static/src/img/avatar_live.png" />
            <h5>{{ data_list.user?.id == 4?'未登录':data_list.user?.name }}</h5>
          </div>
          <div class="blog_info">
            <router-link :to="`/blog/homepage`">
              <span class="count">{{data_list.user_blog_num}}</span>
              <span class="title">帖子</span>
            </router-link>
            <router-link :to="`/blog/attention`">
              <span class="count">{{data_list.blog_id?.blog_attention_ids.length}}</span>
              <span class="title">关注</span>
            </router-link>
            <router-link :to="`/blog/fan`">
              <span class="count">{{data_list.blog_id?.blog_fan_ids.length}}</span>
              <span class="title">粉丝</span>
            </router-link>
          </div>
        </div>
        <div class="blog_message" v-show="false">
          <h5>互动消息</h5>
          <router-link to="/blog/message?key=reply">
            <img src="/hw_frontend/static/img/blog_@my_icon.png"/>
            @我的
            <a-badge :count="data_list.message_count" :number-style="{ backgroundColor: '#FF8110' }"/>
          </router-link>
          <router-link to="/blog/message?key=comment">
            <img src="/hw_frontend/static/img/blog_comment_icon.png"/>
            评论
            <a-badge :count="data_list.comment_count" :number-style="{ backgroundColor: '#FF8110' }"/>
          </router-link>
          <router-link to="/blog/message?key=like">
            <img src="/hw_frontend/static/img/blog_like_icon.png"/>
            点赞
            <a-badge :count="data_list.like_count" :number-style="{ backgroundColor: '#FF8110' }"/>
          </router-link>
        </div>
        <div class="blog_hot_post">
          <h5>
            热帖推荐
            <span @click="onOneChange"><RedoOutlined />换一批</span>
          </h5>
          <ul>
            <li v-for="(item, index) in data_list.hot_blog" :key="index">
              <router-link :to="`/blog/${item.id}`" class="line-clamp-1" :title="item.name">
                {{ item.name }}
              </router-link>
            </li>
          </ul>
        </div>
      </a-col>
    </a-row>
    <a-back-top />
  </div>
</template>

<script setup>
import { RedoOutlined } from '@ant-design/icons-vue';
import RichEditor from "@/components/RichEditor.vue";
import { LikeOutlined, LikeFilled } from "@ant-design/icons-vue";
import { logDebug, logError } from "@/utils/logger";
import { h, onMounted, ref, reactive, computed, watch } from "vue";
import { getResponseData, jsonRPC } from "@/utils/http_utils";
import { getRouterParam, getRouterQuery } from "@/utils/router_utils";
import { useRouter } from "vue-router";
import { message, Modal } from "ant-design-vue";
import { zhCn } from "@/utils/zhCn";
import blogStore from '@/stores/blog.js';

const router = useRouter();
const blog_id = ref(Number(getRouterParam(router, "id")));
const forum_section_name = ref(String(getRouterQuery(router, "forum_section_name")));
const forum_section_id = ref(Number(getRouterQuery(router, "forum_section_id")));
const $blogStore = blogStore();

const data_list = reactive({
  only_poster: false,  // 只看发帖人
  name: null,
  user: null,
  post_date: null,
  tag_ids: [],
  content: null,
  is_image: false,
  visits: null,
  is_comments: null,
  comments: null,
  is_likes: null,
  likes: null,
  hot_blog: [],
  hot_blog_page_count: 2,
  hot_blog_page_index: 1,
  comment_ids: [],
});
function fetchData() {
  jsonRPC({
    url: `/api/blog/comment`,
    params: {
      only_poster: data_list.only_poster,
      id: blog_id.value,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取评论成功`, data);
      Object.assign(data_list, data);
    },
    fail(error) {
      logError(`获取评论失败`, error);
      message.error(`获取评论失败，请稍后再试`);
    },
  });
}
const pagination = reactive({
  // 分页配置
  pageSize: 5, // 每页显示的条数
  showSizeChanger: false, // 是否可以改变每页显示的条数
  // pageSizeOptions: ['9', '18', '81'], // 可选的每页显示条数
  showQuickJumper: false, // 是否可以快速跳转到指定页
  showTotal: total => `共 ${total} 条`, // 显示总条数和当前数据范围
  hideOnSinglePage: true, // 只有一页时是否隐藏分页器 true为不显示 false为显示
  // current: 1, // 当前页数
  // total: 0, // 总条数
  // onChange: markRaw(handlePageChange), // 页码改变时的回调函数
})

const backPath = computed(() => {
  if (backTitle.value == '技术论坛') {
    return '/forum';
  } else {
     return '/blog';
  }
});

const backTitle = computed(() => {
  // 根据来源路径显示不同的标题
  const previousRoute = router.options.history.state.back;
  if(previousRoute.includes('forum/section')  ){
    return '技术论坛';
  }else if(previousRoute.includes('forum')){
    return '技术论坛';
  }else{
    return '技术论坛';
  }
});
// 分页
const total = ref(2);
const current = ref(1);
function paginationFetchData() {
  jsonRPC({
    url: `/api/blog/pagination`,
    params: {
      only_poster: data_list.only_poster,
      user_id: data_list.create_uid?.id,
      page_index: current.value,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取论坛详情成功`, data);
      if (data.record_id){
        blog_id.value = data.record_id;
        fetchDetail()
      }
    },
    fail(error) {
      logError(`获取论坛详情失败`, error);
    },
  });
}
// 获取详情
function fetchDetail() {
  jsonRPC({
    url: `/api/blog/${blog_id.value}`,
    params: {
      only_poster: data_list.only_poster,
      user_id: data_list.create_uid?.id,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取论坛详情成功`, data);
      Object.assign(data_list, data);
      FeedbackCurrent()
    },
    fail(error) {
      logError(`获取论坛详情失败`, error);
    },
  });
  fetchData();
  $blogStore.setBlogId(blog_id.value);
}
// 回显页数和总数
function FeedbackCurrent() {
  jsonRPC({
    url: `/api/blog/current`,
    params: {
      id: blog_id.value,
      only_poster: data_list.only_poster,
      user_id: data_list.create_uid?.id,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取论坛详情成功`, data);
      current.value = data.current;
      total.value = data.record_count;
    },
    fail(error) {
      logError(`获取论坛详情失败`, error);
    },
  });
}
onMounted(fetchDetail);
const handlePageChange = () => {
  paginationFetchData();
};
watch(() => data_list.only_poster, ()=>{
  fetchData()
});

const showComment = ref(false);
const onComment = () => {
  showComment.value = !showComment.value;
};

const onOneChange = () => {
  if (data_list.hot_blog_page_index >= data_list.hot_blog_page_count) {
    data_list.hot_blog_page_index = 1;
  } else {
    data_list.hot_blog_page_index += 1;
  }
  jsonRPC({
    url: `/api/blog/change`,
    params: {
      page_index: data_list.hot_blog_page_index,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取热帖推荐成功`, data);
      Object.assign(data_list, data);
    },
    fail(error) {
      logError(`获取热帖推荐失败`, error);
      message.error(`获取热帖推荐失败，请稍后再试`);
    },
  });
};

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);
const submitting = ref(false);
const commentValue = ref("");
const rec_commentValue = ref("");
const handleSubmit = () => {
  if (!data_list.meta1phone) {
    Modal.warning({
      title: '实名认证提醒',
      content: h('div', {}, [
        h('p', `使用应用、实训、论坛等需要实名认证，未实名认证将会被限制使用，首次实名认证成功可获得 ${data_list.bonus_compute_points} 点算力点。`),
        h(
          'a',
          {
            style: { color: 'blue', cursor: 'pointer' },
            onClick: () => {
              router.push('/my'); // 点击蓝色 a 标签跳转
              Modal.destroyAll(); // 关闭弹窗
            },
          },
          '点击进行实名'
        )
      ]),
      width: '50%',
      footer: null,
      maskClosable: true,
      closable: true,
    });
    return;
  }
  if (commentValue.value.trim() == '<p><br></p>') {
    message.info("评论内容不能为空");
    return;
  }
  submitting.value = true;
  jsonRPC({
    url: `/api/blog/comment/create`,
    params: {
      blog_id: blog_id.value,
      comment: commentValue.value,
    },
    success(res) {
      logDebug(res);
      message.success(`评论成功`);
      fetchData();
    },
    fail(error) {
      logError(`评论失败`, error);
      message.error(error);
    },
  }).then(() => {
    submitting.value = false;
    commentValue.value = "";
  });
};
const handleSubmit1 = (e) => {
  if (!data_list.meta1phone) {
    Modal.warning({
      title: '实名认证提醒',
      content: h('div', {}, [
        h('p', '使用应用、实训、论坛等需要实名认证，未实名认证将会被限制使用，首次实名认证成功可获得 30 点算力点。'),
        h(
          'a',
          {
            style: { color: 'blue', cursor: 'pointer' },
            onClick: () => {
              router.push('/my'); // 点击蓝色 a 标签跳转
              Modal.destroyAll(); // 关闭弹窗
            },
          },
          '点击进行实名'
        )
      ]),
      width: '50%',
      footer: null,
      maskClosable: true,
      closable: true,
    });
    return;
  }
  if (rec_commentValue.value.trim() == '<p><br></p>') {
    message.info("评论内容不能为空");
    return;
  }
  submitting.value = true;
  jsonRPC({
    url: `/api/blog/${blog_id.value}/comment/${e}`,
    params: {
      comment: rec_commentValue.value,
    },
    success(res) {
      logDebug(res);
      message.success(`评论成功`);
      fetchData();
    },
    fail(error) {
      logError(`评论失败`, error);
      message.error(error);
    },
  }).then(() => {
    submitting.value = false;
    rec_commentValue.value = "";
  });
};
const commentDelete = (e) => {
  jsonRPC({
    url: `/api/blog/comment/delete`,
    params: {
      comment_id: e,
    },
    success(res) {
      logDebug(res);
      message.success(`删除评论成功`);
      fetchData();
    },
    fail(error) {
      logError(`删除评论失败`, error);
      message.error(`删除评论失败，请稍后再试`);
    },
  });
};
const onLike = (e) => {
  jsonRPC({
    url: `/api/blog/comment/like/${e}`,
    params: {},
    success(res) {
      logDebug(res);
      fetchData();
    },
    fail(error) {
      logError(`操作失败`, error);
    },
  });
};
const onBlogLike = () => {
  jsonRPC({
    url: `/api/blog/like/${blog_id.value}`,
    params: {},
    success(res) {
      logDebug(res);
      fetchData();
    },
    fail(error) {
      logError(`操作失败`, error);
    },
  });
};
</script>

<style scoped lang="scss">
span,a,div{
  word-break: break-all;
  white-space: normal;
}
.blog_detail {
  min-height: 80vh;
  padding-bottom: 30px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #ffe8da;
  a,div{
    word-break: break-all;
    white-space: normal;
  }
  .info {
    padding: 15px;
    background-color: #fff;
    .blog_title {
      display: flex;
      margin-bottom: 15px;
      .tags {
        flex: 1 1 100%;
        display: flex;
        flex-wrap: wrap;
        .tag {
          color: #000;
          border-radius: 8px;
          padding: 0.3rem 1rem;
          cursor: pointer;
          &.active,
          &:hover {
            background-color: #d5e9ff;
          }
          &:not(:last-child) {
            margin-right: 10px;
          }
        }
      }
      .search {
        flex: 0 0 auto;
        display: flex;
        align-items: center;
        .write_blog {
          display: flex;
          align-items: center;
          gap: 0.3rem;
          background-color: #77b7ff;
          color: #fff;
          border-radius: 15px;
          padding: 0.25rem 0.75rem;
          line-height: 20px;
          margin-right: 1rem;
          img {
            width: 16px;
            height: 16px;
          }
        }
        .ant-btn-primary {
          background-color: #77b7ff;
        }
      }
    }
    .blog_list {
      padding: 15px;
      padding-top: 5px;
      border-bottom: 1px solid #dee2e6;
      margin-bottom: 15px;
      .blog_list_top {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        color: #a1a1a1;
        margin-bottom: 20px;
        h5 {
          display: flex;
          flex-flow: wrap;
          gap: 5px;
          margin-bottom: 0;
          color: #000;
          font-size: 18px;
          font-weight: bold;
          span {
            background-color: #e4efff;
            color: #2580fd;
            padding: 3px 7px;
            border-radius: 4px;
            margin-bottom: -3px;
            position: relative;
            top: -2px;
            margin-left: 6px;
            font-size: 14px;
          }
        }
      }
      .blog_list_content {
        display: flex;
        gap: 10px;
        > div {
          flex-grow: 1;
          display: flex;
          .blog_user{
            width: 124px;
            flex-shrink: 0;
            margin-bottom: 0;
            margin-right: 20px;
            background-color: #f9f9f9;
            display: flex;
            flex-direction: column;
            padding: 12px;
            .user_info {
              img {
                margin-bottom: 15px;
              }
              h5 {
                font-size: 14px;
                margin-bottom: 15px;
              }
            }
            .blog_info {
              a {
                cursor: default;
                .count {
                  font-size: 18px;
                }
                .title {
                  font-size: 12px;
                }
              }
            }
            .ant-btn{
              margin-top: 15px;
            }
          }
          .user {
            display: flex;
            align-items: center;
            color: #a1a1a1;
            margin-bottom: 5px;
            &:hover {
              color: #ff8c00;
            }
            img {
              height: 25px;
              width: 25px;
              margin-right: 5px;
              border-radius: 50%;
              object-fit: cover;
            }
          }
          :where(.ant-typography){
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: normal;
            min-height: 180px;
          }
        }
        > img {
          flex-shrink: 0;
          width: 100%;
          max-width: 200px;
          object-fit: cover;
        }
      }
      .blog_list_bottom {
        display: flex;
        justify-content: flex-end;
        column-gap: 18px;
        margin-top: 5px;
        img {
          width: 25px;
          height: 25px;
          margin-right: 5px;
        }
      }
    }
    .ant-comment{
      .reply-btn {
        z-index: 199;
        position: absolute;
        right: 15px;
        bottom: 15px;
        height: 40px;
        background-color: #77b7ff;
      }
    }
  }
  .blog_user {
    border-radius: 8px;
    background-color: #fff;
    padding: 25px;
    margin-bottom: 15px;
    .user_info {
      display: block;
      text-align: center;
      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-bottom: 1rem;
        object-fit: cover;
      }
      h5 {
        font-size: 16px;
      }
    }
    .blog_info {
      display: flex;
      justify-content: space-around;
      a {
        display: flex;
        flex-direction: column;
        align-items: center;
        .count {
          font-size: 28px;
          color: #429bff;
        }
        .title {
          color: #000;
        }
      }
    }
  }
  .blog_message {
    border-radius: 8px;
    background-color: #fff;
    padding: 15px;
    margin-bottom: 15px;
    h5 {
      font-weight: bold;
      font-size: 16px;
    }
    img {
      width: 25px;
      height: 25px;
    }
    a {
      display: flex;
      gap: 10px;
      padding: 10px 0;
      color: #101010;
      &:hover {
        color: #ff8c00;
      }
      span {
        flex-shrink: 0;
        margin-left: auto;
        .ant-badge-count {
          min-width: 24px;
          height: 24px;
          font-size: 14px;
          line-height: 24px;
          border-radius: 12px;
        }
      }
    }
  }
  .blog_hot_post {
    flex-grow: 1;
    border-radius: 8px;
    background-color: #fff;
    padding: 15px;
    h5 {
      font-size: 16px;
      font-weight: bold;
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        font-size: 14px;
        color: #949494;
        cursor: pointer;
        display: flex;
        gap: 5px;
      }
    }
    ul {
      padding: 0;
      list-style: none;
      li {
        margin: 10px 0;
        a {
          color: #000;
          &:hover {
            color: #ff8c00;
          }
        }
      }
    }
  }
}
:deep(.ant-comment .ant-comment-avatar img) {
  width: 100%;
  height: 100%;
}
.comment_post {
  display: flex;
  margin-top: 1rem;
  position: relative;
  img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    flex: 0 0 auto;
    object-fit: cover;
  }
  input {
    border: 1px solid #77b7ff;
    border-radius: 8px;
    flex: 1 1 100%;
    padding: 0.35rem 0.5rem;

    &:focus-visible {
      border-width: 2px;
    }
  }
  button {
    z-index: 199;
    position: absolute;
    right: 15px;
    bottom: 15px;
    border-radius: 8px;
    background-color: #77b7ff;
    text-align: center;
    border: 1px solid #77b7ff;
    flex: 0 0 auto;
    color: #ffffff;
    padding: 0.3rem 0.75rem;
  }
}
.comment_list {
  .item {
    .comment_content {
      display: flex;
      .blog_user{
        width: 100px;
        flex-shrink: 0;
        margin-bottom: 0;
        margin-right: 0.5rem;
        padding: 0;
        .user_info {
          img {
            margin-bottom: 5px;
          }
          h5 {
            font-size: 14px;
            margin-bottom: 5px;
          }
        }
        .blog_info {
          a {
            cursor: default;
            .count {
              font-size: 18px;
            }
            .title {
              font-size: 12px;
            }
          }
        }
      }
      .avatar {
        flex-shrink: 0;
        img {
          width: 35px;
          height: 35px;
          border-radius: 50%;
          object-fit: cover;
        }
      }
      .content_info {
        flex: 1 1 100%;
        .top {
          color: #047AFF;
        }
        .body {
          color: #000;
          margin-bottom: 0.5rem;
        }
        .bottom {
          color: #9499a0;
          text-align: right;
          a {
            color: #9499a0;
            &:hover,
            &.active {
              color: #ff8c00;
            }
            i {
              font-weight: normal;
              font-size: inherit;
              bottom: auto;
              position: static;
            }
          }
        }
      }
    }
    .reply {
      margin-top: 1rem;
      .item {
        background-color: #EFF6FF;
        padding: 10px;
        border-radius: 8px;
      }
    }
  }
}
:deep(.ant-comment .ant-comment-inner){
  padding: 14px 0;
  .ant-comment-avatar{
    display: none;
  }
}
:deep(.ant-list-pagination){
  text-align: center;
}
</style>
