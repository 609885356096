<template>
  <div class="console_image_virtual_machine_detail">
    <a-breadcrumb separator=">">
      <a-breadcrumb-item>
        <router-link to="/console/image/vm">我的虚拟机镜像</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>{{ data_list.name }}</a-breadcrumb-item>
    </a-breadcrumb>
    <a-row style="padding: 0 20px">
      <a-col :span="24">
        <div>
          <a-row>
            <a-col :span="8">
              <a-typography-title :level="4">虚拟机镜像信息</a-typography-title>
            </a-col>
            <a-col :span="12">
            </a-col>
            <a-col :span="4">
              <a-button type="primary" @click="onDeleteImage" style="display: none;">删除虚拟机镜像</a-button>
            </a-col>
          </a-row>
          <a-form ref="formRef" :disabled="editorMode === 'readonly'" :label-col="labelCol"
                  :model="data_list"
                  :wrapper-col="wrapperCol"
                  layout="horizontal"
                  :rules="rules"
          >
            <a-row>
              <a-col :span="24">
                <a-form-item label="虚拟机镜像ID：" name='id'>
                  <a-input v-model:value="data_list.id" disabled/>
                </a-form-item>
                <a-form-item label="虚拟机镜像名称：" name='name'>
                  <a-input v-model:value="data_list.name" disabled/>
                </a-form-item>
                <a-form-item label="虚拟机镜像描述：" name='description'>
                  <a-input v-model:value="data_list.description"/>
                </a-form-item>
                <a-form-item v-if="data_list.data_volume_url" label="虚拟机镜像下载地址：" name='data_volume_url'>
                  <a-input v-if="['none'].includes(data_list.vm_image_status)"
                           v-model:value="data_list.data_volume_url"/>
                  <a-input v-else v-model:value="data_list.data_volume_url" disabled/>
                </a-form-item>
                <a-form-item label="虚拟镜像类型：" name='data_list.image_file_type'>
                  <a-select :value="data_list.image_file_type" disabled>
                    <a-select-option value="iso">ISO安装镜像</a-select-option>
                    <a-select-option value="img">IMG启动镜像</a-select-option>
                  </a-select>
                </a-form-item>
                <a-form-item label="虚拟机镜像状态：" name='vm_image_status'>
                  <a-input :value="status_map[data_list.vm_image_status]" disabled/>
                </a-form-item>
              </a-col>
            </a-row>
            <a-flex justify="center" align="center" gap="middle" class="mb-5">
              <a-button v-if="editorMode === 'create'" type="primary" @click="confirmFormCreate">创建</a-button>
              <a-button v-if="editorMode === 'update'" type="primary" @click="confirmFormUpdate">保存</a-button>
              <a-button v-if="editorMode !== 'readonly'" style="margin-left: 10px" @click="cancelForm">取消</a-button>
            </a-flex>
          </a-form>
        </div>
        <div v-if="isImageReady" style="margin-top: 20px">
          <a-row>
            <a-col :span="8">
              <a-typography-title :level="4">虚拟机镜像应用</a-typography-title>
            </a-col>
            <a-col :span="12">
            </a-col>
            <a-col :span="4">
              <a-button v-if="appDataSource.length===0" type="primary" @click="onCreateApp">制作应用模板</a-button>
            </a-col>
          </a-row>
          <a-table :columns="appColumns" :dataSource="appDataSource" style="width: 100%">
            <template #bodyCell="{ column, record}">
              <template v-if="column.dataIndex === 'action'">
                <span>
                  <a-button type="link" @click="()=>onDetailImageApp(record.user_app_id)">详情</a-button>
                  <a-divider type="vertical"/>
                  <a-button type="link" @click="()=>_onCreateDeployment(record.template_id)">部署</a-button>
                  <a-divider type="vertical"/>
                  <!--                  <a-button type="link" @click="()=>onDeleteImageApp(record.user_app_id)">删除</a-button>-->
                  <!--                  <a-divider type="vertical"/>-->
                </span>
              </template>
            </template>
          </a-table>
          <!--          <div v-for="row in data_list.template_ids" :key="row.app_id" class="col-md-4">-->
          <!--            <div>应用ID：{{ row.app_id }}， 应用名称：{{ row.name }}</div>-->
          <!--          </div>-->
          <a-modal v-model:open="openCreateAppModal" :confirm-loading="confirmCreateAppLoading"
                   title="创建应用" width="60%" @cancel="cancelCreateApp" @ok="confirmCreateApp">
            <a-form ref="formCreateAppRef" :model="createAppForm" :rules="rulesCreateApp" @submit="confirmCreateApp"
                    :label-col="labelCol" :wrapper-col="wrapperCol" layout="horizontal">
              <a-form-item label="应用名称" name="app_name">
                <a-input v-model:value="createAppForm.app_name"/>
              </a-form-item>
              <a-form-item label="空间类型" name="ns_type">
                <a-select v-model:value="createAppForm.ns_type" :disabled="createAppForm.disable_namespace_selection">
                  <a-select-option v-for="item in createAppForm.ns_types" :key="item[0]" :value="item[0]">
                    {{ item[1] }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="命名空间" name="namespace_id">
                <a-select v-model:value="createAppForm.namespace_id"
                          :disabled="createAppForm.disable_namespace_selection">
                  <template v-if="createAppForm.ns_type === 'private'">
                    <a-select-option v-for="item in createAppForm.private_namespaces" :key="item.id" :value="item.id">
                      {{ item.name }}
                    </a-select-option>
                  </template>
                  <a-select-option v-for="item in createAppForm.public_namespaces" v-else :key="item.id"
                                   :value="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="部署方式" name="deployment_method">
                <a-select v-model:value="createAppForm.deployment_method">
                  <a-select-option value="consumer_compute_power">消耗算力点先用后结</a-select-option>
                  <!-- <a-select-option value="use_resource">使用现有资源</a-select-option> -->
                </a-select>
              </a-form-item>
              <template v-if="createAppForm.deployment_method=='consumer_compute_power'">
                <a-form-item label="算力服务" name="compute_power_service">
                  <a-radio-group v-model:value="createAppForm.compute_power_service" button-style="solid">
                    <a-radio-button v-if="createAppForm.capacity_type_level.base?.length>0" value="base">初级
                    </a-radio-button>
                    <a-radio-button v-if="createAppForm.capacity_type_level.middle?.length>0" value="middle">中级
                    </a-radio-button>
                    <a-radio-button v-if="createAppForm.capacity_type_level.high?.length>0" value="high">高级
                    </a-radio-button>
                  </a-radio-group>
                </a-form-item>
                <div>
                  服务配置：
                  <ul v-if="createAppForm.compute_power_service">
                    <li v-for="item in createAppForm.capacity_type_level[createAppForm.compute_power_service]"
                        :key="item">
                      <a-flex justify="space-between" align="center">
                        <div>
                          <div>{{ item.template_inner_service_name }}:</div>
                          <a-flex :gap="15">
                            <span v-for="op in item.capacity.capacity_type_op" :key="op">
                              {{ op.hardware_id.hardware_type }}: {{ op.use_number }}
                              <template
                                  v-if="op.hardware_id.hardware_unit">{{ op.hardware_id.hardware_unit }}</template><br/>
                              <template
                                  v-if="op.name">({{ op.name }})</template>
                            </span>
                          </a-flex>
                        </div>
                        <div style="color: #a0c8ff;white-space: nowrap;">
                          {{ item.capacity.capacity_type_op_sum_point }} 算力/{{ displayUnit(item.capacity.unit_op) }}
                        </div>
                      </a-flex>
                    </li>
                  </ul>
                </div>
                <div style="border-top: 3px solid #2580fd;"></div>
                <a-flex justify="space-between" align="center" class="my-3">
                  <div style="font-size: 16px;">以上配置当前环境算力点总计：</div>
                  <div>
                    <div class="unit-selector">
                      <span v-for="unit in units" :key="unit" :class="{ active: unit === display_unit }" @click="changeUnit(unit)">
                        {{ unit }}
                      </span>
                    </div>
                    <span style="color: #FF5F5F;font-size: 16px;">{{ displayedPoint }} 算力/{{ display_unit }}</span>
                  </div>
                </a-flex>
              </template>
            </a-form>
          </a-modal>
        </div>
        <div style="margin-top: 20px">
          <a-row>
            <a-col :span="8">
              <a-typography-title :level="4">虚拟机镜像制作</a-typography-title>
            </a-col>
            <a-col :span="12">
            </a-col>
            <a-col :span="4">
              <a-button v-if="data_list.vm_image_status === 'none'" type="primary"
                        :loading="pull_from_repo_loading"
                        @click="pull_from_repo">
                拉取虚拟机镜像
              </a-button>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="24" style="margin: 10px 0">
              <div v-if="data_list.vm_image_status === 'none'">
                <a-typography-title :level="5" style="color: #6cb8e6">
                  未开始拉取镜像
                  <a-progress type="circle" :percent="downloadPercent" size="small"/>
                </a-typography-title>

              </div>
              <div v-else-if="data_list.vm_image_status === 'pulling'">
                <a-typography-title :level="5" style="color: green">
                  正在拉取虚拟机镜像{{ downloadDot }}
                  <a-progress type="circle" :percent="downloadPercent" size="small"/>
                </a-typography-title>
              </div>
              <div v-else-if="data_list.vm_image_status === 'pull_failed'">
                <a-typography-title :level="5" style="color: red">
                  拉取镜像失败
                  <a-progress type="circle" :percent="downloadPercent" size="small"/>
                </a-typography-title>
              </div>
              <div v-else>
                <a-typography-title :level="5" style="color: #0bd734">
                  拉取镜像成功
                  <a-progress type="circle" :percent="downloadPercent" size="small"/>
                </a-typography-title>
              </div>
            </a-col>
          </a-row>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script setup>
import {logDebug, logError} from "@/utils/logger";
import {computed, nextTick, onMounted, onUnmounted, reactive, ref, watch} from "vue";
import {getFailedMessage, getResponseData, jsonRPC} from "@/utils/http_utils";
import {useRouter} from 'vue-router';
import {routerBack, routerReplace} from "@/utils/router_utils";
import {sleep} from "@/utils/time_utils";
import {isEmpty} from "@/utils/common_utils";
import {message, Modal} from "ant-design-vue";

const router = useRouter();

const vm_image_id = router.currentRoute.value.params.vm_image_id
logDebug(`vm_image_id[${vm_image_id}]`)

const editorMode = ref('update')

const labelCol = {
  style: {
    width: '150px',
  },
};
const wrapperCol = {
  span: 20,
};
const formRef = ref();

const data_list = reactive({
  id: '',
  name: '',
  description: '',
  data_volume_url: '',
  image_file_type: 'iso',
  vm_image_status: 'none',
  download_progress: '0%',
});

const validateVmDownloadURL = (rule, value) => {
  value = `${value}`.trim()
  if (!value) {
    return Promise.reject(`请输入虚拟机镜像下载地址`);
  }

  const pattern = /^(http|https):\/\//;
  if (!pattern.test(value)) {
    return Promise.reject(`虚拟机镜像下载地址必须是http或https协议`)
  }
  if (!`${value}`.toLowerCase().endsWith(".img") && !`${value}`.toLowerCase().endsWith(".iso")) {
    return Promise.reject(`仅支持img和iso格式的虚拟机镜像，请指定“.img”或“.iso”格式的虚拟机镜像下载地址。`)
  }
  return Promise.resolve()
};

const rules = {
  description: [
    {
      required: true,
      message: '请输入虚拟机镜像描述',
      trigger: 'change',
    },
  ],
  data_volume_url: [
    {
      required: true,
      trigger: 'change',
      validator: validateVmDownloadURL,
    },
  ],
  image_file_type: [
    {
      required: true,
      trigger: 'change',
    },
  ],
}

const exitRefresh = ref(false)

const downloadDot = ref('.')

const startDownloadDot = async function () {
  for (let i = 0; i < 10000; i++) {
    if (exitRefresh.value) {
      logDebug("exit refreshStatus")
      return
    }

    await nextTick()
    await sleep(1000)

    let length = downloadDot.value.length + 1
    if (length > 12) {
      length = 1
    }
    downloadDot.value = ".".repeat(length)
  }
}

const isImageReady = computed(() => {
  return ['pull_succeed', 'image_created', 'push_succeed'].includes(data_list.vm_image_status)
  // return ['image_created'].includes(data_list.image_status)
})

watch(() => data_list.data_volume_url, function (newVal) {
  logDebug(`watch data_list.data_volume_url, newVal: ${newVal}`);
  if (`${newVal}`.toLowerCase().includes(".img") || `${newVal}`.toLowerCase().includes(".qcow2")) {
    data_list.image_file_type = 'img'
  }
  if (`${newVal}`.toLowerCase().includes(".iso")) {
    data_list.image_file_type = 'iso'
  }
})

const downloadPercent = computed(() => {
  if (isImageReady.value) {
    return 100
  }
  try {
    return parseFloat(`${data_list.download_progress}`.trim().replaceAll('%', ''))
  } catch (e) {
    return 0
  }
})

const appColumns = [
  {
    title: '用户应用模板ID',
    dataIndex: 'user_app_id',
    key: 'user_app_id',
  },
  {
    title: '用户应用名称',
    dataIndex: 'app_name',
    key: 'app_name',
  },
  {
    title: '动作',
    dataIndex: 'action',
    key: 'action',
  },
]

const status_map = {
  "none": '无',
  "building": '构建中',
  "build_succeed": '构建成功',
  "build_failed": '构建失败',
  "pulling": '拉取中',
  "pull_succeed": '拉取成功',
  "pull_failed": '拉取失败',
  "pushing": '推送中',
  "push_succeed": '推送成功',
  "push_failed": '推送失败',
  "image_created": '已创建镜像',
}

const appDataSource = computed(() => {
  if (isEmpty(data_list.template_ids)) {
    return []
  }

  const template_ids = []
  for (const template of data_list.template_ids) {
    template_ids.push({
      app_id: template.app_id,
      app_name: template.name,
      user_app_id: template.user_app_id,
      template_id: template.id,
    })
  }
  return template_ids
})

const confirmFormCreate = () => {
  formRef.value.validate()
      .then(function () {
        logDebug('confirmFormUpdate')
      })
      .catch(error => {
        logError('error', error);
        return
      });
};

const confirmFormUpdate = () => {
  formRef.value.validate()
      .then(function () {
        logDebug('confirmFormUpdate')
        jsonRPC({
          url: `/api/make/vm/image/update`,
          params: {
            'id': vm_image_id,
            'name': data_list.name,
            'description': data_list.description,
            'data_volume_url': data_list.data_volume_url,
            'image_file_type': data_list.image_file_type,
          },
          success(res) {
            logDebug(`更新虚拟机镜像成功`, res.data);
          },
          fail(error) {
            logError(`更新虚拟机镜像失败`, error);
          },
        }).then(function () {
          window.location.reload()
        })
      })
      .catch(error => {
        logError('error', error);
        return
      });
};

const cancelForm = () => {
  routerBack(router)
};

const onCreateApp = () => {
  router.push(`/console/image/vm/${vm_image_id}/app/create`);
};

const refresh_status_interval_ms = 5000
const refreshStatus = async function (targetImageStatusList = [
  'pull_succeed', 'pull_failed', 'push_succeed', 'push_failed', 'image_created']) {
  for (let i = 0; i < 10000; i++) {
    if (exitRefresh.value) {
      logDebug("exit refreshStatus")
      return
    }
    await jsonRPC({
      url: `/api/make/vm/image/${vm_image_id}/status`,
      params: {},
      success(res) {
        const data = getResponseData(res)
        logDebug(`获取虚拟机镜像状态成功`, data);
        Object.assign(data_list, data);
        return true
      },
      fail(error) {
        logError(`获取虚拟机镜像状态失败`, error);
        return false
      },
    })
    if (targetImageStatusList.includes(data_list.vm_image_status)) {
      logDebug(`refreshStatus done, vm_image_status[${data_list.vm_image_status}]`)
      return
    }
    await nextTick()
    await sleep(refresh_status_interval_ms)
  }
}

const pull_image = async function () {
  jsonRPC({
    url: `/api/make/vm/image/${vm_image_id}/pull`,
    params: {},
    success(res) {
      const data = getResponseData(res)
      logDebug(`触发拉取虚拟机镜像成功`, data);
      Object.assign(data_list, data);
      refreshStatus(['pull_succeed', 'pull_failed', 'image_created'])
      return true
    },
    fail(error) {
      logError(`触发拉取虚拟机镜像失败`, error);
      return false
    },
  })
}

const pull_from_repo_loading = ref(false)

const pull_from_repo = function () {
  logDebug('pull_from_repo')
  pull_from_repo_loading.value = true
  pull_image()
  message.info(`已触发拉取虚拟机镜像，拉取虚拟机镜像需要数分钟不等，请耐心等待下载完成！`, 3)
  setTimeout(function () {
    pull_from_repo_loading.value = false
  }, refresh_status_interval_ms * 2)
}

const onDetailImageApp = function (user_app_id) {
  logDebug(`onDetailImageApp app_id[${user_app_id}]`)
  router.push(`/console/image/vm/${vm_image_id}/app/${user_app_id}`)
}

const onDeleteImageApp = function (user_app_id) {
  if (confirm("确定要删除当前应用模板吗？")) {
    jsonRPC({
      url: "/vue/console/app/template/delete",
      params: {
        app_id: user_app_id,
      },
      success(res) {
        logDebug(`删除应用模板成功`, res.data.result);
        message.info(`删除应用模板成功`)
        fetchData();
      },
      fail(error) {
        logError(`删除应用模板失败, `, error);
        message.info(`删除应用模板失败`)
      },
    });
  }
}
logDebug(`onDeleteImageApp[${onDeleteImageApp}]`)

const formCreateAppRef = ref();
const rulesCreateApp = {
  app_name: [
    {
      required: true,
      message: '请输入应用名称',
    },
  ],
  ns_type: [
    {
      required: true,
      message: '请选择空间类型',
      trigger: 'change',
    },
  ],
  namespace_id: [
    {
      required: true,
      message: '请选择命名空间',
      trigger: 'change',
    },
  ],
  deployment_method: [
    {
      required: true,
      message: '部署方式',
      trigger: 'change',
    },
  ],
  compute_power_service: [
    {
      required: true,
      message: '算力服务',
    },
  ],
}
const openCreateAppModal = ref(false);
const confirmCreateAppLoading = ref(false);
const createAppForm = reactive({
  app_name: "",
  ns_type: "",
  namespace_id: "",
  app_id: "",
  template_id: "",
  disable_namespace_selection: false,
  capacity_type_level: {},
  deployment_method: 'consumer_compute_power',
  compute_power_service: 'base',
});

watch(() => createAppForm.ns_type, function (newValue, oldValue) {
  logDebug(`createAppForm.ns_type发生了变化: ${oldValue} -> ${newValue}`);
  if (oldValue !== "") {
    createAppForm.namespace_id = ""
  }
})

function fetchCreateDeploymentData() {
  jsonRPC({
    url: "/vue/console/experiment/deployment/create/setting",
    params: {},
    success(res) {
      logDebug(`查询成功`, res.data.result.data);
      Object.assign(createAppForm, res.data.result.data);
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  });
}

watch(() => createAppForm.ns_type, function () {
  logDebug(`createAppForm.ns_type[${createAppForm.ns_type}]`)
  if (createAppForm.ns_type === 'private') {
    if (createAppForm['private_namespaces'] != null && typeof createAppForm['private_namespaces'] !== "undefined" &&
        createAppForm['private_namespaces'].length >= 1) {
      createAppForm.namespace_id = createAppForm['private_namespaces'][0].id
    } else {
      createAppForm.namespace_id = false
    }
  } else {
    if (createAppForm['public_namespaces'] != null && typeof createAppForm['public_namespaces'] !== "undefined" &&
        createAppForm['public_namespaces'].length >= 1) {
      createAppForm.namespace_id = createAppForm['public_namespaces'][0].id
    } else {
      createAppForm.namespace_id = false
    }
  }
})

const _onCreateDeployment = (template_id) => {
  createAppForm.template_id = template_id
  createAppForm.disable_namespace_selection = false
  if (createAppForm['ns_types'] != null && typeof createAppForm['ns_types'] !== "undefined" &&
      createAppForm['ns_types'].length >= 1) {
    createAppForm.ns_type = createAppForm['ns_types'][0][0]
  }
  if (createAppForm.ns_type === 'private') {
    if (createAppForm['private_namespaces'] != null && typeof createAppForm['private_namespaces'] !== "undefined" &&
        createAppForm['private_namespaces'].length >= 1) {
      createAppForm.namespace_id = createAppForm['private_namespaces'][0].id
    }
  } else {
    if (createAppForm['public_namespaces'] != null && typeof createAppForm['public_namespaces'] !== "undefined" &&
        createAppForm['public_namespaces'].length >= 1) {
      createAppForm.namespace_id = createAppForm['public_namespaces'][0].id
    }
  }
  openCreateAppModal.value = true;
  jsonRPC({
    url: "/api/get/template/capacity_type_level",
    params: {
      app_id: createAppForm.template_id,
    },
    success(res) {
      const data = getResponseData(res)
      logDebug(`创建实验环境成功`, data);
      if (data.capacity_type_level) {
        createAppForm.capacity_type_level = JSON.parse(data.capacity_type_level)
      }
    },
    fail(error) {
      logError(`创建实验环境失败, `, error);
    },
  })
};
const sum_point = computed(() => {
  let sum = 0;
  const ser = createAppForm.compute_power_service;
  for (const x of createAppForm.capacity_type_level[ser] || []) {
    sum += x.capacity.capacity_type_op_sum_point;
  }
  return sum;
});
const unitMap = {
  hours: '小时',
  day: '天',
  month: '月'
};
const displayUnit = (e) => {
  return unitMap[e] || '小时';
}
const sum_point_unit = computed(() => {
  let sum = '小时';
  const ser = createAppForm.compute_power_service;
  for (const x of createAppForm.capacity_type_level[ser] || []) {
    if (unitMap[x.capacity.unit_op]) {
      sum = unitMap[x.capacity.unit_op];
      break;
    }
  }
  return sum;
});
// 单位列表及换算关系
const units = ['时', '日', '月', '年'];
// 设置 display_unit，判断 sum_point_unit 是否在 units 里
const display_unit = ref(units.includes(sum_point_unit.value) ? sum_point_unit.value : '时');
// 监听 sum_point_unit 变化，自动更新 display_unit
watch(sum_point_unit, (newVal) => {
  display_unit.value = units.includes(newVal) ? newVal : '时';
});
// 计算数值
const conversionRates = { '时': 1, '日': 24, '月': 24 * 30, '年': 24 * 365 };
// **改成乘法运算**
const displayedPoint = computed(() => {
  return parseFloat((sum_point.value * conversionRates[display_unit.value]).toFixed(8));
});
// 切换单位
const changeUnit = (unit) => {
  display_unit.value = unit;
};
const confirmCreateApp = () => {
  formCreateAppRef.value.validate()
      .then(() => {
        confirmCreateAppLoading.value = true
        jsonRPC({
          url: "/vue/console/experiment/deployment/create",
          params: {
            name: createAppForm.app_name,
            template_id: createAppForm.template_id,
            namespace_id: createAppForm.namespace_id,
            is_compute_point: sum_point.value > 0,
            capacity_type_level: createAppForm.compute_power_service,
            need_deduct_compute_point: sum_point.value,
            capacity_unit_option: createAppForm.capacity_type_level[createAppForm.compute_power_service]?.[0]?.capacity?.unit_op,
          },
          success(res) {
            const data = getResponseData(res)
            logDebug(`创建实验环境成功`, data);
            message.success(`创建实验环境成功`)
            setTimeout(()=>{
              jsonRPC({
                url: "/vue/template/used_nums",
                params: {
                  template_id: createAppForm.template_id,
                },
                success() {
                  logDebug(`模板使用次数增加成功`);
                },
                fail(error) {
                  logError(`模板使用次数增加失败, `, error);
                },
              });
            },10*1000)
          },
          fail(error) {
            try {
              try {
                let errMsg = JSON.parse(error);
                let user = errMsg.user || '';
                let message = errMsg.message || '资源不足';
                let details = errMsg.details || '';
                Modal.error({
                  title: "创建实验环境失败",
                  content: (
                      <div>
                        <div>{user}</div>
                        <div style={{textIndent: '2em'}}>{message}</div>
                        <div>{details}</div>
                      </div>
                  ),
                  width: '60%', // 设置宽度为 60%
                  closable: true, // 右上角关闭按钮
                  onCancel: () => {
                    Modal.destroyAll(); // 点击关闭按钮时，关闭模态框
                  },
                  footer: () => (
                      <div class="d-flex justify-content-center my-3">
                        <button type="button" class="btn btn-primary" onClick={async () => {
                          router.push('/cost/topup')
                          Modal.destroyAll(); // 关闭所有弹窗
                        }}>补充资源
                        </button>
                      </div>
                  ),
                });
              } catch (err) {
                logError("查询失败: 无法解析错误信息", err);
                Modal.error({
                  title: "创建实验环境失败",
                  content: (
                      <div style={{textIndent: '2em'}}>{JSON.stringify(getFailedMessage(error))}</div>
                  ),
                  width: '60%', // 设置宽度为 60%
                  closable: true, // 右上角关闭按钮
                  onCancel: () => {
                    Modal.destroyAll(); // 点击关闭按钮时，关闭模态框
                  },
                  footer: () => (
                      <div class="d-flex justify-content-center my-3">
                        <button type="button" class="btn btn-primary" onClick={() => {
                          Modal.destroyAll(); // 关闭所有弹窗
                        }}>关闭
                        </button>
                      </div>
                  ),
                });
              }
            } catch (er) {
              logError("部署创建过程中发生未捕获的错误：", er);
              message.error('部署创建失败，请稍后重试。')
            }
          },
        }).then(function () {
          openCreateAppModal.value = false;
          confirmCreateAppLoading.value = false;
          routerReplace(router, '/console/app')
        });
      })
      .catch(error => {
        logDebug('error', error);
      });
};

const cancelCreateApp = () => {
  createAppForm.app_name = ""
  createAppForm.ns_type = ""
  createAppForm.namespace_id = ""
  createAppForm.disable_namespace_selection = false
}

const onDeleteImage = () => {
  Modal.confirm({
    title: '删除容器镜像',
    content: '您正在为当前删除容器镜像，请再次确认！',
    async onOk() {
      try {
        // 使用Promise控制Modal的关闭时机
        return new Promise((resolve, reject) => {
          jsonRPC({
            url: `/api/make/vm/image/delete`,
            params: {
              id: vm_image_id
            },
            success(res) {
              const data = getResponseData(res);
              logDebug('删除虚拟机镜像成功，', data)
              message.info('删除虚拟机镜像成功', 3)
              resolve();  // 请求成功后，关闭 Modal
              setTimeout(function () {
                router.push(`/console/image/vm`)
              }, 1000)
            },
            fail(error) {
              logError(`删除虚拟机镜像失败, `, error);
              message.error('删除虚拟机镜像失败，请重试！', 3)
              reject();  // 请求失败时，不关闭 Modal，并且显示错误消息
            },
          });
        });
      } catch (error) {
        logError('Oops errors!', error);
        message.error('操作失败，请稍后再试');
      }
    },
  });
}

onMounted(() => {
  fetchData()
  fetchCreateDeploymentData()
  startDownloadDot()
});

onUnmounted(() => {
  exitRefresh.value = true
})

async function fetchData() {
  await jsonRPC({
    url: `/api/make/vm/image/${vm_image_id}/info`,
    params: {},
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取虚拟机镜像信息成功`, data);
      Object.assign(data_list, data);
      if (data.capacity_type_level) {
        createAppForm.capacity_type_level = JSON.parse(data.capacity_type_level)
      }
      if (!['none'].includes(data_list.vm_image_status)) {
        refreshStatus(['pull_succeed', 'pull_failed', 'image_created'])
      }
    },
    fail(error) {
      logError(`获取虚拟机镜像信息失败, `, error);
    },
  });
}
</script>

<style lang="scss" scoped>

</style>