<template>
  <div id="filesystem">
    <div id="env_fs">
      <div v-if='!state.folder_fs' class="h-100">
        <div class="operate py-1 px-2">
          <img src="/hw_experiment/static/src/img/return.png" title='上级目录' @click="click_list_fs"/>
          <img src="/hw_experiment/static/src/img/download.png" title="下载" @click="download"/>
          <form id="upload_form" enctype='multipart/form-data'>
            <input id="upload_file" multiple='multiple' name="fileupload" style="display:none" type="file"
                   @change='upload'/></form>
          <!-- <input id="fileupload" type="file" name="files[]" data-url="/upload" multiple="multiple" style="display:none"></input> -->
          <!-- <img @click="upload_btn" src="/hw_experiment/static/src/img/upload.png" title="上传"/>-->
          <img id="uploader-browse" src="/hw_experiment/static/src/img/upload.png" title="上传"/>
          <img src="/hw_experiment/static/src/img/refresh.png" title="刷新" @click="refresh"/>
          <img src="/hw_experiment/static/src/img/new-folder.png" title="新建目录" @click="mkdir"/>
          <img src="/hw_experiment/static/src/img/new-file.png" title="新建文件" @click="touch"/>
          <img src="/hw_experiment/static/src/img/hide.png" title="隐藏文件" @click="hide_fd"/>
          <!-- <img @click="folder_fs" src="/hw_experiment/static/src/img/Collapse-the-sidebar.png" title="折叠"/> -->
          <img id="copypath" data-clipboard-target=".highlight" src="/hw_experiment/static/src/img/word.png"
               title="拷贝所选文件/文件夹路径" @click="copy_path"/>
          <img src="/hw_experiment/static/src/img/closure.png" title="删除所选文件或文件夹" @click="deleteFunction"/>
        </div>
        <!-- <div class="px-2">
            <input class="w-100" type="text" value="18" />  暂时屏蔽搜索功能
        </div> -->
        <div class="position-relative" style="overflow: auto;height: calc(100% - 80px);">
          <div class="px-2 position-absolute">
            <template v-if="state.fs.fd">
              <table class="m-0" style="white-space: nowrap;cursor:default">
                <tr>
                  <td class="px-1">类型</td>
                  <td class="px-1">名称</td>
                  <td class="px-1">大小</td>
                  <td class="px-1">最后修改</td>
                  <td class="px-1">拥有者</td>
                  <td class="px-1">拥有组</td>
                  <td class="px-1">权限</td>
                </tr>
                <tr :class="`${state.selected_fd.indexOf('..') !=-1  ? 'highlight' : ''}`" name=".." title="上级目录"
                    @click="select_fd">
                  <td>
                    <img name=".." src='/hw_experiment/static/src/img/dir.png'
                         @dblclick='click_list_fs'/>
                  </td>
                  <td>
                    <div name=".." @dblclick='click_list_fs'>
                      ..
                    </div>
                  </td>
                  <td>
                    {{ state.fs.fd['..'].size }}
                  </td>
                  <td>
                    {{ state.fs.fd['..'].date }} {{ state.fs.fd['..'].time }}
                  </td>
                  <td>
                    {{ state.fs.fd['..'].owner }}
                  </td>
                  <td>
                    {{ state.fs.fd['..'].group }}
                  </td>
                  <td>
                    {{ state.fs.fd['..'].permission }}
                  </td>
                </tr>
                <template v-for="i in Object.values(state.fs.fd)">
                  <tr v-if="i.name !='..' && (i.name.substr(0, 1)!='.' || state.hide_fd == false) "
                      :key="i.name" :class="state.selected_fd.indexOf(i.name) !=-1  ? 'highlight' : ''"
                      :name="i.name"
                      :title="i.name"
                      @click="select_fd">
                    <td>
                      <template v-if="i.type=='d'">
                        <img :name="i.name" src='/hw_experiment/static/src/img/dir.png'
                             @dblclick='click_list_fs'/>
                      </template>
                      <template v-if="i.type=='f'">
                        <img src='/hw_experiment/static/src/img/file.png'/>
                      </template>
                      <template v-if="i.type=='l'">
                        <img src='/hw_experiment/static/src/img/link.png'/>
                      </template>
                    </td>
                    <td>
                      <template v-if="i.type=='d'">
                        <div :name="i.name" @dblclick='click_list_fs'>
                          {{ i.name }}
                        </div>
                      </template>
                      <template v-else>
                        {{ i.name }}
                      </template>
                    </td>
                    <td>
                      {{ i.size }}
                    </td>
                    <td>
                      {{ i.date }}
                      {{ i.time }}
                    </td>
                    <td>
                      {{ i.owner }}
                    </td>
                    <td>
                      {{ i.group }}
                    </td>
                    <td>
                      {{ i.permission }}
                    </td>
                  </tr>
                </template>
              </table>
            </template>
          </div>
        </div>
        <div class="progress w-100" style="display:none;">
          <div class="progress-bar progress-bar-striped progress-bar-animated" style="text-align: right;"></div>
        </div>
        <div class="px-2">
          <p class="m-0" style="font-size: 12px;">当前目录：{{ state.pwd }}</p>
          <p class="m-0" style="font-size: 12px;">占用磁盘：{{ state.fs.total }}</p>
          <p class="m-0" style="font-size: 12px;">网络地址: {{ ip }}</p>
        </div>
      </div>
      <div v-if="state.folder_fs">
        <button @click="folder_fs">打开折叠</button>
      </div>
      <span id="message_success" class="message_sucess"></span>
      <span id="message_error" class="message_error"></span>
      <span id="message_warning" class="message_warning"></span>
    </div>
  </div>
</template>

<script setup>
import {onMounted, reactive, ref} from 'vue';
import {logDebug, logError} from "@/utils/logger";
import ClipboardJS from 'clipboard';
import $ from 'jquery'
import Uploader from 'simple-uploader.js'
import {isFalse} from "@/utils/common_utils";
import {message} from 'ant-design-vue'

const props = defineProps({
  primal: {type: String},
  data: {type: Object},
  styleSetting: {type: Object},
})
const contentHeight = ref(props.styleSetting.contentStyle.height)

const primal = props.primal
const data = props.data

const env_name = data['env_name'];//获取属性的值
const env_namespace = data['namespace'];
const ip = data['ip'];
const abc_svc_url = data['abc_svc_url'];
const zone_domain = data['zone_domain']
const zone_domain_ssl = data['zone_domain_ssl']
const zone_domain_port = data['zone_domain_port']
const protocol = `${zone_domain_ssl}`.toLowerCase().trim() === 'false' ? "http" : "https"
const upload_url_base = `${protocol}://${zone_domain}:${zone_domain_port}`

const state = reactive({
  fs: '', //文件系统列表
  pwd: '/', //当前目录
  selected_fd: [], //点击单选，或者多选的情况下,文件或者文件名文件名 fd=file or dir
  hide_fd: false, //显示隐藏文件或者文件夹 fd=file or dir
  folder_fs: false, //折叠文件系统div
  // size:file_size,//文件大小
})

const holder = {
  globalFileUploader: null,
}

//点击文件夹事件，传入相应的文件夹
const click_list_fs = function (e) {
  //从tr标签获取属性
  let dir_name = e.currentTarget.getAttribute('name');
  //如果不是从表里点击，是点击了上一级的按钮
  if (!dir_name) dir_name = '..';
  if (dir_name == '..') {
    if (state.pwd == '/') {
      logDebug('已经是根目录');
    } else {
      const dir_list = state.pwd.split('/'); //手工分解文件夹路径
      dir_list.pop(); //连续弹两次
      dir_list.pop();
      if (dir_list.length != 1) dir_name = dir_list.join('/') + '/'; //非根目录，需要加'/'后缀
      else dir_name = state.pwd = '/'; //到了根目录
      list_fs(dir_name);
    }
  } else {
    list_fs(state.pwd + dir_name + "/");
  }
}

//要求传入绝对路径
const list_fs = function (dir) {
  var fs = state.fs;  //保存原来的目录结构
  state.fs = '' //刷新文件系统
  if (!dir) dir = state.pwd; //默认当前目录
  var socket = new WebSocket(abc_svc_url);
  socket.onopen = function () {
    socket.send(JSON.stringify({
      controller: 'env_manager',
      action: 'list_fs',
      env_name: env_name,
      namespace: env_namespace,
      dir: dir
    }));
    socket.onerror = function (event) {
      logDebug('error:' + event);
    };
    socket.onmessage = function (event) {
      var new_fs = JSON.parse(event.data);
      if (new_fs.code === 500) {
        message.error(new_fs.error)
        state.fs = fs;  //返回原来目录结构
      } else {
        state.fs = new_fs;
        if (state.pwd != dir) state.pwd = dir; //进入某个目录，切换当前路径
      }
    };
    socket.onclose = function (event) {
      logDebug('已经关闭', event);
    };
  };
}

//新建目录
const mkdir = function () {
  var dir = prompt('请输入文件夹名称');
  if (!dir || dir == '') return //如果不填写，默认返回
  var fd = dir; //创建成功后高亮显示
  dir = state.pwd + dir;
  var socket = new WebSocket(abc_svc_url);
  socket.onopen = function () {
    socket.send(JSON.stringify({
      controller: 'env_manager',
      action: 'mkdir',
      env_name: env_name,
      namespace: env_namespace,
      dir: dir
    }));
    socket.onerror = function (event) {
      logDebug('error:' + event);
    };
    socket.onmessage = function (event) {
      let result = JSON.parse(event.data);
      if (result.code === 500) {
        message.error(result.error)
      }
      state.selected_fd = [fd];
      list_fs();
    };
    socket.onclose = function (event) {
      logDebug('已经关闭', event);
    };
  };
  //当前目录下重新刷新
}

//刷新
const refresh = function () {
  list_fs();
}

//删除所选文件或者文件夹
const deleteFunction = function () {
  if (state.selected_fd.length == 0)
    return message.warn('请先选择要删除的文件或者文件夹，按住CTRL键可以多选!')
  var msg = "您真的确定要删除所选吗？删除以后无法恢复！\n\n请确认！";
  if (confirm(msg) == false) return;
  var fds = [] //要删除的文件和文件夹（多选）
  for (let i of state.selected_fd) {
    fds.push(state.pwd + i); //组装全路径
  }
  var socket = new WebSocket(abc_svc_url);
  socket.onopen = function () {
    socket.send(JSON.stringify({
      controller: 'env_manager',
      action: 'delete',
      env_name: env_name,
      namespace: env_namespace,
      fds: fds,
    }));
    socket.onerror = function (event) {
      logDebug('error:' + event);
    };
    socket.onmessage = function (event) {
      let result = JSON.parse(event.data);
      if (result.code === 500) {
        message.error(result.error)
      }
      state.selected_fd = [];
    };
    socket.onclose = function (event) {
      logDebug('已经关闭', event);
    };
  };
  list_fs();
}

//新建文件
const touch = function () {
  var file_name = prompt('请输入文件名称');
  var select_fd = file_name; //创建成功，则高亮显示
  if (!file_name || file_name == '') return //如果不填写或取消，默认返回
  file_name = state.pwd + file_name; //全路径
  var socket = new WebSocket(abc_svc_url);
  socket.onopen = function () {
    socket.send(JSON.stringify({
      controller: 'env_manager',
      action: 'touch',
      env_name: env_name,
      namespace: env_namespace,
      file: file_name,
    }));
    socket.onerror = function (event) {
      logDebug('error:' + event);
    };
    socket.onmessage = function (event) {
      let result = JSON.parse(event.data);
      if (result.code === 500) {
        message.error(result.error)
      } else {
        state.selected_fd = [select_fd]
        list_fs(state.pwd); //刷新
      }
    };
    socket.onclose = function (event) {
      logDebug('已经关闭', event);
    };
  };
}

//点击选择,支持CTRL多选 e =event 事件
const select_fd = function (e) {
  //对应的Element的属性name
  var file_name = e.currentTarget.getAttribute('name');
  if (e.ctrlKey) { //  ctrl 多选
    const selected_fd_array = state.selected_fd
    if (state.selected_fd.indexOf(file_name) != -1) {
      //多选里，删除某一个条目
      state.selected_fd = selected_fd_array.splice(selected_fd_array.indexOf(file_name), 1)
    } else {
      selected_fd_array.push(file_name)
      state.selected_fd = selected_fd_array
    }
  } else {
    if (state.selected_fd.length == 1 && state.selected_fd.indexOf(file_name) != -1) {
      state.selected_fd = []; //再次点击同一个文件，取消高亮
    } else {
      state.selected_fd = [file_name];
    }
  }
}

//拷贝
const copy_path = function () {
  if (state.selected_fd.length == 0) {
    message.warn('请先用鼠标左键单击选择一个文件或者文件夹!');
    return;
  }
  if (state.selected_fd.length != 1) {
    message.warn('拷贝路径只能选择一个文件或者文件夹!');
    return;
  }
  var highlight = document.getElementsByClassName('highlight')
  if (highlight.length == 0) {
    return
  }
  //采用了ClipboardJS，增加各种浏览器（移动端）的通用性
  new ClipboardJS('#copypath', {
    text: function () {
      var fd = state.selected_fd[0];
      $('#message_success').html("全路径已经拷贝到剪切板!").show().delay(2000).fadeOut();
      return state.pwd + fd;
    },
  });
}

//显示隐藏文件夹
const hide_fd = function () {
  state.hide_fd = state.hide_fd === true ? false : true;
}

//折叠文件系统
const folder_fs = function () { //折叠文件系统div
  state.folder_fs = state.folder_fs === true ? false : true;
}


//使用simple_uploader的点击上传按钮
const init_file_uploader = function () {
  // step 0: 获取环境参数
  let podName = data['env_name']
  let namespace = data['namespace']
  let containerName = podName.split('-')[0]
  let username = "david"

  // podName = "ubuntu-79b498bfdc-9w46g"
  // namespace = 'ybmkpuoitshcyjrjnomdvgxv'
  // containerName = podName.split('-')[0]

  // step 1: 创建uploader对象
  const uploadUrl = `${upload_url_base}/file/upload`
  logDebug(`uploadUrl[${uploadUrl}]`)
  holder.globalFileUploader = new Uploader({
    target: uploadUrl, //上传URL
    chunkSize: 1024 * 1024,
    testChunks: true,
    allowDuplicateUploads: true, // 允许再次上传已经上传过的文件

    //url_prefix="props.url_prefix" namespace="state.namespace" podName="state.podName" container="state.container"
    processParams: function (params) {
      let destPath = state.pwd
      logDebug(`destPath = ${destPath}`)
      params.podName = podName
      params.namespace = namespace
      params.containerName = containerName
      params.destPath = destPath //默认向Pod的哪个地址拷贝，可以根据文件栏进行调整，传进来
      params.username = username //当前昵称  也可以根据props传递进来
      return params
    },
    checkChunkUploadedByResponse: function (chunk, messages) {
      let objMessage = {}
      try {
        objMessage = JSON.parse(messages)
      } catch (e) {
        logError(e)
      }
      return (objMessage.uploaded_chunks || []).indexOf(chunk.offset + 1) >= 0
    }
  });

  if (!holder.globalFileUploader.support) {
    logDebug("unsupported")
    // TODO: 如果不支持，就退回到原来的表单上传方式。
  }

  // step 2: 定制uploader
  const uploader_browse = document.getElementById('uploader-browse')
  // const uploader_browse_folder = document.getElementById('uploader_browse_folder')
  holder.globalFileUploader.assignBrowse(uploader_browse);
  // globalFileUploader.assignBrowse(uploader_browse_folder, true);

  holder.globalFileUploader.on('filesAdded', function (files, fileList) {
    fileList.forEach(function (file) {
      logDebug(`uploader filesAdded, file[${file.name}], size[${file.getFormatSize()}]`)
    });
  });


  holder.globalFileUploader.on('filesSubmitted', function () {
    logDebug("uploader filesSubmitted")
    holder.globalFileUploader.upload()
  });

  holder.globalFileUploader.on('complete', function () {
    // complete事件 是指所有文件上传完成
    logDebug("uploader complete")
    refresh()
  });

  holder.globalFileUploader.on('fileComplete', function (rooFile) {
    // fileComplete事件 是指单个文件上传完成
    logDebug('uploader fileComplete, ', rooFile)
  });

  holder.globalFileUploader.on('fileError', function (rootFile, file, messages) {
    logDebug('file could not be uploaded: ' + messages)
    message.error('文件上传失败！')
  });

  holder.globalFileUploader.on('fileProgress', function () {
    $('.progress').show();
    const progressRate = Math.floor(holder.globalFileUploader.progress() * 100);//完成度
    //通过设置进度条的宽度达到效果
    $('.progress > div').text(progressRate + '%');
    $('.progress > div').css('width', progressRate + '%');
    if (progressRate === 100) {
      setTimeout(function () {
        $('.progress').hide();
        $('.progress > div').css('width', "");
      }, 500)
    }

  });

  holder.globalFileUploader.on('uploadStart', function () {
    logDebug("uploadStart:")
    logDebug.apply(console, arguments);
  });
  holder.globalFileUploader.on('catchAll', function () {
    logDebug("catchAll:")
    logDebug.apply(console, arguments);
  });
}


//上传选定的文件
const upload = function () {
  var fileList = $('#upload_file')[0].files
  if (fileList.length == 0) {    //客户没有选择文件，取消上传
    message.error("未选择文件！取消上传")
    return
  }
  var formData = new FormData();
  formData.append("env_name", env_name);
  formData.append('env_namespace', env_namespace);
  formData.append('pwd', state.pwd)
  var files_info = {}
  var upload_files = []
  for (var i = 0; i < fileList.length; i++) {
    //发送文件名
    var file = fileList[i];
    formData.append('files-' + i, file, file.name);
    files_info['files-' + i] = {
      file_size: file.size,
      file_path: state.pwd + file.name
    };
    upload_files.push(file.name);
  }
  formData.append('files_info', JSON.stringify(files_info));
  $.ajax({
    url: '/upload',
    type: 'POST',
    data: formData,
    // 告诉jQuery不要去处理发送的数据
    processData: false,
    // 告诉jQuery不要去设置Content-Type请求头
    contentType: false,
    beforeSend: function () {
      // logDebug("正在上传，请稍候");
    },
    xhr: function () {
      var xhr = new XMLHttpRequest();
      //使用XMLHttpRequest.upload监听上传过程，注册progress事件，打印回调函数中的event事件
      xhr.upload.addEventListener('progress', function (e) {
        if (e.lengthComputable) {
          $('.progress').show();
          var progressRate = Math.round((e.loaded / e.total) * 100);//完成度
          //通过设置进度条的宽度达到效果
          $('.progress > div').text(progressRate + '%');
          $('.progress > div').css('width', progressRate + '%');
          if (progressRate == 100) {
            setTimeout(function () {
              $('.progress').hide();
              $('.progress > div').css('width', "");
            }, 500)
          }
        }
      })
      return xhr;
    },
    success: function (responseStr) {
      responseStr = JSON.parse(responseStr);
      if (responseStr.code === 200) {
        list_fs(); //刷新列表
        state.selected_fd = upload_files; //上传成功，所有文件高亮显示
        $('#upload_file')[0].values = ""; //清空上传input文件名，如果不清空，下次点击不起作用，因为是onchange事件。
        $('#message_success').html("上传成功!").show().delay(2000).fadeOut();
      } else {
        message.error(responseStr.error)
      }
    },
    error: function (responseStr) {
      logDebug("error:" + responseStr);
    }
  });
}

//Ajax 无法发起下载请求，只能发送数据，这个Ajax机制决定的，组装Form表单，发起请求
const _render_summit_download_form = function (args) {
  var form = $("<form>");
  form.attr("style", "display:none");
  form.attr("target", "");
  form.attr("method", "post");
  form.attr("action", "/download");
  for (let key in args) {
    var input = $("<input>");
    input.attr("type", "hidden");
    input.attr("name", key);
    input.attr("value", args[key]);
    form.append(input);
  }
  $("body").append(form);
  form.submit();
  form.remove();
}

//下载选定的文件
const download = function () {
  if (state.selected_fd.length == 0) {
    message.info('请先选择文件或者文件夹，按住CTRL键可以多选!')
    return
  }
  _render_summit_download_form({
    env_name: env_name,
    env_namespace: env_namespace,
    env_dir: state.pwd,
    files: JSON.stringify(state.selected_fd)
  })
}

const initialize = function () {
  list_fs(state.pwd); //默认pwd当前目录
  // 初始化文件上传uploader
  init_file_uploader()
}

onMounted(() => {
  // 在onMounted中获取数据
  logDebug(`TerminalFileSystem onMounted.`)
})

const isFirstSelected = ref(true)
const onSelected = function () {
  logDebug(`TerminalFileSystem onSelected, data[${JSON.stringify(data)}]`)

  if (isFalse(isFirstSelected.value)) {
    return
  }

  initialize()
  isFirstSelected.value = false
}

defineExpose({
  primal,
  data,
  onSelected,
})

</script>

<style scoped>
#filesystem {
  background: white;
  width: 100%;
  height: v-bind(contentHeight);
}

#env_fs {
  background: white;
  width: 100%;
  height: v-bind(contentHeight);
  padding: 1px 0 3px 3px
}


.operate {
  display: flex;
  justify-content: space-evenly;
}

#env_fs img {
  width: 20px;
  height: 20px
}

.operate > img:hover {
  background: #c0fcff;
  outline: 1px solid #68f8ff;
}

.highlight {
  background: #89baeb;
}
</style>
