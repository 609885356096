<template>
  <div class="chat">
    <a-tabs v-model:activeKey="activeKey" centered>
      <a-tab-pane key="1" tab="直播互动">
        <div class="info">
          <div v-for="row in data_list" :key="row.id">
            <img v-if="row.has_image" :src="`/web/image/res.users/${row.create_uid}/image_512`"/>
            <img v-else src="/hw_web/static/src/img/avatar_live.png"/>
            <div class="content1">
              <div>{{ row.create_name }} <small>{{ row.create_date }}</small></div>
              <div v-html="row.comment"></div>
            </div>
          </div>
        </div>
      </a-tab-pane>
      <a-tab-pane key="2" tab="在线用户">
        <div class="onlineUser">
          <div v-for="(online,index) in online_user_ids" :key="online.id">
            <a-card :bordered="false" size="small">
              <template v-if="index == 0" #extra>
                <a-tag color="success">当前在线：{{ current_online_user_ids }}人</a-tag>
                <a-button @click="handleSettingButtonClick">
                  <SettingOutlined/>
                </a-button>
              </template>
              <a-card-meta>
                <template #avatar>
                  <a-avatar v-if="online.has_image"
                            :src="`/web/image/res.users/${online.user_id}/image_512?t=${new Date().getTime()}`"/>
                  <a-avatar v-else src="/hw_web/static/src/img/avatar_live.png"/>
                </template>
                <template #title>
                  <a-flex align="center" justify="space-between" style="height: 32px;">
                    <div style="font-size: 16px;">{{ online.user_name }}</div>
                    <div class="converse_btn">
                      <a-tag v-show="!showUserCallButton && online.user_id == login_user_id" color="processing"
                             style="width: 35px;height: 20px">
                        <a-statistic-countdown :value="deadline" format="mm:ss" style="font-size: 5px !important;"
                                               @finish="onDeadlineFinish">
                        </a-statistic-countdown>
                      </a-tag>
                      <img v-if="online.apply_call_state =='on'" class="mr-3"
                           src="/hw_course/static/src/img/live_talking.png" title="通话中"/>
                      <template v-if="teacher_uid">
                        <img v-if="online.apply_call_state =='ready'" class="mr-3"
                             src="/hw_course/static/src/img/live_connect.png"
                             title="接听语音" @click="handlePlayVoiceCall(online.id,online.user_id)"/>
                        <img v-if="online.apply_call_state =='ready'"
                             src="/hw_course/static/src/img/live_reject_connect.png"
                             title="拒绝" @click="handleRejectVoiceCall(online.id,online.user_id)"/>
                        <img v-if="online.apply_call_state =='on'"
                             src="/hw_course/static/src/img/live_ringOff.png"
                             title="挂断语音" @click="teacherHandleVoiceCallLeaveBtn(online.id,online.user_id)"/>
                      </template>
                      <template v-else>
                        <img v-if="online.apply_call_state =='on' && online.user_id == login_user_id"
                             src="/hw_course/static/src/img/live_ringOff.png"
                             title="挂断语音" @click="handleVoiceCallLeaveBtn(online.id,online.user_id)"/>
                      </template>
                    </div>
                  </a-flex>
                </template>
              </a-card-meta>
            </a-card>
          </div>
          <a-alert v-show="testMicrophoneButton" message="麦克风测试中：请打开声音，并持续讲话...." show-icon
                   type="warning"/>
          <audio id="J_prismAudio_local" autoplay loop></audio>
        </div>
      </a-tab-pane>
    </a-tabs>
    <div class="send">
      <!-- 语音 -->
      <template v-if="!teacher_uid && current_online_id">
        <img v-show="showUserCallButton" src="/hw_course/static/src/img/live_lianmai.png"
             style="margin-left: 3px;margin-right: 8px;"
             title="发起语音" @click="handleVoiceCall(current_online_id)"/>
        <img v-show="!showUserCallButton" src="/hw_course/static/src/img/live_cancel_lianmai.png"
             style="margin-left: 3px;margin-right: 8px;"
             title="挂断语音" @click="handleVoiceCallLeaveBtn(current_online_id,login_user_id)"/>
      </template>
      <input v-model="comment" class="form-control" placeholder="输入聊天内容...回车键发送"
             type="text" @keyup="handleKeyUp"/>
      <!-- <img @click="sendComment" src="/hw_web/static/src/img/send-icon.png" title="发送"/> -->
      <!-- 评论（HTML） -->
      <img src="/hw_web/static/src/img/send-html-icon.png" title="发送(html)" @click="Modal2 = true"/>
      <a-modal v-model:open="Modal2" :confirm-loading="confirmLoading2"
               :getContainer="getContainer" :mask="false"
               :maskClosable="false" :wrap-style="{ overflow: 'hidden' }" ok-text="发送消息"
               width="40%" @ok="sendCommentHtml">
        <template #title>
          <div ref="modalTitleRef" style="width: 100%; cursor: move">聊天室</div>
        </template>
        <a-form @submit="sendCommentHtml">
          <a-form-item>
            <RichEditor v-model="commentHtml" :editMode="'create'" :height="301"/>
          </a-form-item>
        </a-form>
        <template #modalRender="{ originVNode }">
          <div :style="transformStyle">
            <component :is="originVNode"/>
          </div>
        </template>
      </a-modal>
      <!-- 举手提问 -->
      <!-- <img v-if="!is_teacher" @click="Modal1 = true" src="/hw_experiment/static/src/img/user_manua1.png"
           height="34" width="34" title="举手提问"/> -->
      <div ref="refModalBox">
        <a-modal v-model:open="Modal1"
                 :confirm-loading="spinning"
                 :force-render="true"
                 :getContainer="()=>$refs.refModalBox"
                 :mask="false"
                 :maskClosable="false"
                 ok-text="提交问题"
                 @ok="createQuestion">
          <template #title>
            <div ref="modalTitleRef" style="width: 100%; cursor: move">举手提问</div>
          </template>
          <template #modalRender="{ originVNode }">
            <div :style="transformStyle">
              <component :is="originVNode"/>
            </div>
          </template>
          <a-form ref="formRef" :model="formState" :rules="rules" name="question_form_modal">
            <a-form-item v-show="false" label="直播ID" name="live_id">
              <a>{{ formState.live_id }}</a>
            </a-form-item>
            <a-form-item label="问题标题" name="question_title">
              <a-input v-model:value="formState.question_title" allow-clear autofocus show-count/>
            </a-form-item>
            <a-form-item label="问题内容" name="question">
              <RichEditor v-model="formState.question" :editMode="'create'" :height="301"/>
            </a-form-item>
          </a-form>
        </a-modal>
      </div>
    </div>

    <a-drawer :closable="false" :getContainer="getContainer" :open="open" :width="350" placement="left" title="通话设置"
              @close="onClose">
      <a-flex gap="middle" vertical>
        <div>
          <a>当前麦克风:{{ getMicLabelById(deviceInfo.micId) }}</a>
        </div>
        <div ref="selectContainer">
          <a>麦克风列表：</a>
          <a-select
              v-model:value="MicValue"
              :disabled="selectDisabled"
              :field-names="{ label: 'label', value: 'deviceId',}"
              :getPopupContainer="()=>$refs.selectContainer"
              :options="deviceInfo.micList"
              placeholder="请下拉选择"
              style="width: 80%"
              @change="handleChange"
          >
          </a-select>
        </div>
        <a-button :disabled="testMicrophoneButton" type="primary"
                  @click="testMicrophoneAudioTrack()">
          确认并测试麦克风
        </a-button>
        <a-divider/>
        <div>
          <div style="padding-bottom: 10px">
            <a>当前扬声器:{{ getSpeakerLabelById(deviceInfo.speakerId) }}</a>
          </div>
          <div>
            <a>
              扬声器列表：
            </a>
            <a-select
                v-model:value="speakerValue"
                :disabled="selectDisabled"
                :field-names="{ label: 'label', value: 'deviceId',}"
                :getPopupContainer="()=>$refs.selectContainer"
                :options="deviceInfo.speakerList"
                placeholder="请下拉选择"
                style="width: 80%"
                @change="handleSpeakerChange"
            >
            </a-select>
          </div>
        </div>
        <a-button :disabled="testMicrophoneButton" type="primary"
                  @click="testMicrophoneAudioTrack()">
          确认并测试扬声器
        </a-button>
      </a-flex>
    </a-drawer>
  </div>
</template>

<script setup>
import {logDebug, logError} from "@/utils/logger";
import {computed, markRaw, onBeforeUnmount, onMounted, reactive, ref, watch, watchEffect,} from "vue";
import {isElectronEnv, isEmpty, isFalse} from "@/utils/common_utils";
import {getFailedMessage, getResponseData, jsonRPC} from "@/utils/http_utils";
import {message, Modal, notification} from "ant-design-vue";
import RichEditor from "@/components/RichEditor"
import {useDraggable} from '@vueuse/core';
import DingRTC from 'dingrtc';
import {SettingOutlined} from '@ant-design/icons-vue';
import chatMessageStore from "@/stores/chat.js";
import {sleep} from "@/utils/time_utils";

const activeKey = ref('1');

const modalTitleRef = ref(null);
const {x, y, isDragging} = useDraggable(modalTitleRef);
const startX = ref(0);
const startY = ref(0);
const startedDrag = ref(false);
const transformX = ref(0);
const transformY = ref(0);
const preTransformX = ref(0);
const preTransformY = ref(0);
const dragRect = ref({
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
});
watch([x, y], () => {
  if (!startedDrag.value) {
    startX.value = x.value;
    startY.value = y.value;
    const bodyRect = document.body.getBoundingClientRect();
    const titleRect = modalTitleRef.value.getBoundingClientRect();
    dragRect.value.right = bodyRect.width - titleRect.width;
    dragRect.value.bottom = bodyRect.height - titleRect.height;
    preTransformX.value = transformX.value;
    preTransformY.value = transformY.value;
  }
  startedDrag.value = true;
});
watch(isDragging, () => {
  if (!isDragging.value) {
    startedDrag.value = false;
  }
});
watchEffect(() => {
  if (startedDrag.value) {
    transformX.value =
        preTransformX.value +
        Math.min(Math.max(dragRect.value.left, x.value), dragRect.value.right) -
        startX.value;
    transformY.value =
        preTransformY.value +
        Math.min(Math.max(dragRect.value.top, y.value), dragRect.value.bottom) -
        startY.value;
  }
});
const transformStyle = computed(() => {
  return {
    transform: `translate(${transformX.value}px, ${transformY.value}px)`,
  };
});
const props = defineProps({
  primal: {type: String},
  data: {type: Object},
});

const spinning = ref(false);
const primal = props.primal;
const data = props.data;
const Modal1 = ref(false)
const Modal2 = ref(false)
const confirmLoading2 = ref(false)
let comment = ref('');
let commentHtml = ref('');
let is_teacher = ref(false);
let sendCommentTime = ref(0);
const data_list = reactive({});

let sendComment = () => {
  if (isEmpty(comment.value)) {
    message.warn("请输入内容！")
    return
  }
  let endTime = new Date().getTime();
  let elapsedTime = (endTime - sendCommentTime.value) / 1000;
  if (elapsedTime < 3) {
    message.warn(`您发表评论过快，请等待 3 秒！`);
    return;
  }
  sendCommentTime.value = endTime; // 发表评论时间
  const result = jsonRPC({
    url: "/vue/live/send/comment",
    params: {
      id: parseInt(data.live_id),
      comment: comment.value,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`发送消息成功`, data);
      Object.assign(data_list, data);
      // 获取容器元素
      var container = document.querySelector('.info');
      // 滚动到容器底部
      setTimeout(() => {
        container.scrollTop = container.scrollHeight;
      }, 300)
    },
    fail(error) {
      logError(`发送消息失败, `, error);
      message.error(`发送消息失败，[${getFailedMessage(error)}]`, 3);
    },
  });
  result.then(function () {
    comment.value = ''
  })
};
let handleKeyUp = (ev) => {
  // 检查按下的键是否是回车键 (key code 13)
  if (ev.key === "Enter") {
    // 执行发送内容的逻辑
    sendComment();
  }
};
let sendCommentHtml = () => {
  if (commentHtml.value === '<p><br></p>') {
    message.warn("请输入内容！")
    return
  }
  let endTime = new Date().getTime();
  let elapsedTime = (endTime - sendCommentTime.value) / 1000;
  if (elapsedTime < 3) {
    message.warn(`您发表评论过快，请等待 3 秒！`);
    return;
  }
  sendCommentTime.value = endTime; // 发表评论时间
  confirmLoading2.value = true
  const result = jsonRPC({
    url: "/vue/live/send/comment",
    params: {
      id: parseInt(data.live_id),
      comment: commentHtml.value,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`发送消息成功`, data);
      Object.assign(data_list, data);
      // 获取容器元素
      const container = document.querySelector('.info');
      // 滚动到容器底部
      setTimeout(() => {
        container.scrollTop = container.scrollHeight;
      }, 300)
    },
    fail(error) {
      logError(`发送消息失败, `, error);
      message.error(`发送消息失败，[${getFailedMessage(error)}]`, 3);
    },
  });
  result.then(function () {
    Modal2.value = false
    confirmLoading2.value = false
    commentHtml.value = '<p><br></p>'
  })
}

const formRef = ref();
const formState = reactive({
  live_id: parseInt(data.live_id),
  question_title: '',
  question: '',
});
const rules = {
  question_title: [
    {
      required: true,
      trigger: 'change',
    },
    {
      min: 3,
      max: 50,
      message: '请限制在3-50字内',
      trigger: 'blur',
    },
  ],
  question: [
    {
      required: true,
      trigger: 'change',
    },
  ],
}

let createQuestion = () => {
  if (formState.question === '<p><br></p>') {
    message.error("请输入内容！", 3)
    return
  }
  formRef.value.validate()
      .then(async values => {
        logDebug('接收到的表单值：', values);
        spinning.value = true
        await jsonRPC({
          url: "/vue/submit/live/question",
          params: {data: JSON.stringify(values)},
          success(res) {
            let data = getResponseData(res);
            logDebug(`创建成功`, data);
            if (data) {
              spinning.value = false
              Modal1.value = false;
              formRef.value.resetFields(); // 重置表单
              message.success(`提交成功`, 3);
            }
          },
          fail(error) {
            logError(`创建失败, `, error);
            spinning.value = false
            message.error(`提交失败，请稍候再试。[${getFailedMessage(error)}]`, 3);
          },
        })
      })
      .catch(info => {
        logDebug('验证失败：', info);
      });
};

const $chatMessageStore = chatMessageStore();
let timestampInMilliseconds = Date.now();

function fetchData() {
  jsonRPC({
    url: "/vue/get/live/detail/comment",
    params: {
      id: parseInt(data.live_id),
      danmu_timestamp: timestampInMilliseconds,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取成功`, data);
      timestampInMilliseconds = Date.now();
      $chatMessageStore.pushChatMessage(data.danmaku_ids)
      Object.assign(data_list, data.comment_ids);
      is_teacher.value = data.is_teacher;
      // if (data.live_state != 'living') {
      //   clearInterval(timer.value);
      //   Modal.info({
      //     title: '直播未开始或已结束',
      //     content: '请等待直播开始',
      //     icon: createVNode(ExclamationCircleOutlined),
      //     centered: true,
      //     onOk() {
      //       logDebug('OK');
      //       // window.location.assign('/live');
      //     },
      //   });
      // }
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  });
}

let timer = ref();
let getOnlineUserListTimer = ref();
let getRemoteUsersTimer = ref();
let container = null
const getContainer = () => {
  return container
}
onMounted(() => {
  container = document.querySelector('.chat')
  fetchData();
  timer.value = setInterval(fetchData, 5000);

  queryOnlineUserList()
  getOnlineUserListTimer.value = setInterval(queryOnlineUserList, 3000);
});

onBeforeUnmount(() => {
  clearInterval(timer.value);
  clearInterval(getOnlineUserListTimer.value);
  clearInterval(getRemoteUsersTimer.value);
});

const isFirstSelected = ref(true);
const onSelected = () => {
  logDebug(`LiveDetailChat onSelected. data[${JSON.stringify(data)}]`);
  if (isFalse(isFirstSelected.value)) {
    return;
  }
  isFirstSelected.value = false;
};

const getDeviceList = async () => {
  try {
    const [micList, speakerList] = await Promise.all([DingRTC.getMicrophones(), DingRTC.getPlaybackDevices()]);

    // 过滤有效设备
    const filterDevices = (list) => list.filter((item) => item.deviceId);
    const filteredMicList = filterDevices(micList);
    const filteredSpeakerList = filterDevices(speakerList);

    // 更新设备信息
    deviceInfo.value = {
      ...deviceInfo.value,
      micList: filteredMicList,
      speakerList: filteredSpeakerList,
      micId: filteredMicList[0]?.deviceId,
      speakerId: filteredSpeakerList[0]?.deviceId,
    };

    logDebug("获取播放设备", deviceInfo.value);
  } catch (error) {
    logError("设备获取失败", error);
  }
};


onMounted(async () => {
  try {
    await queryUserListToken(); // 第一次加载，需要后端生成token
    await AliRtcSupportCheck(); // token获取成功后进行rtc能力检测
    await getDeviceList()
  } catch (error) {
    logError('Error during initialization:', error);
  }
});

// 创建通话DingRTC引擎
const newClient = markRaw(DingRTC.createClient())
DingRTC.setLogLevel("debug");

let teacher_uid = ref();
let total_user_ids = ref(0);
let current_online_user_ids = ref(0);
let login_user_id = ref();
const online_user_ids = ref([]);
const current_online_id = ref();
const joinInfo = reactive({'appId': null, 'token': null, 'uid': null, 'channel': null, 'userName': null,});
const deviceInfo = ref({
  micList: [],
  speakerList: [],
  micId: null,
  speakerId: null,
});

let showUserCallButton = ref(true);
let showTeacherCallButton = ref(true);
let showTeacherCallOffBtn = ref(false);
let currentRemoteUserId = 0;
let localMicTrack;
let currentRemoteUserTrack = null;
let RemoteUserAudioTrack = []

/**
 * isSupport rtc能力检测
 */
async function AliRtcSupportCheck() {
  const supported = DingRTC.checkSystemRequirements();
  if (supported) {
    await getNewClient().then(
        await RemoteInit() //初始化远端设备，并做事件回调
    )
    LocalDeviceInit() // 初始化本地设备，并做事件回调
  } else {
    notification.warning({message: '当前浏览器不支持连麦',})
    return false
  }
}

/**
 * LocalDeviceInit 初始化本地设备，并做事件回调
 */
function LocalDeviceInit() {

  DingRTC.on('microphone-changed', (info) => {
    logDebug(info);
    if (info) {
      if (info.state == "active") {
        notification.warning({
          message: "音频采集设备状态变化,检测到插入新的麦克风设备！",
        })
      }
      if (info.state == "inactive") {
        notification.warning({
          message: "音频采集设备状态变化,检测到麦克风被拔出！",
        })
      }
    }

  })

  DingRTC.on('playback-device-changed', (info) => {
    logDebug(info);
    if (info) {
      if (info.state == "active") {
        notification.warning({
          message: "音频播放设备变化,检测到插入新的设备！",
        })
      }
      if (info.state == "inactive") {
        notification.warning({
          message: "音频播放设备变化,检测设备被拔出！",
        })
      }
    }
  });

  DingRTC.on('autoplay-failed', (track) => {
    logDebug(track);
    Modal.confirm({
      content: '由于浏览器自动播放限制，点击确定后才会开始播放音频',
      onOk: () => {
        track.play('#J_prismAudio_local');
      },
    });
  });
}


// 初始化最后警告时间
let lastWarningTime = 0;

// Notification，带有节流机制
const throttledNotification = (message, message_type) => {
  const currentTime = Date.now();
  if (currentTime - lastWarningTime >= 5000) { // 检查是否已经过去了5秒
    if (message_type == "warning") {
      notification.warning({
        message: message,
        duration: 1,
      });
    } else if (message_type == "info") {
      notification.info({
        message: message,
        duration: 1,
      });
    }
    lastWarningTime = currentTime; // 更新最后警告时间
  }
};

/**
 * LocalDeviceInit 初始化远端设备，并做事件回调
 */
async function RemoteInit() {
  // 检查网络质量并发出警告
  newClient.on('network-quality', (uplinkNetworkQuality, downlinkNetworkQuality) => {
    // 网络质量定义
    const networkQualityDescriptions = {
      '0': '网络状态未知',
      '1': '网络极佳',
      '2': '网络较好',
      '3': '网络一般',
      '4': '网络较差',
      '5': '网络极差',
      '6': '网络已断开'
    };
    // 检查网络质量并发出警告
    const checkNetworkQuality = (quality, direction) => {
      if (Number(quality) >= 5) {
        const message = `当前${direction}网络状态： ${networkQualityDescriptions[quality] || quality}`;
        throttledNotification(`当前网络不佳`, 'warning');
        logDebug(message);
      }
    };
    checkNetworkQuality(uplinkNetworkQuality, '上行');
    checkNetworkQuality(downlinkNetworkQuality, '下行');
  });

  // 远端用户取消发布轨道的回调
  newClient.on("user-unpublished", async (user, mediaType, auxiliary) => {
    logDebug("该回调通知远端用户取消发布音频轨道或者视频轨道。", user);
    logDebug("媒体类型：", mediaType);
    logDebug("辅助信息：", auxiliary);
    if (user && user.userId) {
      if (user.userId == currentRemoteUserId) {
        await unsubscribeRemoteUserAudioTrack(user)
      } else {
        const userTrackIndex = RemoteUserAudioTrack.findIndex(item => item.userId == user.userId);
        RemoteUserAudioTrack.splice(userTrackIndex, 1);
      }
    }
    logDebug("远端用户取消发布轨道的回调更新后的：RemoteUserAudioTrack", RemoteUserAudioTrack);
  });

  // 远端用户发布新轨道的回调
  newClient.on("user-published", async (user, mediaType, auxiliary) => {
    logDebug("该回调通知远端用户发布了新的音频轨道或者视频轨道。", user);
    logDebug("媒体类型：", mediaType);
    logDebug("辅助信息：", auxiliary);

    if (!user || !user.userId) {
      return;
    }
    RemoteUserAudioTrack.push(user)
    logDebug("远端用户发布了新的音频轨道或者视频轨道更新后的：RemoteUserAudioTrack", RemoteUserAudioTrack);
  });

}

// 获取在线用户列表
const queryOnlineUserList = () => {
  jsonRPC({
    url: `/api/live/${data.live_id}/online/user_list`,
    success(res) {
      const responseData = getResponseData(res);
      logDebug('获取在线用户列表', responseData);
      const {online_user_ids: onlineUserIds, login_user_id: loginUserId, teacher_id: teacherId} = responseData;
      online_user_ids.value = onlineUserIds;

      const currentUser = onlineUserIds.find(user => user.user_id === loginUserId);
      current_online_id.value = currentUser ? currentUser.id : false;
      login_user_id.value = loginUserId;
      teacher_uid.value = teacherId;
      total_user_ids.value = responseData.total_user_ids
      current_online_user_ids.value = responseData.current_online_user_ids

      teacherHandleUserLeave();
    },
    fail(error) {
      logError('获取在线用户列表失败', error);
    },
  });
};


// 查询在线用户列表Token
let queryUserListToken = () => {
  return new Promise((resolve, reject) => {
    jsonRPC({
      url: `/api/live/${data.live_id}/channel/token`,
      success(res) {
        const data = getResponseData(res);
        Object.assign(joinInfo, data);
        // logDebug('查询在线用户列表Token成功', data);
        resolve(data); // 成功后调用 resolve
      },
      fail(error) {
        logError('查询在线用户列表Token失败', error);
        reject(error); // 失败后调用 reject
      },
    });
  });
};


/**
 * 发送请求以更新语音状态
 */
const applyCallRequest = async (online_id, state) => {
  await jsonRPC({
    url: `/api/live/online/call/state`, // 请求的 API 端点
    params: {
      online_id: online_id, // 在线用户 ID
      apply_call_state: state, // 语音状态
    },
    // 请求成功后的回调函数
    success(res) {
      // 处理响应数据
      const data = getResponseData(res);
      logDebug("更新语音状态请求", data);
      // 更新在线用户列表
      queryOnlineUserList();
    },
    // 请求失败后的回调函数
    fail(error) {
      // 打印错误信息
      logError(`语音请求失败,`, `${error}`);
    },
  });
}

const deadline = ref(0) // 通话倒计时


let lastCallTimestamp = 0;
// 发起语音
const handleVoiceCall = async (online_id) => {
  const supported = DingRTC.checkSystemRequirements();
  if (!supported) {
    notification.warning({message: '当前浏览器不支持通话，请更换浏览器'});
    return;
  }
  if (!deviceInfo.value.micId) {
    notification.warning({message: '未检测到您的麦克风设备，请插入麦克风并重试'});
    return;
  }

  const now = Date.now();
  if (now - lastCallTimestamp < 10000) {
    notification.warning({message: '操作过于频繁，请稍后再试'});
    return;
  }
  lastCallTimestamp = now;

  deadline.value = Date.now() + 1000 * 60 * 20;

  const cleanup = async () => {
    if (localMicTrack) {
      await localMicTrack.close();
      await newClient.unpublish();
      await applyCallRequest(current_online_id.value, "down");
    }
    showUserCallButton.value = true;
  };

  try {
    const MicTrack = await checkLocalTrack();
    logDebug("MicTrack", MicTrack);
    showUserCallButton.value = false;

    if (MicTrack) {
      await getNewClient();
      await newClient.publish(MicTrack); // 推送本地麦克风
      await applyCallRequest(online_id, 'ready'); // 更新语音状态请求
      message.success("连麦已申请，请等待对方接听");
    } else {
      notification.error({message: '连麦申请失败，无法推送本地麦克风，请稍后再试'});
      await cleanup(); // 处理失败的清理
    }
  } catch (error) {
    logError("Error during voice call:", error);
    notification.error({message: `连麦申请失败，推送本地麦克风失败: ${error?.reason || error?.message || JSON.stringify(error)}，请稍后再试`});
    await cleanup(); // 处理异常的清理
  }
};

const isElectron = computed(() => {
  return isElectronEnv();
})

const checkAndAuthMicPhone = async function () {
  let getUserMedia
  if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
    getUserMedia = navigator.mediaDevices.getUserMedia
  } else {
    getUserMedia = navigator.getUserMedia ||
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia ||
        navigator.msGetUserMedia;
  }

  if (getUserMedia) {
    await getUserMedia({audio: true}).then(async (stream) => {
      // 只是为了获取麦克风权限，获取以后立马关闭
      stream.getTracks().forEach(track => track.stop());
      await sleep(1000)
      open.value = false
      notification.info({
        message: '麦克风已开启，请重新点击“设置”按钮中的“测试麦克风”进行测试',
      })
    }).catch((e) => {
      logError("麦克风开启失败，", e);
      logDebug(`isElectron.value[${isElectron.value}]`)
      if (isElectron.value) {
        if (window.electronAPI && window.electronAPI.sendMessage) {
          const data = JSON.stringify({
            event: "checkAndAuthMicPhone",
            data: {}
          })
          logDebug(`window.electronAPI.sendMessage, data[${data}]`)
          window.electronAPI.sendMessage(data)
        }
      } else {
        notification.warning({
          message: '麦克风开启失败，请在浏览器“设置”中搜索“麦克风”关键词，允许本网站使用麦克风后再使用连麦功能！',
        })
      }
    })
  }
}

let testMicrophoneButton = ref(false)
// 测试麦克风6秒后结束
const testMicrophoneAudioTrack = async () => {
  const supported = DingRTC.checkSystemRequirements();
  if (!supported) {
    notification.warning({
      message: '当前浏览器不支持通话',
    })
  }
  DingRTC.getMicrophones().then(async (microphoneList) => {
    if (microphoneList.length > 0) {
      const microphoneId = microphoneList[0].deviceId;
      let microphoneTrack
      try {
        microphoneTrack = await DingRTC.createMicrophoneAudioTrack({deviceId: microphoneId});
      } catch (e) {
        if (isElectron.value) {
          if (window.electronAPI && window.electronAPI.sendMessage) {
            const data = JSON.stringify({
              event: "checkAndAuthMicPhone",
              data: {}
            })
            logDebug(`window.electronAPI.sendMessage, data[${data}]`)
            window.electronAPI.sendMessage(data)
          }
        }
        throw e;
      }
      microphoneTrack.play('#J_prismAudio_local')
      testMicrophoneButton.value = true
      message.loading('设备测试中：请打开声音，并持续讲话....', 6);
      deviceInfo.value.micId = microphoneId
      setTimeout(() => {
        microphoneTrack.stop();
        microphoneTrack.close();
        testMicrophoneButton.value = false
        logDebug('Microphone track stopped');
      }, 6000);
    } else {
      await checkAndAuthMicPhone()
    }
  })
}

const selectDisabled = ref(false);
const MicValue = ref();
const speakerValue = ref();
const handleChange = value => {
  logDebug(`selected ${value}`);
  deviceInfo.value.micId = value
};
const handleSpeakerChange = value => {
  logDebug(`selected ${value}`);
  deviceInfo.value.speakerId = value
};

const open = ref(false);
const handleSettingButtonClick = () => {
  getDeviceList()
  open.value = true;
}
const onClose = () => {
  open.value = false;
};

function getMicLabelById(micId) {
  const mic = deviceInfo.value.micList.find(mic => mic.deviceId === micId);
  return mic ? mic.label : "设备未找到";
}

function getSpeakerLabelById(speakerId) {
  const speaker = deviceInfo.value.speakerList.find(speaker => speaker.deviceId === speakerId);
  return speaker ? speaker.label : "设备未找到";
}

// 检查本地localMicTrack
const checkLocalTrack = async () => {
  const microphoneId = deviceInfo.value.micId;
  if (microphoneId) {
    localMicTrack = null
    try {
      localMicTrack = await DingRTC.createMicrophoneAudioTrack({deviceId: microphoneId})
    } catch (e) {
      if (isElectron.value) {
        if (window.electronAPI && window.electronAPI.sendMessage) {
          const data = JSON.stringify({
            event: "checkAndAuthMicPhone",
            data: {}
          })
          logDebug(`window.electronAPI.sendMessage, data[${data}]`)
          window.electronAPI.sendMessage(data)
        }
      }
      throw e;
    }
    return localMicTrack;
  }
  try {
    const microphoneList = await DingRTC.getMicrophones()
    if (microphoneList.length > 0) {
      const microphoneId = microphoneList[0].deviceId;
      testMicrophoneButton.value = true;
      deviceInfo.value.micId = microphoneId;
      localMicTrack = null
      try {
        localMicTrack = await DingRTC.createMicrophoneAudioTrack({deviceId: microphoneId})
      } catch (e) {
        if (isElectron.value) {
          if (window.electronAPI && window.electronAPI.sendMessage) {
            const data = JSON.stringify({
              event: "checkAndAuthMicPhone",
              data: {}
            })
            logDebug(`window.electronAPI.sendMessage, data[${data}]`)
            window.electronAPI.sendMessage(data)
          }
        }
        throw e;
      }
      return localMicTrack;
    } else {
      await checkAndAuthMicPhone()
      return null;
    }
  } catch (error) {
    logError('获取麦克风失败', error);
    notification.error({
      message: '获取麦克风失败，请重试',
    });
    return null;
  }
};

// 学生：侧挂断语音
const handleVoiceCallLeaveBtn = async (online_id, user_id) => {
  await applyCallRequest(online_id, 'down');
  showUserCallButton.value = true;
  logDebug("学生", user_id)
  logDebug("侧挂断语音localMicTrack", localMicTrack)
  if (localMicTrack) {
    await localMicTrack.close();
    await newClient.unpublish();
    // notification.success({message: '已挂断'});
  }
};

// 老师：侧挂断语音，取消订阅
const teacherHandleVoiceCallLeaveBtn = async (online_id, user_id) => {
  logDebug("currentRemoteUserTrack", currentRemoteUserTrack)
  if (currentRemoteUserTrack != null) {
    currentRemoteUserTrack.stop()
    currentRemoteUserTrack.setVolume(0)
    // notification.success({message: '已挂断'});
  }
  await applyCallRequest(online_id, "teacher_down");
  logDebug("unSubscribeResult 老师：侧挂断语音")
  logDebug(user_id)
};

// 老师挂断电话，更新学生用户信息
let notificationSent = false; // 初始化为 false
const teacherHandleUserLeave = async () => {
  const currentUser = online_user_ids.value.find(user => user.user_id === login_user_id.value);

  const handleCallEnd = async (notificationMessage) => {
    if (!notificationSent) {  // 仅在尚未发送提醒时触发
      showUserCallButton.value = true;
      logDebug("老师挂断电话，更新学生用户信息 notificationMessage", notificationMessage);
      message.info(notificationMessage, 3);
      notificationSent = true; // 发送提醒后标记为已提醒
    }

    if (localMicTrack) {
      await localMicTrack.close();
      await newClient.unpublish();
      await applyCallRequest(current_online_id.value, "down");
      showUserCallButton.value = true;
    }
  };

  if (currentUser?.id) {
    switch (currentUser.apply_call_state) {
      case 'teacher_down':
        await handleCallEnd('对方已挂断');
        break;
      case 'reject':
        await handleCallEnd('对方已拒接');
        break;
    }
  }
};


// 通话倒计时结束触发事件
const onDeadlineFinish = async () => {
  logDebug('通话倒计时结束触发事件 finished!');
  await applyCallRequest(current_online_id.value, 'down');
  showUserCallButton.value = true;
  if (localMicTrack) {
    await localMicTrack.close();
    await newClient.unpublish();
  }
};

// 老师：接通电话
const handlePlayVoiceCall = async (online_id, user_id) => {
  logDebug(user_id);
  // await applyCallRequest(online_id, 'on');  // 申请接听语音通话
  showTeacherCallOffBtn.value = true
  message.success('连接中。。');
  try {
    if (RemoteUserAudioTrack.length > 0) {
      logDebug("接通电话 RemoteUserAudioTrack ", RemoteUserAudioTrack);
      // 重新进入频道
      newClient.leave()
      let result = await newClient.join({
        appId: joinInfo.appId,
        token: joinInfo.token,
        uid: joinInfo.uid,
        channel: joinInfo.channel,
        userName: joinInfo.userName,
      })
      logDebug("接通电话,重新进入频道 result ", result);
      if (result.remoteUsers.length > 0) {
        await newClient.subscribe('mcu', 'audio').then(
            async (RemoteAudioTrack) => {
              if (RemoteAudioTrack) {
                logDebug("接通电话 RemoteAudioTrack", RemoteAudioTrack);
                currentRemoteUserTrack = RemoteAudioTrack
                currentRemoteUserId = user_id
                RemoteAudioTrack.play('#J_prismAudio_local')// 播放远端音频
                RemoteAudioTrack.setVolume(1)// 播放远端音频
                showTeacherCallButton.value = false;
                await applyCallRequest(online_id, 'on');  // 申请接听语音通话
                message.success('已接听');
                // notification.success({message: '已接听'});
              }
            }
        )
      }
    }
  } catch (error) {
    showTeacherCallButton.value = true;
    currentRemoteUserTrack = null
    await applyCallRequest(online_id, 'down');  // 申请接听语音通话
    const errorMessage = error?.reason || error?.message || JSON.stringify(error);
    message.error(`接听失败: ${errorMessage}`);
  }
};

// 老师： 拒绝通话
const handleRejectVoiceCall = async (online_id, user_id) => {
  logDebug(user_id);
  await applyCallRequest(online_id, 'reject');  // 申请接听语音通话
};


const getNewClient = async () => {
  try {
    if (!newClient?.joinInfo) {
      // 未初始化客户端，进行初始化和连接
      await newClient.join({
        appId: joinInfo.appId,
        token: joinInfo.token,
        uid: joinInfo.uid,
        channel: joinInfo.channel,
        userName: joinInfo.userName,
      });
      logDebug('DingRTC 客户端已成功加入频道');
    } else {
      logDebug('DingRTC 客户端已经初始化');
    }
  } catch (error) {
    logError('初始化或加入 DingRTC 客户端失败', error);
    // notification.error({message: '初始化或加入客户端失败，请重试'});
    throw error; // 抛出异常以供调用者处理
  }
};

// 取消订阅
const unsubscribeRemoteUserAudioTrack = async (user) => {
  try {
    // 确保 newClient 已初始化
    if (!newClient) {
      throw new Error('DingRTC 客户端未初始化');
    }

    const userTrackIndex = RemoteUserAudioTrack.findIndex(item => item.userId == user.userId);
    if (userTrackIndex !== -1) {
      // const userTrack = RemoteUserAudioTrack[userTrackIndex].RemoteAudioTrack;
      // logDebug("成功取消订阅远端音频", userTrack);
      // 从 RemoteUserAudioTrack 列表中移除该用户的音频轨道
      RemoteUserAudioTrack.splice(userTrackIndex, 1);
      await getNewClient()
      await newClient.unsubscribe('mcu', 'audio').then(
          async (RemoteAudioTrack) => {
            if (RemoteAudioTrack) {
              logDebug("成功取消订阅远端音频RemoteAudioTrack", RemoteAudioTrack);
              notification.info({message: `对方已挂断}`});
              currentRemoteUserId = 0
            }
          }
      )
      logDebug("更新后的 RemoteUserAudioTrack 列表", RemoteUserAudioTrack);

    }
  } catch (error) {
    logError("取消订阅远端音频失败", error);
  }
}


onBeforeUnmount(async () => {
  await getNewClient()
  if (!newClient || !newClient.subscribe) {
    return Promise.reject(new Error("client.subscribe is not defined"));
  }
  newClient.leave();

  await applyCallRequest(current_online_id.value, 'down');
  showUserCallButton.value = true;
  if (localMicTrack) {
    await localMicTrack.close();
    await newClient.unpublish();
  }
})


// 获取远端用户的视频统计信息
const getRemoteAudioStats = async () => {
  if (newClient) {
    let getRemoteAudioStats = await newClient.getRemoteAudioStats()
    if (getRemoteAudioStats && getRemoteAudioStats.audioLevel) {
      logDebug("远端用户的音频统计信息-调用时的时间戳", getRemoteAudioStats.timestamp)
      logDebug("音频能量", getRemoteAudioStats.audioLevel)
      logDebug("往返时延", getRemoteAudioStats.rtt)
      logDebug("接收的音频总字节数 (bytes)", getRemoteAudioStats.receiveBytes)
      logDebug("接收的音频总包数", getRemoteAudioStats.receivePackets)
      logDebug("远端用户的音频统计信息", getRemoteAudioStats)
    }

  }
}
let getRemoteAudioStatsTimer = ref();
onMounted(() => {
  getRemoteAudioStats()
  getRemoteAudioStatsTimer.value = setInterval(getRemoteAudioStats, 2000);
});
onBeforeUnmount(() => {
  clearInterval(getRemoteAudioStatsTimer.value);
});


defineExpose({
  primal,
  data,
  onSelected,
});
</script>

<style lang='scss' scoped>
:deep(.ant-modal-content) {
  resize: both; /* IE7+, Firefox, Opera, Safari */
  -moz-resize: both; /* Firefox */
  -webkit-resize: both; /* Safari, Chrome */
  overflow: auto; /* 允许用户通过滚动条调整大小 */
}

:where(.ant-btn >span) {
  display: inline-flex !important;
}

.chat {
  height: 100%;
  width: 100%;
  border: solid 2px #DAEDFF;
  border-radius: 8px;
  overflow: hidden;

  .info {
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column-reverse;
    padding: 15px;
    background-color: #fff;

    > div {
      display: flex;
      margin-bottom: 15px;

      img {
        height: 30px;
        width: 30px;
        border-radius: 50%;
        margin-right: 10px;
      }

      .content1 {
        flex: 1 1 auto;

        > div:first-child {
          color: #959595;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        > div:last-child {
          overflow-wrap: anywhere;
        }
      }
    }
  }

  .onlineUser {
    height: 100%;
    overflow: auto;
    background-color: #fff;

    .converse_btn {
      display: flex;

      img {
        height: 30px;
        width: 30px;
        // border-radius: 50%;
        cursor: pointer;
      }
    }
  }

  > .send {
    display: flex;
    align-items: center;
    height: 40px;
    background-color: #B9DEFF;
    padding-left: 8px;
    padding-right: 8px;

    > input {
      height: 30px;
      background-color: #fff;
      color: #7d7d7d;
      border-radius: 8px;
      box-shadow: none;
    }

    img {
      height: 20px;
      width: 20px;
      margin-left: 8px;
      margin-right: 3px;
      cursor: pointer;
    }
  }
}

:deep(.ant-tabs) {
  height: calc(100% - 40px);
}

:deep(.ant-tabs .ant-tabs-content) {
  height: 100%;
}

:deep(.ant-tabs-nav) {
  margin-bottom: 0;
}

:deep(.ant-tabs-nav-list) {
  width: 100%;
}

:deep(.ant-tabs .ant-tabs-tab) {
  width: 50%;
  padding: 8px 0;
  margin: 0;
  color: #258EFF;
  background-color: #E7F3FF;
  justify-content: center;
}

:deep(.ant-tabs .ant-tabs-tab.ant-tabs-tab-active) {
  color: #fff;
  background-color: #86c2ff;

  > .ant-tabs-tab-btn {
    color: #fff;
  }
}

:deep(.ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations) {
  display: none;
}

:deep(.ant-tabs .ant-tabs-ink-bar) {
  display: none;
}

:deep(.ant-statistic .ant-statistic-content) {
  font-size: 10px;
}
</style>