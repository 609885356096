<template>
  <div class="desktop" id="desktop">
    <div class="wallpaper-container" :style="{ 'background-image': 'url(' + $coreStore.wallpaper + ')' }">
      <div class="icon-grid-container" @mousedown.self="onMousedown($event)">
        <appIcon v-for="(item,index) in icons" :key="item.id" :item="item" :order="index"></appIcon>
      </div>
    </div>
    <Taskbar></Taskbar>
    <LiveRtc :livebarShow="$coreStore.livebar" :OnlineLiveList="query_onlineLive_list"></LiveRtc>
    <ContextMenu :display="$coreStore.contextMenu.show"
                 :position="{x:$coreStore.contextMenu.x,y:$coreStore.contextMenu.y}"
                 :data="$coreStore.contextMenu.data"></ContextMenu>
    <Sidebar :sidebarShow="$coreStore.sidebar"></Sidebar>
    <vue-danmaku ref="danmakuRef" v-model:danmus="danmus" class="danmu" v-bind="config">
      <template v-slot:dm="{ danmu }">
        <div class="danmu-item">
          <img :style="{ height: $coreStore.fontSize, width: $coreStore.fontSize }"
               :src="`/web/image/res.users/${danmu.create_uid}/image_512`"/>
          <span :style="{ color: $coreStore.fontColor, fontSize: $coreStore.fontSize }">{{ danmu.create_name }}：</span>
          <span :style="{ color: $coreStore.fontColor, fontSize: $coreStore.fontSize }" v-html="danmu.comment"></span>
        </div>
      </template>
    </vue-danmaku>
    <UpdateVersion></UpdateVersion>
    <NotificationWatcher></NotificationWatcher>
  </div>
</template>

<script setup name="desktop">
import '@/assets/css/style.less';
import '@/assets/css/icon.less';
import vueDanmaku from 'vue3-danmaku'
import {computed, onMounted, onBeforeUnmount, reactive, ref} from 'vue';
import {getResponseData, jsonRPC} from "@/utils/http_utils";
import {logDebug, logError} from "@/utils/logger";
import Taskbar from './Taskbar.vue';
import LiveRtc from "@/components/LiveRtc.vue"
import ContextMenu from './ContextMenu.vue';
import Sidebar from './Sidebar.vue';
import appIcon from './Icon.vue';
import coreStore from '@/stores/core.js';
import messageStore from "@/stores/message.js";
import UpdateVersion from "@/components/UpdateVersion.vue";
import {useRouter} from "vue-router";
import {trackPage, trackUV} from "@/utils/trackPage";
import NotificationWatcher from "@/views/desktop/NotificationWatcher.vue";
// import helper from '@/utils/helper.js'
// import useNotificationStore from '@/stores/notification';
// const notificationStore = useNotificationStore();
const danmakuRef = ref(null)
const danmus = ref([])

const config = reactive({
  autoplay: false,// 自动播放
  channels: 5, // 轨道数量，为0则弹幕轨道数会撑满容器
  randomChannel: true, // 随机弹幕轨道
  useSlot: true, // 是否开启slot
  loop: false, // 是否开启弹幕循环
  speeds: 50, // 弹幕速度
  fontSize: 25, // 文本模式下的字号
  top: 10, // 弹幕轨道间的垂直间距
  right: 50, // 同一轨道弹幕的水平间距
  debounce: 100, // 弹幕刷新频率（多少毫秒插入一条弹幕，建议不小于50）
})

const $coreStore = coreStore();
const $messageStore = messageStore();

const router = useRouter();
const user_name = ref(null)
const app_count = ref(0)
function fetchData() {
  jsonRPC({
    url: "/vue/desktop/environment",
    params: {},
    success(res) {
      const data = getResponseData(res)
      logDebug(`获取成功`, data)
      // 显示桌面环境提示
      $messageStore.cleanMessage();
      $messageStore.pushMessage({
        title: "初始化应用",
        content: `加载了${data.apps.length}个应用，欢迎${data.user_name}`,
      });
      user_name.value = data.user_name
      app_count.value = data.apps.length
      for (const app of data.apps) {
        let loading = false
        if (app.app_env_status != 'Running') {
          loading = true;
          renovate1()
        }
        $coreStore.applications = $coreStore.applications.filter((obj) => {
          return obj.id !== (Number(app.id) + 10000)
        });
        $coreStore.applications.push({
          "id": Number(app.id) + 10000,
          "name": app.name,
          "page": "desktopEnvironment",
          "icon": String(app.image_url),
          "width": 1024,
          "height": 600,
          "link": true,
          "abcPage": true,
          "loading": loading,
        });
      }
      // 进入桌面端右上角的提示
      // if (app_count.value >0){
      //   notificationStore.addNotification({ title: `${user_name.value},${helper.getTimeOfDay()}好:`,content: `欢迎访问Hi168在线学习平台,您当前有${app_count.value}个实验环境，正在稳定运行中。`,close_time:5 });
      // }else {
      //   notificationStore.addNotification({ title: `${user_name.value},${helper.getTimeOfDay()}好:`,content: `欢迎访问Hi168在线学习平台。`,close_time:5 });
      // }
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  }).then(()=>{
    jsonRPC({
      url: "/api/query/user/bucket/list",
      params: {},
      success(res) {
        const data = getResponseData(res);
        logDebug(`获取成功`, data);
        const bucketImages = [
          '/hw_frontend/static/img/BucketFolder.png',
          '/hw_frontend/static/img/BucketFolder1.png',
          '/hw_frontend/static/img/BucketFolder2.png'
        ];
        const filteredBuckets = data.user_id_bucket_list.filter(bucket => bucket.show_desktop);
        for (const [index, bucket] of [...filteredBuckets].reverse().entries()) {
          $coreStore.applications = $coreStore.applications.filter((obj) => {
            return obj.id != bucket.bucket_name;
          });
          $coreStore.applications.push({
            id: bucket.bucket_name,
            name: bucket.bucket_custom_name,
            page: "desktopBucket",
            icon: bucketImages[index % 3],
            width: 1024,
            height: 600,
            link: true,
            abcPage: true,
            loading: false,
          });
        }
      },
      fail(error) {
        logError(`查询失败, `, error);
      },
    });
  });
  trackPage(router.currentRoute);
}
const renovate1 = () => {
  jsonRPC({
    url: "/vue/desktop/environment",
    params: {},
    success(res) {
      const data = getResponseData(res);
      // 检测所有应用的状态都是创建好的，如果不是，则重新加载桌面环境
      let hasNonRunningApp = data.apps.filter(app => app.app_env_status != 'Running');
      if (hasNonRunningApp.length == 0) {
        // 批量更新状态
        $coreStore.applications.forEach(application => {
          application.loading = false;
        });
      }else {
        setTimeout(renovate1, 5000);
      }
    },
    fail(error) {
      logError('查询失败:', error);
    },
  });
};

const sectors = ref([])
onMounted(() => {
  $coreStore.init();
  //oncontextmenu 事件在元素中用户右击鼠标时触发并打开上下文菜单
  document.oncontextmenu = new Function('event.returnValue=false');
  //onselectstart 几乎可以用于所有对象，其触发时间为目标对象被开始选中时（即选中动作刚开始，尚未实质性被选中）
  // document.onselectstart = new Function("event.returnValue=false");
  fetchData()
  queryOnlineLiveList()
  // 设置直播弹幕并关闭
  $coreStore.liveInit(danmakuRef.value)
  $coreStore.liveStop()
  // 查询直播
  clearInterval(timer.value)
  timer.value = setInterval(fetchTeacherLiveIdData, 5000);
  // 获取用户操作模式
  jsonRPC({
    url: `/vue/user/get_operation_mode`,
    success(res) {
      const data = getResponseData(res);
      logDebug(`查询成功`, JSON.parse(data.sectors.replace(/'/g, '"')));
      sectors.value = JSON.parse(data.sectors.replace(/'/g, '"'));
    },
    fail(error) {
      logError('获取用户操作模式失败', error);
    },
  });
});
onBeforeUnmount(() => {
  clearInterval(timer.value)
});
const timer = ref()
const liveId = ref()

function fetchTeacherLiveIdData() {
  jsonRPC({
    url: "/vue/get/live/teacher/liveId",
    params: {},
    success(res) {
      const data = getResponseData(res);
      if (data > 0) {
        liveId.value = data;
        clearInterval(timer.value)
        timer.value = setInterval(fetchDanmakuData, 5000);
        $coreStore.livePay()
      }
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  });
}

let timestampInMilliseconds = Date.now();

function fetchDanmakuData() {
  jsonRPC({
    url: "/vue/get/live/detail/comment",
    params: {
      id: parseInt(liveId.value),
      danmu_timestamp: timestampInMilliseconds,
    },
    success(res) {
      const data = getResponseData(res);
      danmus.value = danmus.value.concat(data.danmaku_ids)
      timestampInMilliseconds = Date.now();
      if (data.live_state != 'living') {
        clearInterval(timer.value)
        timer.value = setInterval(fetchTeacherLiveIdData, 5000);
        danmus.value = []
      }
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  });
}

const icons = computed(() => {
  // 根据sectors.value来展示不同的图标，和item.page对比
  let icons = []
  $coreStore.applications.forEach(item => {
    if (item.link) {
      if (sectors.value.includes(item.page)) {
        // 隐藏的图标
        logDebug(item)
      }else{
        icons.push(item)
      }
    }
  })
  return icons;
});

const onMousedown = function (event) {
  if (event.button == 2) {
    $coreStore.showContextMenu(event.clientX, event.clientY, 'wall', {});
    event.stopPropagation(); //防止事件进一步传播到上层元素
  } else {
    $coreStore.closeContextMenu();
  }
  $coreStore.selectIcon('');
  $coreStore.switchSidebar(false);
  $coreStore.switchLivebar(false);
}

let query_onlineLive_list = []
const queryOnlineLiveList = () => {
  jsonRPC({
    url: `/api/my/live/list`,
    success(res) {
      const responseData = getResponseData(res);
      logDebug('获取在线直播列表', responseData);
      query_onlineLive_list = responseData.records
    },
    fail(error) {
      logError('获取在线直播列表失败', error);
    },
  });
};

// 在页面加载时触发 UV 埋点
onMounted(() => {
  // 调用 trackUV 来统计 UV 数据
  trackUV(router.currentRoute);
});
</script>

<style scoped lang="scss">
html,
body {
	height: 100%;
	width: 100%;
	overflow: hidden;
}

.desktop {
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
  overflow: hidden;
}

.wallpaper-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  // background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}

.icon-grid-container {
  width: 100%;
  height: calc(100% - 40px);
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: repeat(auto-fill, 100px); //repeat(6, minmax(60px, auto));
  grid-template-columns: repeat(auto-fill, 100px); //repeat(9, minmax(60px, auto));
  grid-gap: 10px;
  justify-items: center;
  align-items: end;
  padding-bottom: 10px;
}

.danmu {
  height: 100%;
  width: 100%;
  pointer-events: none;
  z-index: 3000;

  .danmu-item {
    display: flex;
    align-items: center;

    img {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      margin-right: 8px;
    }

    span {
      color: red;
      font-size: 40px;
    }
  }
}
</style>
