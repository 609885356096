<template>
  <div class="home">
    <div class="img_box_banner">
      <img src="/hw_frontend/static/img/desktopAICloudServer.jpg">
      <div class="introduce1">
        <div>
          <h1><span v-for="(char, index) in Text.letter1.split('')" :key="index" class="letter">{{char}}</span></h1>
          <h2><span v-for="(char, index) in Text.letter2.split('')" :key="index" class="letter">{{ char == ' ' ? '\u00A0' : char }}</span></h2>
          <h3><span v-for="(char, index) in Text.letter3.split('')" :key="index" class="letter">{{ char == ' ' ? '\u00A0' : char }}</span></h3>
          <h4><span v-for="(char, index) in Text.letter4.split('')" :key="index" class="letter">{{ char == ' ' ? '\u00A0' : char }}</span></h4>
        </div>
      </div>
      <div class="swiper_box1">
        <div>
          <swiper v-if="banners1.length > 0"
            :modules="[Pagination, Autoplay]"
            :slides-per-view="1"
            :space-between="0"
            :loop="true"
            :effect="'fade'"
            :pagination="{ clickable: true, renderBullet: bulletRenderer }"
            :autoplay="{delay: 10000,pauseOnMouseEnter: true}"
          >
            <swiper-slide v-for="(row, index) in banners1" :key="index">
              <img :src="row" />
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
    <div class="container uses">
      <a-row>
        <a-col :span="8">
          <h1>云应用服务人次</h1>
          <div class="number">{{ formattedNumber }}+</div>
          <p>全国多可用区均可一键连接</p>
          <a-flex align="center" gap="25">
            <a-button type="primary" @click="onLearnMore">了解更多</a-button>
            <a-button @click="onLearnMore">立即体验</a-button>
          </a-flex>
        </a-col>
        <a-col :span="1">
          <div class="separate">
            <div></div>
            <div></div>
          </div>
        </a-col>
        <a-col :span="10">
          <h1>{{ satisfiedNumber }}%</h1>
          <p>在初次体验Hi168平台的AI云服务器后，大部分用户表示有长期部署使用的意愿</p>
          <h1>{{ usersNumber }}+</h1>
          <p>大量用户参与共创，共建平台AI云生态，构建多样化应用累计突破500个</p>
        </a-col>
        <a-col :span="5">
          <a-flex justify="center" align="center" style="height: 100%;">
            <img src="/hw_frontend/static/img/serviceUsers.png">
          </a-flex>
        </a-col>
      </a-row>
    </div>
    <div class="img_box_banner">
      <img src="/hw_frontend/static/img/quickOneClickDeployment.jpg">
      <div class="introduce2">
        <div>
          <h1><span v-for="(char, index) in Text.letter5.split('')" :key="index" class="letter">{{char}}</span></h1>
          <h1><span v-for="(char, index) in Text.letter6.split('')" :key="index" class="letter">{{char}}</span></h1>
          <h1><span v-for="(char, index) in Text.letter7.split('')" :key="index" class="letter">{{char}}</span></h1>
        </div>
      </div>
      <div class="tags">
        <img src="/hw_frontend/static/img/homeOpenSUSE.png" style="top: 24px;left: 143px;">
        <img src="/hw_frontend/static/img/hmeUbuntu.png" style="top: 30px;left: 515px;">
        <img src="/hw_frontend/static/img/homeJenkins.png" style="top: 131px;left: 330px;">
        <img src="/hw_frontend/static/img/homeOracle.png" style="top: 194px;left: 128px;">
        <img src="/hw_frontend/static/img/homeNginx.png" style="top: 204px;left: 537px;">
        <img src="/hw_frontend/static/img/homeRedHat.png" style="top: 296px;left: 306px;">
        <img src="/hw_frontend/static/img/homeAlmalinux.png" style="top: 397px;left: 205px;">
        <img src="/hw_frontend/static/img/homeMySQL.png" style="top: 371px;left: 509px;">
      </div>
    </div>
    <div class="container practice">
      <h1>
        Hi168云平台
        <br/>
        帮助您在任何地方进行高质量应用实践
      </h1>
      <a-row>
        <a-col :span="8">
          <img src="/hw_frontend/static/img/homeCloudApps.png">
          <h3>云应用</h3>
          <p>为用户提供丰富多样的云应用，支持在线一键部署、开箱即用</p>
        </a-col>
        <a-col :span="8">
          <img src="/hw_frontend/static/img/homeComputingPowerService.png">
          <h3>算力服务</h3>
          <p>高性价比、高性能算力服务，帮助用户更高效实验，多线程稳定运行</p>
        </a-col>
        <a-col :span="8">
          <img src="/hw_frontend/static/img/homeStorage.png">
          <h3>存储空间</h3>
          <p>借助平台存储桶，实现用户高自由度的文件上传下载</p>
        </a-col>
      </a-row>
      <a-flex justify="center" align="center" gap="25" style="margin-top: 50px;">
        <a-button type="primary" @click="onLearnMore">立即部署</a-button>
        <a-button @click="onLearnMore">快速体验</a-button>
      </a-flex>
    </div>
    <div class="img_box_banner">
      <img src="/hw_frontend/static/img/cloudInterconnection.jpg">
      <div class="introduce3">
        <div>
          <h1><span v-for="(char, index) in Text.letter8.split('')" :key="index" class="letter">{{char}}</span></h1>
          <h1 style="font-size: 46px;"><span v-for="(char, index) in Text.letter9.split('')" :key="index" class="letter">{{ char == ' ' ? '\u00A0' : char }}</span></h1>
          <h1><span v-for="(char, index) in Text.letter10.split('')" :key="index" class="letter">{{char}}</span></h1>
        </div>
      </div>
      <div class="swiper_box2">
        <div>
          <swiper v-if="banners2.length > 0"
            :modules="[Pagination, Autoplay]"
            :slides-per-view="1"
            :space-between="0"
            :loop="true"
            :effect="'fade'"
            :pagination="{ clickable: true, renderBullet: bulletRenderer }"
            :autoplay="{delay: 10000,pauseOnMouseEnter: true}"
          >
            <swiper-slide v-for="(row, index) in banners2" :key="index">
              <img :src="row" />
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
    <div class="container experiment">
      <a-flex justify="space-around" align="center" style="margin-top: 30px;margin-bottom: 30px;">
        <img src="/hw_frontend/static/img/homeFast.png">
        <img src="/hw_frontend/static/img/homeSimple.png">
        <img src="/hw_frontend/static/img/homeOpenness.png">
        <img src="/hw_frontend/static/img/homeExtend.png">
        <img src="/hw_frontend/static/img/homeEfficient.png">
        <img src="/hw_frontend/static/img/homeReliable.png">
        <img src="/hw_frontend/static/img/homeConnect.png">
      </a-flex>
      <a-row :gutter="[30,30]">
        <a-col :span="4">
          <div class="info">
            大数据
            <p>Big Data</p>
          </div>
        </a-col>
        <a-col :span="4">
          <div class="info">
            软件工程
            <p>Software Engineering</p>
          </div>
        </a-col>
        <a-col :span="4">
          <div class="info">
            数据科学
            <p>Data Science</p>
          </div>
        </a-col>
        <a-col :span="4">
          <div class="info">
            人工智能
            <p>Artificial Intelligence</p>
          </div>
        </a-col>
        <a-col :span="4">
          <div class="info">
            金融科技
            <p>Financial Technology</p>
          </div>
        </a-col>
        <a-col :span="4">
          <div class="info">
            信息安全
            <p>Information Security</p>
          </div>
        </a-col>
        <a-col :span="4">
          <div class="info">
            电子商务
            <p>E-commerce</p>
          </div>
        </a-col>
        <a-col :span="16">
          <div class="t_center">
            辐射多学科多领域
          </div>
        </a-col>
        <a-col :span="4">
          <div class="info">
            工业设计
            <p>Industrial Design</p>
          </div>
        </a-col>
        <a-col :span="4">
          <div class="info">
            计算机应用
            <p>Computer Application</p>
          </div>
        </a-col>
        <a-col :span="4">
          <div class="info">
            艺术设计
            <p>Art Design</p>
          </div>
        </a-col>
        <a-col :span="4">
          <div class="info">
            计算机网络技术
            <p>Network Technology</p>
          </div>
        </a-col>
        <a-col :span="4">
          <div class="info">
            网络工程
            <p>Networking</p>
          </div>
        </a-col>
        <a-col :span="4">
          <div class="info">
            广告设计
            <p>Advertising Design</p>
          </div>
        </a-col>
        <a-col :span="4">
          <div class="info">
            区块链
            <p>Blockchain</p>
          </div>
        </a-col>
      </a-row>
      <a-flex justify="center" align="center" gap="25" style="margin-top: 50px;">
        <a-button type="primary" @click="onLearnMore">立即试用</a-button>
        <a-button @click="onLearnMore">快速体验</a-button>
      </a-flex>
    </div>
    <a-back-top />
  </div>
</template>

<script setup>
import { Pagination, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/scss";
import "swiper/scss/pagination";
import "swiper/scss/effect-fade";
import { ref, reactive, computed, onMounted, onUnmounted } from 'vue'
import anime from 'animejs/lib/anime.es.js'; // 引入 anime.js
import {useRouter} from "vue-router";
const router = useRouter();
const onLearnMore = ()=>{
  router.push("/app")
}
const bulletRenderer = (index, className) => {
  const _className = `${className} swiper-pagination-bullet-custom`;
  return `<span class="${_className}">${index + 1}</span>`;
};
const banners1 = ref([
  '/hw_frontend/static/img/homeGIF/box1gif1-min.gif',
  '/hw_frontend/static/img/homeGIF/box1gif2-min.gif',
]);
const banners2 = ref([
  '/hw_frontend/static/img/homeGIF/box2gif1-min.gif',
  '/hw_frontend/static/img/homeGIF/box2gif2-min.gif',
  '/hw_frontend/static/img/homeGIF/box2gif3-min.gif',
  '/hw_frontend/static/img/homeGIF/box2gif4-min.gif',
]);

const Text = reactive({
  letter1: '桌面AI云服务器',
  letter2: 'Desktop AI Cloud Server',
  letter3: '影音 NAS 学习 开发 游戏',
  letter4: 'Audiovisual NAS Study Development Game',
  letter5: '快速一键部署',
  letter6: '随时随地',
  letter7: '连接、构建、实验',
  letter8: '云端互联',
  letter9: 'Cloud interconnection',
  letter10: '桌面、云端无缝集成',
});

// 千分位格式化
const formattedNumber = computed(() => {
  return new Intl.NumberFormat('en-US').format(currentNumber.value)
})
const currentNumber = ref(100000) // 初始数字为 100000
const satisfiedNumber = ref(80) // 初始数字为 80
const usersNumber = ref(500) // 初始数字为 500
const maxNumber = 100000 // 最大数字
const maxSatisfiedNumber = 80 // 满意度最大值
const maxUsersNumber = 500 // 用户最大值
const duration = 3000 // 动画持续时间（毫秒）
const pauseDuration = 3000 // 停留时间（毫秒）
let animationFrame = null // 动画帧控制
let startTime = null

const startAnimation = () => {
  cancelAnimationFrame(animationFrame) // 取消任何正在进行的动画
  currentNumber.value = 0 // 动画从 0 开始
  satisfiedNumber.value = 0 // 动画从 0 开始
  usersNumber.value = 0 // 动画从 0 开始
  startTime = null

  const animate = (timestamp) => {
    if (!startTime) startTime = timestamp // 记录动画开始时间
    const elapsed = timestamp - startTime // 计算已过去的时间

    if (elapsed < duration) {
      // 根据进度计算三个值的当前数字
      const progress = elapsed / duration
      currentNumber.value = Math.round(progress * maxNumber)
      satisfiedNumber.value = Math.round(progress * maxSatisfiedNumber)
      usersNumber.value = Math.round(progress * maxUsersNumber)
      animationFrame = requestAnimationFrame(animate) // 继续下一帧
    } else {
      // 动画结束，确保三个值显示最大值
      currentNumber.value = maxNumber
      satisfiedNumber.value = maxSatisfiedNumber
      usersNumber.value = maxUsersNumber
      setTimeout(() => {
        // 停留 3 秒后重新开始动画
        startAnimation()
      }, pauseDuration)
    }
  }

  animationFrame = requestAnimationFrame(animate)
}

// 组件加载时，初始值停留 3 秒，然后开始动画
onMounted(() => {
  // 获取每一行的字母节点
  const letters1 = document.querySelectorAll('.introduce1 h1 .letter');
  const letters2 = document.querySelectorAll('.introduce1 h2 .letter');
  const letters3 = document.querySelectorAll('.introduce1 h3 .letter');
  const letters4 = document.querySelectorAll('.introduce1 h4 .letter');
  const letters5 = document.querySelectorAll('.introduce2 h1')[0];
  const letters6 = document.querySelectorAll('.introduce2 h1')[1];
  const letters7 = document.querySelectorAll('.introduce2 h1')[2];
  const letters8 = document.querySelectorAll('.introduce3 h1 .letter');
  // 定义一个函数，用于执行动画
  const animateLetters = (letters) => {
    return anime({
      targets: letters,
      rotate: ['-60deg', '0deg'], // 旋转效果：从 -180 度到 0 度
      opacity: [0, 1],  // 从透明到不透明
      duration: 1000,    // 每个字母持续时间 1 秒
      delay: anime.stagger(50), // 字母间隔动画
      easing: 'easeOutExpo',  // 缓动效果
      loop: false,  // 不需要循环
    });
  };
  // 创建一个控制逐行显示的函数
  const animateAllLines = () => {
    anime({
      targets: letters1,
      opacity: [0, 1],
      rotate: ['-60deg', '0deg'],
      duration: 1000,
      easing: 'easeOutExpo',
      begin: () => {
        // 第一行完成后，开始第二行
        animateLetters(letters2);  // 第二行从 0.5秒后开始
      },
      loop: false,
    });
    anime({
      targets: letters2,
      opacity: [0, 1],
      rotate: ['-60deg', '0deg'],
      duration: 1000,
      easing: 'easeOutExpo',
      delay: 500,  // 第二行延迟 0.5秒
      begin: () => {
        // 第二行完成后，开始第三行
        animateLetters(letters3);  // 第三行从 1秒后开始
      },
      loop: false,
    });
    anime({
      targets: letters3,
      opacity: [0, 1],
      rotate: ['-60deg', '0deg'],
      duration: 1000,
      easing: 'easeOutExpo',
      delay: 1000,  // 第三行延迟 1秒
      begin: () => {
        // 第三行完成后，开始第四行
        animateLetters(letters4);  // 第四行从 1.5秒后开始
      },
      loop: false,
    });
    anime({
      targets: letters4,
      opacity: [0, 1],
      rotate: ['-60deg', '0deg'],
      duration: 1000,
      easing: 'easeOutExpo',
      delay: 1500,  // 第四行延迟 1.5秒
      endDelay: 2000,
      complete: () => {
        // 第四行完成后，重新从第一行开始动画
        animateAllLines();  // 循环调用
      },
      loop: false,
    });
  };
  // 开始动画
  animateAllLines();
  // 文字滚动动画
  anime.timeline({ loop: true })
  .add({
    targets: letters5,
    opacity: [0, 1],
    translateX: [100, 0],
    duration: 400,
    easing: 'easeOutExpo',
  })
  .add({
    targets: letters6,
    opacity: [0, 1],
    translateX: [-100, 0],
    duration: 400,
    easing: 'easeOutExpo',
  })
  .add({
    targets: letters7,
    opacity: [0, 1],
    translateX: [100, 0],
    duration: 400,
    endDelay: 3000,
    easing: 'easeOutExpo',
  });
  // 文字滚动动画
  anime({
    targets: letters8,  // 针对所有带有 .letter 类的元素
    opacity: [0, 1],      // 从透明到完全不透明
    translateX: [-20, 0], // 文字从左侧平移到原位置
    translateY: [10, 0],  // 文字从下方平移到原位置
    scale: [0.5, 1],      // 从缩小到正常大小
    rotate: ['-45deg', '0deg'], // 文字从旋转到正常位置
    duration: 700,        // 动画时长 0.7秒，加快速度
    delay: anime.stagger(50), // 字母之间的延迟，50ms 为每个字母
    endDelay: 3000,       // 动画结束后保持一段时间
    easing: 'easeInOutCirc', // 更炫酷的缓动效果，圆形缓动
    loop: true,           // 动画循环
  });
  // 初始停留 3 秒，然后开始动画
  setTimeout(() => {
    startAnimation()
  }, pauseDuration) // 初始停留 3 秒
})

// 在组件销毁时清除动画帧
onUnmounted(() => {
  cancelAnimationFrame(animationFrame)
})
</script>

<style scoped lang="scss">
.home {
  background-color: #fff;
  .img_box_banner {
    display: block;
    position: relative;
    width: 100%;
    height: 0;
    // padding-bottom: calc(100% / (24 / 5));
    padding-bottom: 500px;
    > img {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      object-fit: cover; /* 图像保持自身比例填满容器，并裁剪掉超出的部分 */
    }
    .tags{
      position: absolute;
      height: 100%;
      width: 56%;
      overflow: hidden;
      img{
        position: absolute;
        height: 80px;
        object-fit: cover; /* 保持图片比例 */
        transition: transform 0.3s ease; /* 平滑过渡效果 */
        &:hover {
          transform: scale(1.2); /* 鼠标移入时放大图片 */
        }
      }
    }
    .letter {
      display: inline-block;
    }
    .introduce1{
      position: absolute;
      height: 100%;
      width: 50%;
      overflow: hidden;
      color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      h1{
        font-size: 72px;
        font-weight: bold;
        margin-bottom: 1rem;
      }
      h2{
        margin-bottom: 50px;
        font-size: 44px;
      }
      h3{
        font-size: 54px;
        font-weight: bold;
        margin-bottom: 1rem;
      }
      h4{
        font-size: 30px;
        margin-bottom: 1rem;
      }
    }
    .introduce2{
      position: absolute;
      height: 100%;
      width: 50%;
      right: 0;
      overflow: hidden;
      color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      h1{
        font-size: 60px;
        font-weight: bold;
        font-style: italic;
        margin-bottom: 2rem;
        text-align: right;
      }
    }
    .introduce3{
      position: absolute;
      height: 100%;
      width: 50%;
      right: 0;
      overflow: hidden;
      color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      h1{
        font-size: 60px;
        font-weight: bold;
        margin-bottom: 2rem;
        text-align: right;
      }
    }
    .swiper_box1{
      position: absolute;
      height: 100%;
      width: 50%;
      padding: 100px 50px;
      right: 0;
      >div{
        overflow: hidden;
      }
      img {
        height: 300px;
        margin-bottom: 30px;
        width: 100%;
        object-fit: contain;  /* 图像保持自身比例填满容器，并裁剪掉超出的部分 */
      }
    }
    .swiper_box2{
      position: absolute;
      height: 100%;
      width: 50%;
      padding: 100px 50px;
      left: 0;
      >div{
        overflow: hidden;
      }
      img {
        height: 300px;
        margin-bottom: 30px;
        width: 100%;
        object-fit: contain;  /* 图像保持自身比例填满容器，并裁剪掉超出的部分 */
      }
    }
  }
  .uses{
    margin-top: 50px;
    margin-bottom: 50px;
    .ant-col-8{
      h1{
        font-size: 48px;
        font-weight: bold;
      }
      .number{
        font-size: 72px;
        color: #006BFF;
        margin-bottom: 20px;
      }
      p{
        font-size: 20px;
        margin-bottom: 40px;
      }
    }
    .ant-col-1{
      .separate{
        display: flex;
        width: 5px;
        height: 100%;
        flex-direction: column;
        justify-content: space-between;
        >div{
          width: 5px;
          height: 45%;
          background-color: #737373;
        }
      }
    }
    .ant-col-10{
      h1{
        font-size: 48px;
        color: #006BFF;
        margin-bottom: 20px;
      }
      p{
        font-size: 20px;
        margin-bottom: 20px;
      }
    }
  }
  .practice{
    margin-top: 50px;
    margin-bottom: 50px;
    text-align: center;
    h1{
      font-size: 36px;
      font-weight: bold;
      line-height: 1.8;
      margin-bottom: 50px;
    }
    .ant-col-8{
      img{
        width: 60px;
        height: 60px;
        margin-bottom: 15px;
      }
      h3{
        font-size: 18px;
        color: #006BFF;
        margin-bottom: 15px;
      }
      p{
        width: 60%;
        font-size: 16px;
        text-align: left;
        margin: 0 auto;
      }
    }
  }
  .experiment{
    padding-bottom: 50px;
    .ant-flex{
      img{
        height: 40px;
      }
    }
    .info{
      width: 100%;
      height: 100%;
      padding: 15px 0 10px;
      font-size: 18px;
      border-radius: 8px;
      background-color: rgba(255, 255, 255, 1);
      text-align: center;
      box-shadow: 2px 2px 4px 0px rgba(120, 165, 255, 1);
      p{
        color: #959595;
        margin-top: 5px;
        margin-bottom: 0;
      }
    }
    .t_center{
      height: 100%;
      display: flex;
      font-size: 20px;
      color: #165AE2;
      align-items: center;
      justify-content: center;
    }
  }
  .ant-btn{
    height: 40px;
    padding: 8px 31px;
    border-radius: 8px;
    &.ant-btn-default{
      color: #006BFF;
      border-color: #006BFF;
    }
  }
  // 轮播分页样式
  --swiper-pagination-bottom: 0;
  :deep(.swiper-pagination-bullet-custom) {
    width: 20px !important;
    height: 20px !important;
    line-height: 20px !important;
    text-align: center;
    background-color: #f8f8f8;
    color: #24292f94;
    transition: all 0.2s;
    opacity: 0.6;
    &:hover {
      opacity: 1;
      color: #24292f;
    }
    &.swiper-pagination-bullet-active {
      opacity: 1;
      color: #fff;
      background-color: #007aff;
    }
  }
}
</style>
